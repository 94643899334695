import axios from 'axios';
import EnvDecode from '../common/EnvDecode';
import { 
    HTTP_ERRORS, 
    isValidToken, 
    AXIOS_AUTH,
    AXIOS_LANGUAGE,
    APP_LANG_KEY
} from '../components/Common/constants';

const BASE_URL = EnvDecode.getEnvPath(process.env.React_App_API_SERVER_URL);

var ScheduleService = {
    createSchedule (schedule) {
        return new Promise((resolve, reject) => {         
            if(!isValidToken(axios.defaults.headers.common[AXIOS_AUTH]))
            {
                reject(HTTP_ERRORS.InvalidToken);
            }
            else
            {  
                //axios.post('/api/schedule/create', schedule)
                delete schedule.errors;
                //console.log("Create schedule = ", schedule);
                axios.defaults.headers.common[AXIOS_LANGUAGE] = localStorage.getItem(APP_LANG_KEY) || 'en';
                axios.post(`${BASE_URL}` + '/franchise/workinghour', schedule)
                .then(response => {                    
                    if ( response.status === 200 )
                    {                       
                        resolve(response.data);                        
                    }
                    else
                    {   
                        reject(response.data);
                    }
                })
                .catch(err=>{
                    reject(err);
                })
            }   
        });
    },
    updateSchedule (schedule) {
        // let data = {
        //     scheduleId: schedule._id,
        //     status: schedule.status
        // }
        delete schedule.errors;
        //console.log("Update schedule = ", schedule);
        return new Promise((resolve, reject) => { 
            if(!isValidToken(axios.defaults.headers.common[AXIOS_AUTH]))
            {
                reject(HTTP_ERRORS.InvalidToken);
            }
            else
            {  
                axios.defaults.headers.common[AXIOS_LANGUAGE] = localStorage.getItem(APP_LANG_KEY) || 'en';
                axios.patch(`${BASE_URL}` + '/franchise/workinghour',  schedule)
                .then(response => {                    
                    if ( response.status === 200 )
                    {                       
                        resolve(response.data);                        
                    }
                    else
                    {   
                        reject(response.data);
                    }
                })
                .catch(err=>{
                    reject(err);
                })   
            }
        }); 
    },
    deleteSchedule (schedule) {
        console.log("Send => ", schedule)
        delete schedule.errors;
        return new Promise((resolve, reject) => {        
            if(!isValidToken(axios.defaults.headers.common[AXIOS_AUTH]))
            {
                reject(HTTP_ERRORS.InvalidToken);
            }
            else
            {
                axios.defaults.headers.common[AXIOS_LANGUAGE] = localStorage.getItem(APP_LANG_KEY) || 'en';
                axios.delete(`${BASE_URL}` + '/franchise/workinghour/' + schedule._id)
                .then(response => {                    
                    if ( response.status === 200 )
                    {                       
                        resolve(response.data);                        
                    }
                    else
                    {   
                        reject(response.data);
                    }
                })
                .catch(err=>{
                    reject(err);
                })
            }
        });
    },

    getSchedules (queryData) {
        let url = "";
        let pageNum = queryData.pageNum
        let query = queryData.query === "" ? 0 : queryData.query

        if (queryData.status === 1) {
            url = `${BASE_URL}` + '/franchise/workinghoursWithQuery/'+queryData.status + "/" + pageNum + "/" + query;
        } else if (queryData.status === 2) {
            url = `${BASE_URL}` + '/franchise/workinghoursWithQuery/'+queryData.status + "/" + pageNum + "/" + query;
        }  else {
            url = `${BASE_URL}` + '/franchise/workinghoursWithQuery/'+queryData.status + "/" + pageNum + "/" + query;
        }
        
        return new Promise((resolve, reject) => {     
            if(!isValidToken(axios.defaults.headers.common[AXIOS_AUTH]))
            {
                reject(HTTP_ERRORS.InvalidToken);
            }
            else
            {
                axios.defaults.headers.common[AXIOS_LANGUAGE] = localStorage.getItem(APP_LANG_KEY) || 'en';
                axios.get(url)
                .then(response => {                    
                    if ( response.status === 200 )
                    {   
                        resolve(response.data);                        
                    }
                    else
                    {   
                        reject(response.data);
                    }
                })
                .catch(err=>{
                    reject(err);
                })
            }       
        });
    },
    addSlot ( slotData ) {
        return new Promise((resolve, reject) => {            
            if(!isValidToken(axios.defaults.headers.common[AXIOS_AUTH]))
            {
                reject(HTTP_ERRORS.InvalidToken);
            }
            else
            {
                axios.defaults.headers.common[AXIOS_LANGUAGE] = localStorage.getItem(APP_LANG_KEY) || 'en';
                axios.post(`${BASE_URL}` + '/franchise/workinghour/addslot', slotData)
                .then(response => {                    
                    if ( response.status === 200 )
                    {                       
                        resolve(response.data);                        
                    }
                    else
                    {   
                        reject(response.data);
                    }
                })
                .catch(err=>{
                    reject(err);
                })
            }
        });
    },
    deleteSlot ( slotData ) {
        
        //console.log( 'delete slot  =', slotData )
        return new Promise((resolve, reject) => { 
            if(!isValidToken(axios.defaults.headers.common[AXIOS_AUTH]))
            {
                reject(HTTP_ERRORS.InvalidToken);
            }
            else
            {
                axios.defaults.headers.common[AXIOS_LANGUAGE] = localStorage.getItem(APP_LANG_KEY) || 'en';
                axios.post(`${BASE_URL}` + '/franchise/workinghour/deleteslot', slotData )
                .then(response => {                    
                    if ( response.status === 200 )
                    {                       
                        resolve(response.data);                        
                    }
                    else
                    {   
                        reject(response.data);
                    }
                })
                .catch(err=>{
                    reject(err);
                })   
            }
        }); 
    },
}

export default ScheduleService;