import { HTTP_ERRORS } from '../../components/Common/constants';
import FAQService from '../../services/faq';

import { GET_FAQS, GET_FAQS_DONE, GOT_ERROR_FAQ, INVALID_TOKEN_ERROR} from '../actions/actions';

const faq = store => next => action => {
    let dispatch = store.dispatch;
    if (action.type === GET_FAQS) {       
      // Make an API call to login the server      
      FAQService.getFAQs().then(result => {

        //console.log( 'faq middle =', result )
        dispatch({ type: GET_FAQS_DONE, payload: result });
      })
      .catch(err=>{
        if(err === HTTP_ERRORS.InvalidToken)
        {
          dispatch({ type: INVALID_TOKEN_ERROR, payload: err });
        }
        else
        {
          //console.log( 'faq middle error =', err )
          dispatch({ type: GOT_ERROR_FAQ, payload: err })
        }
      })
    }
    return next(action)
  }

export default faq;