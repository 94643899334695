import { PRODUCT_STATUS } from '../../components/Common/constants';
import { UPDATE_STORE_MENU_DONE, GOT_ERROR_STORE_MENU, 
    DELETE_STORE_MENU_DONE, CREATE_STORE_MENU_DONE, GET_STORE_MENU_DONE, OPEN_NEW_STORE_MENU_DIALOG, OPEN_VIEW_STORE_MENU_ADDON_DIALOG,
    OPEN_EDIT_STORE_MENU_DIALOG, CLOSE_STORE_MENU_DIALOG} from '../actions/storeMenu.actions';

const initialState = {
    
    pending_products: [],
    approved_products: [],
    rejected_products: [],
    banned_products: [],
    deleted_products: [],
    list_products: [],
    err_product:null,
    productDialog     : {
        type : 'new',
        props: {
            open: false
        },
        data : null
    },
    addonDialog      : {
        type : 'new',
        props: {
            open: false
        },
        data : null
    },
}

const storeMenuReducer = (state = initialState, action) => {    
    switch (action.type) {
        case UPDATE_STORE_MENU_DONE:

            let updateProduct = action.payload;
            let itemsPending = [...state.pending_products];
            let itemsApproved = [...state.approved_products];
            let itemsRejected = [...state.rejected_products];
            let itemsBanned = [...state.banned_products];
            let itemsDeleted = [...state.deleted_products];
            let itemsList = [...state.list_products];

            if ( updateProduct.status === PRODUCT_STATUS.PENDING ){
                let isFound = itemsPending.find((_pd) => {return _pd._id === updateProduct._id});
                // If status is updated
                if (!isFound) {
                    itemsPending = [updateProduct, ...itemsPending]
                    itemsApproved =  itemsApproved.filter(_pd => updateProduct._id !== _pd._id);
                    itemsRejected =  itemsRejected.filter(_pd => updateProduct._id !== _pd._id);
                    itemsBanned =  itemsBanned.filter(_pd => updateProduct._id !== _pd._id);
                    itemsDeleted =  itemsDeleted.filter(_pd => updateProduct._id !== _pd._id);
                    itemsList =  itemsList.filter(_pd => updateProduct._id !== _pd._id);
                }

                itemsPending = itemsPending.map((_pd) => {
                    if ( _pd._id === updateProduct._id )
                    {
                        return updateProduct;
                    }
                    return _pd;
                });
                return {
                    ...state,
                    pending_products: itemsPending,
                    approved_products: itemsApproved,
                    rejected_products: itemsRejected,
                    banned_products: itemsBanned,
                    deleted_products: itemsDeleted,
                    list_products: itemsList
                }

            }else if ( [PRODUCT_STATUS.APPROVED, PRODUCT_STATUS.OUT_OF_STOCK].includes(updateProduct.status)){
                let isFound = itemsApproved.find((_pd) => {return _pd._id === updateProduct._id});
                // If status is updated
                if (!isFound) {
                    itemsApproved = [updateProduct, ...itemsApproved]
                    itemsPending =  itemsPending.filter(_pd => updateProduct._id !== _pd._id);
                    itemsRejected =  itemsRejected.filter(_pd => updateProduct._id !== _pd._id);
                    itemsBanned =  itemsBanned.filter(_pd => updateProduct._id !== _pd._id);
                    itemsDeleted =  itemsDeleted.filter(_pd => updateProduct._id !== _pd._id);
                    itemsList =  itemsList.filter(_pd => updateProduct._id !== _pd._id);
                }

                itemsApproved = itemsApproved.map((_pd) => {
                    if ( _pd._id === updateProduct._id )
                    {
                        return updateProduct;
                    }
                    return _pd;
                });
                return {
                    ...state,
                    pending_products: itemsPending,
                    approved_products: itemsApproved,
                    rejected_products: itemsRejected,
                    banned_products: itemsBanned,
                    deleted_products: itemsDeleted,
                    list_products: itemsList
                }

            }else if ( updateProduct.status === PRODUCT_STATUS.DELETED ){
                let isFound = itemsDeleted.find((_pd) => {return _pd._id === updateProduct._id});
                // If status is updated
                if (!isFound) {
                    itemsDeleted = [updateProduct, ...itemsDeleted]
                    itemsPending =  itemsPending.filter(_pd => updateProduct._id !== _pd._id);
                    itemsRejected =  itemsRejected.filter(_pd => updateProduct._id !== _pd._id);
                    itemsBanned =  itemsBanned.filter(_pd => updateProduct._id !== _pd._id);
                    itemsApproved =  itemsApproved.filter(_pd => updateProduct._id !== _pd._id);
                    itemsList =  itemsList.filter(_pd => updateProduct._id !== _pd._id);
                }

                itemsDeleted = itemsDeleted.map((_pd) => {
                    if ( _pd._id === updateProduct._id )
                    {
                        return updateProduct;
                    }
                    return _pd;
                });
                return {
                    ...state,
                    pending_products: itemsPending,
                    approved_products: itemsApproved,
                    rejected_products: itemsRejected,
                    banned_products: itemsBanned,
                    deleted_products: itemsDeleted,
                    list_products: itemsList
                }

            }else if ( updateProduct.status === PRODUCT_STATUS.REJECTED ){
                let isFound = itemsRejected.find((_pd) => {return _pd._id === updateProduct._id});
                // If status is updated
                if (!isFound) {
                    itemsRejected = [updateProduct, ...itemsRejected]
                    itemsPending =  itemsPending.filter(_pd => updateProduct._id !== _pd._id);
                    itemsApproved =  itemsApproved.filter(_pd => updateProduct._id !== _pd._id);
                    itemsBanned =  itemsBanned.filter(_pd => updateProduct._id !== _pd._id);
                    itemsDeleted =  itemsDeleted.filter(_pd => updateProduct._id !== _pd._id);
                    itemsList =  itemsList.filter(_pd => updateProduct._id !== _pd._id);
                }

                itemsRejected = itemsRejected.map((_pd) => {
                    if ( _pd._id === updateProduct._id )
                    {
                        return updateProduct;
                    }
                    return _pd;
                });
                return {
                    ...state,
                    pending_products: itemsPending,
                    approved_products: itemsApproved,
                    rejected_products: itemsRejected,
                    banned_products: itemsBanned,
                    deleted_products: itemsDeleted,
                    list_products: itemsList
                }

            }else if ( updateProduct.status === PRODUCT_STATUS.BANNED ){
                let isFound = itemsBanned.find((_pd) => {return _pd._id === updateProduct._id});
                // If status is updated
                if (!isFound) {
                    itemsBanned = [updateProduct, ...itemsBanned]
                    itemsPending =  itemsPending.filter(_pd => updateProduct._id !== _pd._id);
                    itemsApproved =  itemsApproved.filter(_pd => updateProduct._id !== _pd._id);
                    itemsRejected =  itemsRejected.filter(_pd => updateProduct._id !== _pd._id);
                    itemsDeleted =  itemsDeleted.filter(_pd => updateProduct._id !== _pd._id);
                    itemsList =  itemsList.filter(_pd => updateProduct._id !== _pd._id);
                }

                itemsBanned = itemsBanned.map((_pd) => {
                    if ( _pd._id === updateProduct._id )
                    {
                        return updateProduct;
                    }
                    return _pd;
                });
                return {
                    ...state,
                    pending_products: itemsPending,
                    approved_products: itemsApproved,
                    rejected_products: itemsRejected,
                    banned_products: itemsBanned,
                    deleted_products: itemsDeleted,
                    list_products: itemsList
                }

            } else {
                const {
                    pending_products, approved_products, rejected_products, 
                    banned_products, deleted_products, list_products
                } = state;

                const setAvailability = (items, itemId) => {
                    const copyOfItems = [...items];
                    const foundIndex = copyOfItems.findIndex(({_id})=> _id.toString() === itemId.toString());

                    if(foundIndex >= 0)
                        copyOfItems[foundIndex].status = copyOfItems[foundIndex].status === 1 ? 5 : 1;
                        
                    return copyOfItems;
                }

                const  itemsPending = Array.isArray(pending_products) ? setAvailability(pending_products, updateProduct._id) : [];
                const  itemsApproved = Array.isArray(approved_products) ? setAvailability(approved_products, updateProduct._id) : [];
                const  itemsRejected = Array.isArray(rejected_products) ? setAvailability(rejected_products, updateProduct._id) : [];
                const  itemsBanned = Array.isArray(banned_products) ? setAvailability(banned_products, updateProduct._id) : [];
                const  itemsDeleted = Array.isArray(deleted_products) ? setAvailability(deleted_products, updateProduct._id) : [];
                const  itemsList = Array.isArray(list_products) ? setAvailability(list_products, updateProduct._id) : [];

                return {
                    ...state,
                    pending_products: itemsPending,
                    approved_products: itemsApproved,
                    rejected_products: itemsRejected,
                    banned_products: itemsBanned,
                    deleted_products: itemsDeleted,
                    list_products: itemsList
                }
            }
            
            break;
        case GOT_ERROR_STORE_MENU:            
            return {
                ...state,
                err_product: action.payload
            }
        case DELETE_STORE_MENU_DONE:
            let id = action.payload._id;
            let status = action.payload.status
            
            if (status === PRODUCT_STATUS.PENDING) {
                let itemsPending = [...state.pending_products];
                itemsPending = itemsPending.filter(_pd => id !== _pd._id);

                return {
                    ...state,
                    pending_products: itemsPending
                }

            } else if (status === PRODUCT_STATUS.APPROVED) {
                let itemsApproved = [...state.approved_products];
                itemsApproved = itemsApproved.filter(_pd => id !== _pd._id);

                return {
                    ...state,
                    approved_products: itemsApproved
                }
            } else if (status === PRODUCT_STATUS.REJECTED) {
                let itemsRejected = [...state.rejected_products];
                itemsRejected = itemsRejected.filter(_pd => id !== _pd._id);

                return {
                    ...state,
                    rejected_products: itemsRejected
                }
            } else if (status === PRODUCT_STATUS.BANNED) {
                let itemsBanned = [...state.banned_products];
                itemsBanned = itemsBanned.filter(_pd => id !== _pd._id);

                return {
                    ...state,
                    banned_products: itemsBanned
                }
            } else if (status === PRODUCT_STATUS.DELETED ) {
                let itemsDeleted = [...state.deleted_products];
                itemsDeleted = itemsDeleted.filter(_pd => id !== _pd._id);

                return {
                    ...state,
                    deleted_products: itemsDeleted
                }
            } else if (status === PRODUCT_STATUS.ALL ) {
                let itemsList = [...state.list_products];
                itemsList = itemsList.filter(_pd => id !== _pd._id);

                return {
                    ...state,
                    list_products: itemsList
                }
            }
            break;
        case GET_STORE_MENU_DONE:            
        {
            let status = action.queryData.status
            let products = action.payload.data

            //console.log( 'products reducer =', products )
            if ( status === PRODUCT_STATUS.PENDING ){
                return {
                    ...state,
                    pending_products: products.data,
                    pending_products_count: products.count
                }
            }else if ( status === PRODUCT_STATUS.APPROVED){
                return {
                    ...state,
                    approved_products: products.data,
                    approved_products_count: products.count
                }
            }else if ( status === PRODUCT_STATUS.REJECTED ){
                return {
                    ...state,
                    rejected_products: products.data,
                    rejected_products_count: products.count
                }
            }else if ( status === PRODUCT_STATUS.BANNED ){
                return {
                    ...state,
                    banned_products: products.data,
                    banned_products_count: products.count
                }
            }else if ( status === PRODUCT_STATUS.DELETED ){
                return {
                    ...state,
                    deleted_products: products.data,
                    deleted_products_count: products.count
                }
            }
        }
        break;
        case CREATE_STORE_MENU_DONE:

           
            let pd = action.payload.data.value;
            if (pd.status === PRODUCT_STATUS.PENDING) {                
                return {
                    ...state,
                    pending_products: [pd, ...state.pending_products]
                }
            } else if (pd.status === PRODUCT_STATUS.APPROVED) {                
                return {
                    ...state,
                    approved_products: [pd, ...state.approved_products]
                }
            } else if (pd.status === PRODUCT_STATUS.REJECTED) {                
                return {
                    ...state,
                    rejected_products: [pd, ...state.rejected_products]
                }
            } else if (pd.status === PRODUCT_STATUS.BANNED) {                
                return {
                    ...state,
                    banned_products: [pd, ...state.banned_products]
                }
            } else if (pd.status === PRODUCT_STATUS.DELETED) {                
                return {
                    ...state,
                    deleted_products: [pd, ...state.deleted_products]
                }
            } else if (pd.status === PRODUCT_STATUS.ALL) {                
                return {
                    ...state,
                    list_products: [pd, ...state.list_products]
                }
            } 
            break;
        case OPEN_NEW_STORE_MENU_DIALOG:
            return {
                ...state,
                productDialog: {
                    type : 'new',
                    props: {
                        open: true
                    },
                    data : null
                }
            };
        case OPEN_VIEW_STORE_MENU_ADDON_DIALOG:

        //console.log( 'store menu reducer opened')
            return {
                ...state,
                addonDialog: {
                    type : 'edit',
                    props: {
                        open: true
                    },
                    data : action.data
                }
            };
            
        case OPEN_EDIT_STORE_MENU_DIALOG:
            return {
                ...state,
                productDialog: {
                    type : 'edit',
                    props: {
                        open: true
                    },
                    data : action.data
                }
            };
        case CLOSE_STORE_MENU_DIALOG:
            return {
                ...state,
                productDialog: {
                    type : 'new',
                    props: {
                        open: false
                    },
                    data : null
                },
                addonDialog: {
                    type : 'new',
                    props: {
                        open: false
                    },
                    data : null
                }
            };
                
        default:
            return state;
    }
}

export default storeMenuReducer;