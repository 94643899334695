import { GET_FAQS_DONE, GOT_ERROR_FAQ} from '../actions/actions';

const initialState = {
    tickets: [],
    err_ticket:null,    
}

const ticketReducer = (state = initialState, action) => {    
    switch (action.type) {
        case GET_FAQS_DONE:
            return {
                ...state,
                tickets: action.payload
            }
        case GOT_ERROR_FAQ:            
            return {
                ...state,
                err_ticket: action.payload
            }   
        default:
            return state;
    }
}

export default ticketReducer;