import { HTTP_ERRORS } from '../../components/Common/constants';
import AddonService from '../../services/addonService';

import { GET_ADDONS, CREATE_ADDON, UPDATE_ADDON
  , DELETE_ADDON, GET_ADDONS_DONE, UPDATE_ADDON_DONE, 
  DELETE_ADDON_DONE, CREATE_ADDON_DONE, 
  GOT_ERROR_ADDON, GET_ADDONS_GROUP, 
  GET_ADDONS_GROUP_DONE, INVALID_TOKEN_ERROR} from '../actions/actions';

const addon = store => next => action => {
    let dispatch = store.dispatch;
    if (action.type === GET_ADDONS) {       
      // Make an API call to login the server            
      AddonService.getAddons(action.queryData).then(result => {        
        dispatch({ type: GET_ADDONS_DONE, payload: result, queryData:action.queryData });
      })
      .catch(err=>{
        if(err === HTTP_ERRORS.InvalidToken)
        {
          dispatch({ type: INVALID_TOKEN_ERROR, payload: err });
        }
        else
        {
          dispatch({ type: GOT_ERROR_ADDON, payload: err })
        }
      })
    } 
    else if (action.type === GET_ADDONS_GROUP) {       
      // Make an API call to login the server            
      AddonService.getAddonsGroup(action.queryData).then(result => {    
        dispatch({ type: GET_ADDONS_GROUP_DONE, payload: result, queryData:action.queryData });
      })
      .catch(err=>{
        if(err === HTTP_ERRORS.InvalidToken)
        {
          dispatch({ type: INVALID_TOKEN_ERROR, payload: err });
        }
        else
        {
          dispatch({ type: GOT_ERROR_ADDON, payload: err })
        }
      })
    } 
    else if (action.type === CREATE_ADDON) {      
      AddonService.createAddon(action.data)
      .then(result => {
        dispatch({ type: CREATE_ADDON_DONE, payload:result });
      })
      .catch(err=>{
        if(err === HTTP_ERRORS.InvalidToken)
        {
          dispatch({ type: INVALID_TOKEN_ERROR, payload: err });
        }
        else
        {
          dispatch({ type: GOT_ERROR_ADDON, payload: err })
        }
      })
    } 
    else if (action.type === UPDATE_ADDON) {      
      let addonId = action.data._id
      AddonService.updateAddon(action.data)
      .then(result => {        
        dispatch({ type: UPDATE_ADDON_DONE, payload:action.data, queryData: addonId });        
      })
      .catch(err=>{
        if(err === HTTP_ERRORS.InvalidToken)
        {
          dispatch({ type: INVALID_TOKEN_ERROR, payload: err });
        }
        else
        {
          dispatch({ type: GOT_ERROR_ADDON, payload: err })
        }
      })      
    } 
    else if (action.type === DELETE_ADDON) {      
      AddonService.deleteAddon(action.data)
      .then(result => {       
        dispatch({ type: DELETE_ADDON_DONE, payload:action.data });
      })
      .catch(err=>{
        if(err === HTTP_ERRORS.InvalidToken)
        {
          dispatch({ type: INVALID_TOKEN_ERROR, payload: err });
        }
        else
        {
          dispatch({ type: GOT_ERROR_ADDON, payload: err })
        }
      }) 
    }
  
    return next(action)
  }

export default addon;