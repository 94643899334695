import axios from 'axios';
import EnvDecode from '../common/EnvDecode';
import { 
    HTTP_ERRORS, 
    isValidToken, 
    AXIOS_AUTH,
    AXIOS_LANGUAGE,
    APP_LANG_KEY
} from '../components/Common/constants';

const BASE_URL = EnvDecode.getEnvPath(process.env.React_App_API_SERVER_URL);

var StoreMenuService = {

    createStoreMenu (menu) {

        return new Promise((resolve, reject) => {    
            if(!isValidToken(axios.defaults.headers.common[AXIOS_AUTH]))
            {
                reject(HTTP_ERRORS.InvalidToken);
            }
            else
            { 
                axios.defaults.headers.common[AXIOS_LANGUAGE] = localStorage.getItem(APP_LANG_KEY) || 'en';
                axios.post( `${BASE_URL}` + '/child/product/create', menu)
                .then(response => {                    
                    if ( response.status === 200 )
                    {                       
                        resolve(response.data);                        
                    }
                    else
                    {   
                        reject(response.data);
                    }
                })
                .catch(err=>{
                    reject(err);
                })
            }        
        });
    },
    updateStoreMenu (menu) {
        //console.log("** Update Store Menu***", menu)
        let data = menu
        delete data.checked
        delete data.description
        let url = "";
        url = `${BASE_URL}` + '/child/product'
        return new Promise((resolve, reject) => {     
            if(!isValidToken(axios.defaults.headers.common[AXIOS_AUTH]))
            {
                reject(HTTP_ERRORS.InvalidToken);
            }
            else
            { 
                axios.defaults.headers.common[AXIOS_LANGUAGE] = localStorage.getItem(APP_LANG_KEY) || 'en';
                axios.patch( url, menu)
                .then(response => {                    
                    if ( response.status === 200 )
                    {                       
                        resolve(response.data);                        
                    }
                    else
                    {   
                        reject(response.data);
                    }
                })
                .catch(err=>{
                    reject(err);
                })
            }       
        });
    },
    deleteStoreMenu (id) {
        return new Promise((resolve, reject) => {  
            if(!isValidToken(axios.defaults.headers.common[AXIOS_AUTH]))
            {
                reject(HTTP_ERRORS.InvalidToken);
            }
            else
            { 
                axios.defaults.headers.common[AXIOS_LANGUAGE] = localStorage.getItem(APP_LANG_KEY) || 'en';
                axios.delete( `${BASE_URL}` + '/child/product/' + id )
                .then(response => {                    
                    if ( response.status === 200 )
                    {                       
                        resolve(response.data);                        
                    }
                    else
                    {   
                        reject(response.data);
                    }
                })
                .catch(err=>{
                    reject(err);
                })
            }          
        });
    },

    getStoreMenu (queryData) {

        let storeId = queryData.storeId
        let status = queryData.status
        let catId = queryData.catId === "" ? 0 : queryData.catId
        let subCatId = queryData.subCatId === "" ? 0 : queryData.subCatId
        let subSubCatId = queryData.subSubCatId === "" ? 0 : queryData.subSubCatId
        let pageNum = queryData.pageNum
        let query = queryData.query === "" ? 0 : queryData.query

        let url = `${BASE_URL}` + '/child/productWithQuery/' + storeId + "/" + status + "/" + catId + "/" + subCatId + "/" + subSubCatId + "/" + pageNum + "/" + query
        //console.log("** Pagination Service **", url)
        return new Promise((resolve, reject) => {   
            if(!isValidToken(axios.defaults.headers.common[AXIOS_AUTH]))
            {
                reject(HTTP_ERRORS.InvalidToken);
            }
            else
            { 
                axios.defaults.headers.common[AXIOS_LANGUAGE] = localStorage.getItem(APP_LANG_KEY) || 'en';
                axios.get(url)
                .then(response => {                    
                    if ( response.status === 200 )
                    {   
                        resolve(response.data);                        
                    }
                    else
                    {   
                        reject(response.data);
                    }
                })
                .catch(err=>{
                    reject(err);
                })
            }         
        });
    },
}

export default StoreMenuService;