import Cache from "./Cache";

import $ from "jquery";

import moment from "moment";
import { storageKey } from "./localize";

class UtilService {
  static getLanguage() {
    var lang = "nl";
    var cur_lang = localStorage.getItem(storageKey);

    if (cur_lang === "en") lang = "en";

    return lang;
  }

  static getNameByLang(name) {
    var cur_lang = localStorage.getItem(storageKey);

    if (cur_lang == "en") {
      return name.en;
    }

    if (name.nl == "") {
      return name.en;
    }

    return name.nl;
  }
  static getLocale(item, langCode) {
    // Return an empty string if item or langCode is falsy
    if (!item || !langCode) return "";

    let locale;

    if (Array.isArray(item) && item.length) {
      locale = langCode === "en" ? item[0] : item[1];
    } else {
      locale = item[langCode];
    }

    if (
      locale &&
      typeof locale === "object" &&
      Object.keys(locale).length === 0
    ) {
      return Array.isArray(item) && item.length ? item[0] : item["en"] ?? "";
    }

    return locale
      ? locale
      : Array.isArray(item) && item.length
      ? item[0]
      : item["en"] ?? "";
  }

  static getLangByCode(code) {
    return Cache.getLang(this.getLanguage(), String(code)) || String(code);
  }

  static initLanguage() {
    $("[data-localize]").localize("locales/", { language: this.getLanguage() });
  }

  static getObjectValueByStringPath(o, s) {
    s = s.replace(/\[(\w+)\]/g, ".$1"); // convert indexes to properties
    s = s.replace(/^\./, ""); // strip a leading dot
    var a = s.split(".");
    for (var i = 0, n = a.length; i < n; ++i) {
      var k = a[i];
      if (k in o) {
        o = o[k];
      } else {
        return;
      }
    }
    return o;
  }

  static getDateTime(dateTimeStr) {
    if (
      dateTimeStr &&
      dateTimeStr !== "" &&
      dateTimeStr !== "0001-01-01T00:00:00Z"
    )
      return moment
        .unix(new Date(dateTimeStr).getTime() / 1000)
        .format("YYYY-MM-DD HH:mm:ss");
    return "-";
  }
}

export default UtilService;
