import axios from 'axios';
import EnvDecode from '../common/EnvDecode';
import { 
    HTTP_ERRORS, 
    AXIOS_AUTH, 
    JWT_ACCESS_TOKEN, 
    isValidToken, 
    AXIOS_LANGUAGE,
    APP_LANG_KEY
} from '../components/Common/constants';

const BASE_URL = EnvDecode.getEnvPath(process.env.React_App_API_SERVER_URL);

var InvoiceService = {
    
    getInvoices(data) {
      return new Promise((resolve, reject) => {
        axios.defaults.headers.common[AXIOS_LANGUAGE] = localStorage.getItem(APP_LANG_KEY) || 'en';
        axios.post(`${BASE_URL}` + '/franchise/api/invoices', data, 
            {
                    headers:{
                      "Content-Type": "application/json", 
                      "X-Frame-Options": "sameorigin", 
                      language: "en"
                    }
            })
            .then(response => {         
              console.log("API Invoices =>", response)
                if ( response.status === 200 )
                {                       
                    resolve(response.data);                        
                }
                else
                {   
                    reject(response.data);
                }
            })
            .catch(err=>{
                reject(err);
            })
      });
    },

    getInvoiceBuffer(file_name) {
      return new Promise((resolve, reject) => {
        axios.defaults.headers.common[AXIOS_LANGUAGE] = localStorage.getItem(APP_LANG_KEY) || 'en';
        axios.get(`${BASE_URL}` + `/franchise/api/invoice/${file_name}`, 
            {
                    headers:{
                      "Content-Type": "application/json", 
                      "X-Frame-Options": "sameorigin", 
                      language: "en"
                    }
            })
            .then(response => {
              console.log("API Invoice =>", response)
                if ( response.status === 200 )
                {                       
                    resolve(response);                        
                }
                else
                {   
                    reject(response.data);
                }
            })
            .catch(err => {
                reject(err);
            })
      });
    }
}

export default InvoiceService;