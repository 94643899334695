import React from "react";
import "./style.css";

class CDropDwon extends React.Component {
  state = {
    items: this.props.items || [],
    showItems: false,
    selectedItem: this.props.items && (this.props.default ? this.props.items[this.props.default] : this.props.items[0])
  };

  dropDown = () => {
    this.setState(prevState => ({
      showItems: !prevState.showItems
    }));
  };

  selectItem = item => {
    this.setState({
      selectedItem: item,
      showItems: false
    });
    
  };

  render() {
    return (
      <div className="select-box--box">
        <div className="select-box--container">
          <div className="select-box--selected-item">
            {this.state.selectedItem.data}
          </div>
          <div className="select-box--arrow" onClick={this.dropDown}>
            <span
              className={`${
                this.state.showItems
                  ? "select-box--arrow-up"
                  : "select-box--arrow-down"
              }`}
            />
          </div>

          <div
            style={{ display: this.state.showItems ? "block" : "none"}}
            className={"select-box--items"}
          >
            {this.state.items.map(item => (
              <div
                key={item.id}
                onClick={() => {
                    this.props.onChangeItem(item)
                    this.selectItem(item)
                }}
                className={this.state.selectedItem === item ? "selected" : ""}
                name={`${item.value}`}
              >
                {item.data}
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

export default CDropDwon;
