import axios from 'axios';
import EnvDecode from '../common/EnvDecode';
import { 
    HTTP_ERRORS, 
    AXIOS_AUTH, 
    JWT_ACCESS_TOKEN, 
    isValidToken, 
    AXIOS_LANGUAGE,
    APP_LANG_KEY
} from '../components/Common/constants';

const BASE_URL = EnvDecode.getEnvPath(process.env.React_App_API_SERVER_URL);

var LoginService = {
    login (email, password) 
    {
        //console.log( 'api server url =', BASE_URL)
        return new Promise((resolve, reject) => {            
            axios.defaults.headers.common[AXIOS_LANGUAGE] = localStorage.getItem(APP_LANG_KEY) || 'en';
            axios.post(`${BASE_URL}`+ '/franchise/logIn', {email, password}, 
                        {headers:{"Content-Type": "application/json", "X-Frame-Options": "sameorigin"}})
                .then(response => {      
                    if ( response.status === 200 )
                    {                       
                        resolve(response.data);
                    }
                    else
                    {   
                        console.log("LOGIN_RESULT =>", response)
                        reject(response.data);
                    }
                })
                .catch( err => {
                    reject( err );
                })
        });
    },

    loginWithStoreId ( storeId, superToken, fcmUserTopic ) {
        return new Promise((resolve, reject) => {            
            axios.defaults.headers.common[AXIOS_LANGUAGE] = localStorage.getItem(APP_LANG_KEY) || 'en';
            axios.defaults.headers.common[AXIOS_AUTH] = 'Bearer ' + superToken;
            console.log("LoginWithStoreId API URL= ", `${BASE_URL}`, "fcmUserTopic:", fcmUserTopic, "superToken=>", superToken)
            axios.post(`${BASE_URL}` + '/franchise/logInWithStoreId', { storeId: storeId, fcmUserTopic: fcmUserTopic}, 
                        {headers:{"Content-Type": "application/json", "X-Frame-Options": "sameorigin"}})
                .then(response => {                    
                    if ( response.status === 200 )
                    {                       
                        resolve(response.data);                        
                    }
                    else
                    {   
                        reject(response.data);
                    }
                })
                .catch(err=>{
                    reject(err);
                })
        });
    },


    logout ( userId ) {
        return new Promise((resolve, reject) => {
            axios.defaults.headers.common[AXIOS_LANGUAGE] = localStorage.getItem(APP_LANG_KEY) || 'en';
            axios.post(`${BASE_URL}` + '/franchise/logout', {}, 
                        {headers:{"Content-Type": "application/json", "X-Frame-Options": "sameorigin", language: "en"}})
                .then(response => {                    
                    if ( response.status === 200 )
                    {                       
                        resolve(response.data);                        
                    }
                    else
                    {   
                        reject(response.data);
                    }
                })
                .catch(err=>{
                    reject(err);
                })
        });
    },

    updateSession() {
        // updateUserLogged
        return new Promise((resolve, reject) => {            
            //axios.post(env.API_SERVER_URL + '/admin/store/signIn', {email, password})
            axios.defaults.headers.common[AXIOS_LANGUAGE] = localStorage.getItem(APP_LANG_KEY) || 'en';
            axios.post(`${BASE_URL}` + '/franchise/updateUserLogged', {}, 
                    {headers:{"Content-Type": "application/json", "X-Frame-Options": "sameorigin"}})
                .then(response => {                    
                    if ( response.status === 200 )
                    {                       
                        resolve(response.data);                                                 
                    }
                    else
                    {   
                        reject(response.data);                         
                    }
                })
                .catch(err=>{
                    reject(err);
                })
        });
    },

    setSession(access_token) {
        if ( access_token )
        {
            
            localStorage.setItem(JWT_ACCESS_TOKEN, access_token);
            axios.defaults.headers.common[AXIOS_LANGUAGE] = 'en';
            axios.defaults.headers.common[AXIOS_AUTH] = 'Bearer ' + access_token;
        }
        else
        {
            localStorage.removeItem(JWT_ACCESS_TOKEN);
            delete axios.defaults.headers.common[AXIOS_AUTH];
            delete axios.defaults.headers.common[AXIOS_LANGUAGE];
        }
    },

    replaceSession() {
        let new_jwt_access_token = localStorage.getItem(JWT_ACCESS_TOKEN);
        axios.defaults.headers.common[AXIOS_AUTH] = 'Bearer ' + new_jwt_access_token;
    },
    
    createBlockedUser( data ) {
        return new Promise((resolve, reject) => {            
            axios.defaults.headers.common[AXIOS_LANGUAGE] = localStorage.getItem(APP_LANG_KEY) || 'en';
            axios.post(`${BASE_URL}` + '/api/blockedUser/create', data,
                    {headers:{"Content-Type": "application/json", "X-Frame-Options": "sameorigin", language: "en"}})
                .then(response => {                    
                    if ( response.status === 200 )
                    {                       
                        resolve(response.data);                                                 
                    }
                    else
                    {   
                        reject(response.data);                         
                    }
                })
                .catch(err => {
                    reject(err);
                })
        });
    },
    
    changePassword(data) {
        return new Promise((resolve, reject) => {            
            if(!isValidToken(axios.defaults.headers.common[AXIOS_AUTH]))
            {
                reject(HTTP_ERRORS.InvalidToken);
            }
            else
            {
                axios.defaults.headers.common[AXIOS_LANGUAGE] = localStorage.getItem(APP_LANG_KEY) || 'en';
                axios.post(`${BASE_URL}` + '/franchise/changePassword', data,
                            { headers: {"Content-Type": "application/json", "X-Frame-Options": "sameorigin"}})
                .then(response => {                    
                    if ( response.status === 200 )
                    {                       
                        resolve(response.data);                                                 
                    }
                    else
                    {   
                        reject(response.data);                         
                    }
                })
                .catch(err=>{
                    reject(err);
                })
            }
        });
    },
    
    forgotPassword(data) {
        return new Promise((resolve, reject) => {            
            axios.defaults.headers.common[AXIOS_LANGUAGE] = localStorage.getItem(APP_LANG_KEY) || 'en';
            axios.post(`${BASE_URL}` + '/franchise/ForgotPassword', data,
                        { headers: {"Content-Type": "application/json", "X-Frame-Options": "sameorigin"}})
            .then(response => {                    
                if ( response.status === 200 )
                {                       
                    resolve(response.data);                                                 
                }
                else
                {   
                    reject(response.data);                         
                }
            })
            .catch(err=>{
                reject(err);
            })
            
        });
    },
    
    resetPassword(data) {
        return new Promise((resolve, reject) => {            
            axios.defaults.headers.common[AXIOS_LANGUAGE] = localStorage.getItem(APP_LANG_KEY) || 'en';
            axios.post(`${BASE_URL}` + '/franchise/ResetPassword', data,
                        { headers: {"Content-Type": "application/json", "X-Frame-Options": "sameorigin"}})
            .then(response => {                    
                if ( response.status === 200 )
                {                       
                    resolve(response.data);                                                 
                }
                else
                {   
                    reject(response.data);                         
                }
            })
            .catch(err=>{
                reject(err);
            })
            
        });
    }
}

export default LoginService;