// import category from "../middlewares/category.middleware";

export const GET_CATEGORIES = 'GET_CATEGORIES';
export const GET_ALL_CATEGORIES = 'GET_ALL_CATEGORIES';
export const GET_SUB_CATEGORIES = 'GET_SUB_CATEGORIES';
export const GET_SUB_SUB_CATEGORIES = 'GET_SUB_SUB_CATEGORIES';

export const CREATE_CATEGORY = 'CREATE_CATEGORY';
export const CREATE_SUB_CATEGORY = 'CREATE_SUB_CATEGORY';
export const CREATE_SUB_SUB_CATEGORY = 'CREATE_SUB_SUB_CATEGORY';

export const DELETE_CATEGORY = 'DELETE_CATEGORY';
export const UPDATE_CATEGORY = 'UPDATE_CATEGORY';
export const UPDATE_CATEGORY_SORTING = 'UPDATE_CATEGORY_SORTING';

export const UPDATE_STORE_CATEGORY = 'UPDATE_STORE_CATEGORY';
export const UPDATE_STORE_PRODUCT = 'UPDATE_STORE_PRODUCT';

export const GOT_ERROR_CATEGORY = 'GOT_ERROR_CATEGORY';

export const UPDATE_CATEGORY_DONE = 'UPDATE_CATEGORY_DONE';
export const DELETE_CATEGORY_DONE = "DELETE_CATEGORY_DONE";
export const CREATE_CATEGORY_DONE = "CREATE_CATEGORY_DONE";
export const CREATE_SUB_CATEGORY_DONE = 'CREATE_SUB_CATEGORY_DONE';
export const CREATE_SUB_SUB_CATEGORY_DONE = 'CREATE_SUB_SUB_CATEGORY_DONE';
export const UPDATE_CATEGORY_SORTING_DONE = 'UPDATE_CATEGORY_SORTING_DONE';

export const UPDATE_STORE_CATEGORY_DONE = 'UPDATE_STORE_CATEGORY_DONE';
export const UPDATE_STORE_PRODUCT_DONE = 'UPDATE_STORE_PRODUCT_DONE';


export const GET_CATEGORIES_DONE = 'GET_CATEGORIES_DONE';
export const GET_ALL_CATEGORIES_DONE = 'GET_ALL_CATEGORIES_DONE';
export const GET_SUB_CATEGORIES_DONE = 'GET_SUB_CATEGORIES_DONE';
export const GET_SUB_SUB_CATEGORIES_DONE = 'GET_SUB_SUB_CATEGORIES_DONE';


export const OPEN_NEW_CATEGORY_DIALOG = 'OPEN_NEW_CATEGORY_DIALOG';
export const OPEN_EDIT_CATEGORY_DIALOG = 'OPEN_EDIT_CATEGORY_DIALOG';
export const CLOSE_CATEGORY_DIALOG = 'CLOSE_CATEGORY_DIALOG';

/**
 * Get category list
 */
export function getCategories(queryData) {    
    return { type: GET_CATEGORIES, queryData:queryData };
}


/**
 * Get Sub category list
 */
export function getApprovedCategories(queryData) {    
    return { type: GET_ALL_CATEGORIES, queryData:queryData };
}


/**
 * Get Sub category list
 */
 export function getSubCategories(queryData) {    
    return { type: GET_SUB_CATEGORIES, queryData:queryData };
}

/**
 * Get Sub Sub category list
 */
 export function getSubSubCategories(queryData) {    
    return { type: GET_SUB_SUB_CATEGORIES, queryData:queryData };
}


/**
 * Create Category
 */
export function createCategory(categoryData) {
    return { type: CREATE_CATEGORY, data:categoryData };
}

/**
 * Create Sub Category
 */
 export function createSubCategory(categoryData) {
    return { type: CREATE_SUB_CATEGORY, data:categoryData };
}

/**
 * Create Sub-Sub Category
 */
 export function createSubSubCategory(categoryData) {
    return { type: CREATE_SUB_SUB_CATEGORY, data:categoryData };
}


/**
 * Update Category
 */
export function updateCategory(categoryData) {
    return { type: UPDATE_CATEGORY, data:categoryData };
}

/**
 * Update Category
 */
export function updateCategorySorting(categoryData) {
    return { type: UPDATE_CATEGORY_SORTING, data:categoryData };
}

/**
 * Update Store Category
 */
 export function updateStoreCategory(categoryData) {
    return { type: UPDATE_STORE_CATEGORY, data:categoryData };
}

/**
 * Update Store Product
 */
 export function updateStoreProduct(categoryData) {
    return { type: UPDATE_STORE_PRODUCT, data:categoryData };
}


/**
 * Delete Category
 */
export function deleteCategory(categoryData) {
    return { type: DELETE_CATEGORY, data:categoryData };
}

export function openNewCategoryDialog()
{
    return {
        type: OPEN_NEW_CATEGORY_DIALOG
    }
}

export function openEditCategoryDialog(data)
{
    return {
        type: OPEN_EDIT_CATEGORY_DIALOG, data:data
    }
}

export function closeCategoryDialog()
{
    return {
        type: CLOSE_CATEGORY_DIALOG
    }
}

