import { HTTP_ERRORS } from '../../components/Common/constants';
import BrandService from '../../services/brandService';

import { GET_ACTIVE_BRANDS, GET_INACTIVE_BRANDS, CREATE_BRAND, UPDATE_BRAND
  , DELETE_BRAND, GET_ACTIVE_BRANDS_DONE, UPDATE_BRAND_DONE, DELETE_BRAND_DONE, 
  CREATE_BRAND_DONE, GOT_ERROR_BRAND, GET_INACTIVE_BRANDS_DONE,
  GET_CENTRAL_BRAND, INSERT_BRANDS, GET_CENTRAL_BRAND_DONE, INSERT_BRANDS_DONE, INVALID_TOKEN_ERROR} from '../actions/actions';

const brand = store => next => action => {
    let dispatch = store.dispatch;
    if (action.type === GET_ACTIVE_BRANDS) {       
      // Make an API call to login the server      
      BrandService.getActiveBrands( action.data ).then(result => {
        dispatch({ type: GET_ACTIVE_BRANDS_DONE, payload: result });
      })
      .catch(err=>{
        if(err === HTTP_ERRORS.InvalidToken)
        {
          dispatch({ type: INVALID_TOKEN_ERROR, payload: err });
        }
        else
        {
          dispatch({ type: GOT_ERROR_BRAND, payload: err })
        }
      })
    } else if (action.type === GET_INACTIVE_BRANDS) {
      BrandService.getInactiveBrands( action.data ).then(result => {
        dispatch({ type: GET_INACTIVE_BRANDS_DONE, payload: result });
      })
      .catch(err=>{
        if(err === HTTP_ERRORS.InvalidToken)
        {
          dispatch({ type: INVALID_TOKEN_ERROR, payload: err });
        }
        else
        {
          dispatch({ type: GOT_ERROR_BRAND, payload: err })
        }
      })
    } else if (action.type === GET_CENTRAL_BRAND) {
      BrandService.getCentralBrands().then(result => {
        dispatch({ type: GET_CENTRAL_BRAND_DONE, payload: result });
      })
      .catch(err=>{
        if(err === HTTP_ERRORS.InvalidToken)
        {
          dispatch({ type: INVALID_TOKEN_ERROR, payload: err });
        }
        else
        {
          dispatch({ type: GOT_ERROR_BRAND, payload: err })
        }
      })
    } else if (action.type === INSERT_BRANDS) {      
      BrandService.insertBrands(action.data).then(result => {
        dispatch({ type: INSERT_BRANDS_DONE, payload:result });
      })
      .catch(err=>{
        if(err === HTTP_ERRORS.InvalidToken)
        {
          dispatch({ type: INVALID_TOKEN_ERROR, payload: err });
        }
        else
        {
          dispatch({ type: GOT_ERROR_BRAND, payload: err })
        }
      })
    } else if (action.type === CREATE_BRAND) {      
      BrandService.createBrand(action.data).then(result => {
        dispatch({ type: CREATE_BRAND_DONE, payload:result });
      })
      .catch(err=>{
        if(err === HTTP_ERRORS.InvalidToken)
        {
          dispatch({ type: INVALID_TOKEN_ERROR, payload: err });
        }
        else
        {
          dispatch({ type: GOT_ERROR_BRAND, payload: err })
        }
      })
    } else if (action.type === UPDATE_BRAND) {
      BrandService.updateBrand(action.data).then(result => {        
        dispatch({ type: UPDATE_BRAND_DONE, payload:result });
      })
      .catch(err=>{
        if(err === HTTP_ERRORS.InvalidToken)
        {
          dispatch({ type: INVALID_TOKEN_ERROR, payload: err });
        }
        else
        {
          dispatch({ type: GOT_ERROR_BRAND, payload: err })
        }
      })      
    } else if (action.type === DELETE_BRAND) {      
      BrandService.deleteBrand(action.data._id).then(result => {       
        dispatch({ type: DELETE_BRAND_DONE, payload:action.data });
      })
      .catch(err=>{
        if(err === HTTP_ERRORS.InvalidToken)
        {
          dispatch({ type: INVALID_TOKEN_ERROR, payload: err });
        }
        else
        {
          dispatch({ type: GOT_ERROR_BRAND, payload: err })
        }
      }) 
    }
  
    return next(action)
  }

export default brand;