import { SCHEDULE_STATUS } from '../../components/Common/constants';
import { GET_SCHEDULES_DONE, GOT_ERROR_SCHEDULE, 
    CREATE_SCHEDULE_DONE, UPDATE_SCHEDULE_DONE, DELETE_SCHEDULE_DONE, OPEN_NEW_SCHEDULE_DIALOG, 
    OPEN_EDIT_SCHEDULE_DIALOG, CLOSE_SCHEDULE_DIALOG, ADD_SLOT_DONE, DELETE_SLOT_DONE } from '../actions/schedule.actions';

const initialState = {
    active_schedules:[],
    expired_schedules:[],
    deleted_schedules:[],

    active_schedules_count:0,
    expired_schedules_count:0,
    deleted_schedules_count:0,
    
    err_schedule:null,
    scheduleDialog     : {
        type : 'new',
        props: {
            open: false
        },
        data : null
    }
}

const scheduleReducer = (state = initialState, action) => {    
    switch (action.type) {
        case GET_SCHEDULES_DONE:
            if (action.queryData.status === 1) {
                return {
                    ...state,
                    active_schedules: action.payload.data.data,
                    active_schedules_count: action.payload.data.count
                }    
            } else if (action.queryData.status === 2) {
                return {
                    ...state,
                    expired_schedules: action.payload.data.data,
                    expired_schedules_count: action.payload.data.count
                }    
            } else if (action.queryData.status === 3) {
                return {
                    ...state,
                    deleted_schedules: action.payload.data.data,
                    deleted_schedules_count: action.payload.data.count
                }    
            }      
            break;  
        case GOT_ERROR_SCHEDULE:            
            return {
                ...state,
                err_schedule: action.payload
            }
        case CREATE_SCHEDULE_DONE:

            let schedule = action.payload.data;           

            
            if (schedule.status === 1) {                
                return {
                    ...state,
                    active_schedules: [schedule, ...state.active_schedules]
                }
            } else if (schedule.status === 2) {                
                return {
                    ...state,
                    expired_schedules: [schedule, ...state.expired_schedules]
                }
            } else if (schedule.status === 3) {                
                return {
                    ...state,
                    deleted_schedules: [schedule, ...state.deleted_schedules]
                }
            }
            break;
        case ADD_SLOT_DONE: {
            return {
                ...state               
            }            
        }   
        case DELETE_SLOT_DONE:{
            
            // let slot = action.payload

            // let itemsActive = [...state.active_schedules];
            // let itemsExpired = [...state.expired_schedules];
            // let itemsDeleted = [...state.deleted_schedules];

            // itemsActive = itemsActive.map((_schedule) => {
            //     if ( _schedule._id === slot.scheduleId )
            //     {
            //         if ( _schedule.date !== undefined )
            //             delete _schedule.data[slot.slotDate]
            //         return _schedule;
            //     }
            //     return _schedule;
            // });

            // itemsExpired = itemsExpired.map((_schedule) => {
            //     if ( _schedule._id === slot.scheduleId )
            //     {
            //         if ( _schedule.date !== undefined )
            //             delete _schedule.data[slot.slotDate]
                    
            //         return _schedule;
            //     }
            //     return _schedule;
            // });

            // itemsDeleted = itemsDeleted.map((_schedule) => {
            //     if ( _schedule._id === slot.scheduleId )
            //     {
            //         if ( _schedule.date !== undefined )
            //             delete _schedule.data[slot.slotDate]
            //         return _schedule;
            //     }
            //     return _schedule;
            // });

            return {
                ...state,
                // active_schedules:   itemsActive,
                // expired_schedules:  itemsExpired,
                // deleted_schedules:  itemsDeleted
            }            
        }
        case UPDATE_SCHEDULE_DONE:            
            
            let updateSchedule = action.payload;

            let itemsActive = [...state.active_schedules];
            let itemsExpired = [...state.expired_schedules];
            let itemsDeleted = [...state.deleted_schedules];
            
            if (updateSchedule.status === 1) {
            
                let isFound = itemsActive.find((_schedule) => {return _schedule._id === updateSchedule._id});
                // If status is updated
                if (!isFound) {
                    itemsActive     = [updateSchedule, ...itemsActive]
                    itemsExpired    =  itemsExpired.filter(_schedule => updateSchedule._id !== _schedule._id);
                    itemsDeleted    =  itemsDeleted.filter(_schedule => updateSchedule._id !== _schedule._id);                    
                }

                itemsActive = itemsActive.map((_schedule) => {
                    if ( _schedule._id === updateSchedule._id )
                    {
                        return updateSchedule;
                    }
                    return _schedule;
                });
                return {
                    ...state,
                    active_schedules:   itemsActive,
                    expired_schedules:  itemsExpired,
                    deleted_schedules:  itemsDeleted
                }

            } else if (updateSchedule.status === 2) {
            
                let isFound = itemsExpired.find((_schedule) => {return _schedule._id === updateSchedule._id});
                // If status is updated
                if (!isFound) {
                    itemsExpired     = [updateSchedule, ...itemsExpired]
                    itemsActive    =  itemsActive.filter(_schedule => updateSchedule._id !== _schedule._id);
                    itemsDeleted    =  itemsDeleted.filter(_schedule => updateSchedule._id !== _schedule._id);                    
                }

                itemsExpired = itemsExpired.map((_schedule) => {
                    if ( _schedule._id === updateSchedule._id )
                    {
                        return updateSchedule;
                    }
                    return _schedule;
                });
                return {
                    ...state,
                    active_schedules:   itemsActive,
                    expired_schedules:  itemsExpired,
                    deleted_schedules:  itemsDeleted
                }

            } else if (updateSchedule.status === 3) {
            
                let isFound = itemsDeleted.find((_schedule) => {return _schedule._id === updateSchedule._id});
                // If status is updated
                if (!isFound) {
                    itemsDeleted     = [updateSchedule, ...itemsDeleted]
                    itemsActive      =  itemsActive.filter(_schedule => updateSchedule._id !== _schedule._id);
                    itemsExpired     =  itemsExpired.filter(_schedule => updateSchedule._id !== _schedule._id);                    
                }

                itemsDeleted = itemsDeleted.map((_schedule) => {
                    if ( _schedule._id === updateSchedule._id )
                    {
                        return updateSchedule;
                    }
                    return _schedule;
                });
                return {
                    ...state,
                    active_schedules:   itemsActive,
                    expired_schedules:  itemsExpired,
                    deleted_schedules:  itemsDeleted
                }
            }
            return {
                ...state,
                err_schedule: null
            }                
                
        case DELETE_SCHEDULE_DONE:
            let id = action.payload._id;
            console.log("DELETED Schedule => ", action.payload)
            /*if (action.payload.status === SCHEDULE_STATUS.ACTIVE) {
                let itemsSchedule = [...state.active_schedules];
                itemsSchedule = itemsSchedule.filter(_schedule => id !== _schedule._id);
                return {
                    ...state,
                    active_schedules: itemsSchedule
                }

            } else if (action.payload.status === SCHEDULE_STATUS.EXPIRED) {                        
                let itemsSchedule = [...state.expired_schedules];
                itemsSchedule = itemsSchedule.filter(_schedule => id !== _schedule._id);
                return {
                    ...state,
                    expired_schedules: itemsSchedule
                }
            } else */
            if (action.payload.status === SCHEDULE_STATUS.DELETED) {                        
                let itemsSchedule = [...state.deleted_schedules];
                itemsSchedule = itemsSchedule.filter(_schedule => id !== _schedule._id);
                return {
                    ...state,
                    deleted_schedules: itemsSchedule
                }
            }
            break;
        case OPEN_NEW_SCHEDULE_DIALOG:
            return {
                ...state,
                scheduleDialog: {
                    type : 'new',
                    props: {
                        open: true
                    },
                    data : null
                }
            };
            
        case OPEN_EDIT_SCHEDULE_DIALOG:
            return {
                ...state,
                scheduleDialog: {
                    type : 'edit',
                    props: {
                        open: true
                    },
                    data : action.data
                }
            };
        case CLOSE_SCHEDULE_DIALOG:
            return {
                ...state,
                scheduleDialog: {
                    type : 'new',
                    props: {
                        open: false
                    },
                    data : null
                }
            };
                
        default:
            return state;
    }
}

export default scheduleReducer;