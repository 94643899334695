import React from "react";
import { useReactToPrint } from "react-to-print";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { COLORS } from "../../components/Common/constants";
import OrderRenderContent from "./orderRenderContent";

export const RenderModal = ({
  isOpenDetail,
  onClose,
  style,
  order,
  orderForAndroid,
  getAddonName,
}) => {
  const componentRef = React.useRef(null);

  const reactToPrintContent = () => {
    return componentRef.current;
  };

  const handlePrint = useReactToPrint({
    documentTitle: "Order Detail",
    contentRef: componentRef,
  });

  return (
    <Modal
      isOpen={isOpenDetail}
      toggle={onClose}
      style={style || { marginTop: "200px", lineHeight: "0.05rem" }}
      className="font-arial-bold"
    >
      {order && (
        <div id="printSectionOrder" ref={componentRef}>
          <ModalHeader
            className="text-center w-100 mx-auto d-flex justify-content-center"
            style={{
              padding: "0",
              border: 0,
            }}
          >
            <div className="w-100 text-center">
              <img
                className="img-fluid mx-auto"
                src="img/notify_logo.svg"
                alt="App Logo"
                style={{ width: "140px", height: "140px", marginTop: "-80px" }}
              />
            </div>
            <p
              className="mx-auto"
              style={{
                color: COLORS.GENERAL,
                fontSize: "20px",
              }}
            >
              {orderForAndroid.orderHeader}
            </p>
          </ModalHeader>

          <ModalBody
            style={{
              marginLeft: "30px",
              marginRight: "30px",
              fontSize: "16px",
              lineHeight: 1,
            }}
          >
            <OrderRenderContent
              onClose={onClose}
              order={order}
              orderForAndroid={orderForAndroid}
              handlePrint={() => handlePrint()}
              getAddonName={getAddonName}
            />
          </ModalBody>
        </div>
      )}
      <div style={{ marginBottom: "20px" }} />
    </Modal>
  );
};
