export const GET_STORE_MENU = 'GET_STORE_MENU';
export const CREATE_STORE_MENU = 'CREATE_STORE_MENU';
export const DELETE_STORE_MENU = 'DELETE_STORE_MENU';
export const UPDATE_STORE_MENU = 'UPDATE_STORE_MENU';
export const GOT_ERROR_STORE_MENU = 'GOT_ERROR_STORE_MENU';

export const UPDATE_STORE_MENU_DONE = 'UPDATE_STORE_MENU_DONE';
export const DELETE_STORE_MENU_DONE = "DELETE_STORE_MENU_DONE";
export const CREATE_STORE_MENU_DONE = "CREATE_STORE_MENU_DONE";

export const GET_STORE_MENU_DONE = 'GET_STORE_MENU_DONE';

export const OPEN_NEW_STORE_MENU_DIALOG = 'OPEN_NEW_STORE_MENU_DIALOG';
export const OPEN_EDIT_STORE_MENU_DIALOG = 'OPEN_EDIT_STORE_MENU_DIALOG';
export const OPEN_VIEW_STORE_MENU_ADDON_DIALOG = "OPEN_VIEW_STORE_MENU_ADDON_DIALOG";
export const CLOSE_STORE_MENU_DIALOG = 'CLOSE_STORE_MENU_DIALOG';
/**
 * Get active schedule list
 */
export function getStoreMenu(queryData) {    
    return { type: GET_STORE_MENU, queryData:queryData };
}

/**
 * Create Manager
 */
export function createStoreMenu( menu ) {
    return { type: CREATE_STORE_MENU, data:menu };
}

/**
 * Update Manager
 */
export function updateStoreMenu( menu ) {
    return { type: UPDATE_STORE_MENU, data:menu };
}

/**
 * Delete Manager
 */
export function deleteStoreMenu( menu ) {
    return { type: DELETE_STORE_MENU, data:menu };
}

export function openNewStoreMenuDialog()
{
    return {
        type: OPEN_NEW_STORE_MENU_DIALOG
    }
}

export function openEditStoreMenuDialog(data)
{
    return {
        type: OPEN_EDIT_STORE_MENU_DIALOG, data:data
    }
}

export function openViewStoreMenuAddonDialog(data)
{
    //console.log( 'store menu action ', data)
    return {
        type: OPEN_VIEW_STORE_MENU_ADDON_DIALOG,
        data: data
    }
}

export function closeStoreMenuDialog()
{
    return {
        type: CLOSE_STORE_MENU_DIALOG
    }
}

