import { GET_WALLETS_DONE, GOT_ERROR_WALLETS} from '../actions/wallets.actions';

const initialState = {
    wallets:[],
    wallets_count: 0,
    err_wallet:null,    
}

const walletReducer = (state = initialState, action) => {    
    switch (action.type) {
        case GET_WALLETS_DONE:
            return {
                ...state,
                wallets: action.payload.data,
                wallets_count: action.payload.count
            }
       
        case GOT_ERROR_WALLETS:            
            return {
                ...state,
                err_wallet: action.payload
            }   
        default:
            return state;
    }
}

export default walletReducer;