import { HTTP_ERRORS } from '../../components/Common/constants';
import InvoiceService from '../../services/invoiceService';
import { INVALID_TOKEN_ERROR } from '../actions/auth.actions';

import { GET_INVOICES, GET_INVOICES_FAILED, GET_INVOICES_SUCCESS, GET_INVOICE_BUFFER, GET_INVOICE_BUFFER_FAILED, GET_INVOICE_BUFFER_SUCCESS } from '../actions/invoice.actions';

const invoice = store => next => action => {
    let dispatch = store.dispatch;
    if (action.type === GET_INVOICES) {       
      InvoiceService.getInvoices(action.data)
      .then(result => {
        console.log("get invoices =>", result);
        dispatch({ type: GET_INVOICES_SUCCESS, payload: result });
      })
      .catch(err => {
        if(err === HTTP_ERRORS.InvalidToken)
        {
          dispatch({ type: INVALID_TOKEN_ERROR, payload: err });
        }
        else
        {
          console.log("get invoices failed =>", err )
          dispatch({ type: GET_INVOICES_FAILED, payload: err })
        }
      })
    }
    else if (action.type === GET_INVOICE_BUFFER) {
      InvoiceService.getInvoiceBuffer(action.data)
      .then(result => {
        console.log("get invoice buffer =>", result);
        dispatch({ type: GET_INVOICE_BUFFER_SUCCESS, payload: result });
      })
      .catch(err => {
        if(err === HTTP_ERRORS.InvalidToken)
        {
          dispatch({ type: INVALID_TOKEN_ERROR, payload: err });
        }
        else
        {
          console.log("get invoice buffer failed =>", err )
          dispatch({ type: GET_INVOICE_BUFFER_FAILED, payload: err })
        }
      })
    }
    return next(action)
  }

export default invoice;