import { HTTP_ERRORS } from '../../components/Common/constants';
import OfferService from '../../services/offerService';

import {
  CREATE_OFFER,GET_OFFER,UPDATE_OFFER,GET_OFFERS_BY_STATUS,GET_PRODUCTS_BY_STORE_ID,
  UPDATE_OFFER_BY_STATUS,GET_CATEGORY_LIST_BY_STORE_ID,
  GET_SUB_CATEGORY_LIST_BY_STORE_ID,GET_SUB_SUB_CATEGORY_LIST_BY_STORE_ID,
  GET_UNIT_LIST_BY_PRODUCT_ID,
  GET_CITIES, CREATE_OFFER_DONE, GET_OFFER_DONE, UPDATE_OFFER_DONE,
  GET_OFFERS_BY_STATUS_DONE,GET_PRODUCTS_BY_STORE_ID_DONE,
  UPDATE_OFFER_BY_STATUS_DONE, GET_CATEGORY_LIST_BY_STORE_ID_DONE, 
  GET_SUB_CATEGORY_LIST_BY_STORE_ID_DONE, 
  GET_SUB_SUB_CATEGORY_LIST_BY_STORE_ID_DONE, 
  GET_UNIT_LIST_BY_PRODUCT_ID_DONE, GET_CITIES_DONE,
  OPEN_CONFIRM_DIALOG, GET_OFFER_ERROR, INVALID_TOKEN_ERROR} from '../actions/actions';

const offer = store => next => action => {
    let dispatch = store.dispatch;
    if (action.type === CREATE_OFFER) {       
      OfferService.createOffer( action.data ).then(result => {        
        dispatch({ type: CREATE_OFFER_DONE, payload: action.data });
        dispatch({ type: OPEN_CONFIRM_DIALOG, payload: {
          title:'Created Offer ', 
          description: result, //"offers is created soon...!!!!",
          action_type:'', 
          dialog_type:'info'
        }
      });
      })
      .catch(err=>{
        if(err === HTTP_ERRORS.InvalidToken)
        {
          dispatch({ type: INVALID_TOKEN_ERROR, payload: err });
        }
        else
        {
          dispatch({ type: GET_OFFER_ERROR, payload: err })
        }
      })
    } else if (action.type === GET_OFFER) {
      OfferService.getOfferById( action.data ).then(result => {
        
        dispatch({ type: GET_OFFER_DONE, payload: result });
      })
      .catch(err=>{
        if(err === HTTP_ERRORS.InvalidToken)
        {
          dispatch({ type: INVALID_TOKEN_ERROR, payload: err });
        }
        else
        {
          dispatch({ type: GET_OFFER_ERROR, payload: err })
        }
      })
    } else if (action.type === UPDATE_OFFER) {      
      OfferService.updateOffer(action.data).then(result => {
        dispatch({ type: UPDATE_OFFER_DONE, payload:action.data });
      })
      .catch(err=>{
        if(err === HTTP_ERRORS.InvalidToken)
        {
          dispatch({ type: INVALID_TOKEN_ERROR, payload: err });
        }
        else
        {
          dispatch({ type: GET_OFFER_ERROR, payload: err })
        }
      })
    } else if (action.type === GET_OFFERS_BY_STATUS) {      
      OfferService.getOffersByStatus(action.data).then(result => {
        dispatch({ type: GET_OFFERS_BY_STATUS_DONE, payload:result, queryData:action.data });
      })
      .catch(err=>{
        if(err === HTTP_ERRORS.InvalidToken)
        {
          dispatch({ type: INVALID_TOKEN_ERROR, payload: err });
        }
        else
        {
          dispatch({ type: GET_OFFER_ERROR, payload: err })
        }
      })
    } else if (action.type === GET_PRODUCTS_BY_STORE_ID) {      
      OfferService.getProductsByStoreId(action.data).then(result => {
        dispatch({ type: GET_PRODUCTS_BY_STORE_ID_DONE, payload:result });
      })
      .catch(err=>{
        if(err === HTTP_ERRORS.InvalidToken)
        {
          dispatch({ type: INVALID_TOKEN_ERROR, payload: err });
        }
        else
        {
          dispatch({ type: GET_OFFER_ERROR, payload: err })
        }
      })
    } else if (action.type === UPDATE_OFFER_BY_STATUS) {      
      OfferService.updateOfferStatus(action.data).then(result => {
        dispatch({ type: UPDATE_OFFER_BY_STATUS_DONE, payload:result, queryData:action.data });
      })
      .catch(err=>{
        if(err === HTTP_ERRORS.InvalidToken)
        {
          dispatch({ type: INVALID_TOKEN_ERROR, payload: err });
        }
        else
        {
          dispatch({ type: GET_OFFER_ERROR, payload: err })
        }
      })
    } else if (action.type === GET_CATEGORY_LIST_BY_STORE_ID) {      
      OfferService.getCategoryListByStoreId(action.data).then(result => {
        dispatch({ type: GET_CATEGORY_LIST_BY_STORE_ID_DONE, payload:result });
      })
      .catch(err=>{
        if(err === HTTP_ERRORS.InvalidToken)
        {
          dispatch({ type: INVALID_TOKEN_ERROR, payload: err });
        }
        else
        {
          dispatch({ type: GET_OFFER_ERROR, payload: err })
        }
      })
    } else if (action.type === GET_SUB_CATEGORY_LIST_BY_STORE_ID) {      
      OfferService.getSubCategoryListByStoreId(action.data).then(result => {
        dispatch({ type: GET_SUB_CATEGORY_LIST_BY_STORE_ID_DONE, payload:result });
      })
      .catch(err=>{
        if(err === HTTP_ERRORS.InvalidToken)
        {
          dispatch({ type: INVALID_TOKEN_ERROR, payload: err });
        }
        else
        {
          dispatch({ type: GET_OFFER_ERROR, payload: err })
        }
      })
    } else if (action.type === GET_SUB_SUB_CATEGORY_LIST_BY_STORE_ID) {      
      OfferService.getSubSubCategoryListByStoreId(action.data).then(result => {
        dispatch({ type: GET_SUB_SUB_CATEGORY_LIST_BY_STORE_ID_DONE, payload:result });
      })
      .catch(err=>{
        if(err === HTTP_ERRORS.InvalidToken)
        {
          dispatch({ type: INVALID_TOKEN_ERROR, payload: err });
        }
        else
        {
          dispatch({ type: GET_OFFER_ERROR, payload: err })
        }
      })
    } else if (action.type === GET_UNIT_LIST_BY_PRODUCT_ID ) {
      OfferService.getUnitListByProductId(action.data).then(result => {
        dispatch({ type: GET_UNIT_LIST_BY_PRODUCT_ID_DONE, payload:result });
      })
      .catch(err=>{
        if(err === HTTP_ERRORS.InvalidToken)
        {
          dispatch({ type: INVALID_TOKEN_ERROR, payload: err });
        }
        else
        {
          dispatch({ type: GET_OFFER_ERROR, payload: err })
        }
      })
    }
    else if (action.type === GET_CITIES) {      
      OfferService.getCities().then(result => {
        dispatch({ type: GET_CITIES_DONE, payload:result });
      })
      .catch(err=>{
        if(err === HTTP_ERRORS.InvalidToken)
        {
          dispatch({ type: INVALID_TOKEN_ERROR, payload: err });
        }
        else
        {
          dispatch({ type: GET_OFFER_ERROR, payload: err })
        }
      })
    }

  
    return next(action)
  }

export default offer;