import { HTTP_ERRORS } from '../../components/Common/constants';
import TicketService from '../../services/ticketService';

import { 
  GET_TICKETS, 
  GET_TICKETS_DONE, 
  GOT_ERROR_TICKET, 
  INVALID_TOKEN_ERROR} from '../actions/actions';

const ticket = store => next => action => {
    let dispatch = store.dispatch;
    if (action.type === GET_TICKETS) {       
      // Make an API call to login the server      
      TicketService.getAllTickets( action.data ).then(result => {
        //console.log( 'ticket middle =', result )
        dispatch({ type: GET_TICKETS_DONE, payload: result });
      })
      .catch(err=>{
        if(err === HTTP_ERRORS.InvalidToken)
        {
          dispatch({ type: INVALID_TOKEN_ERROR, payload: err });
        }
        else
        {
          //console.log( 'ticket middle error =', err )
          dispatch({ type: GOT_ERROR_TICKET, payload: err })
        }
      })
    }
    return next(action)
}

export default ticket;