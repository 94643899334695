export const UPDATE_STORE_STATUS = 'UPDATE_STORE_STATUS';
export const UPDATE_STORE_STATUS_DONE = 'UPDATE_STORE_STATUS_DONE';
export const GOT_ERROR_STORE_STATUS = 'GOT_ERROR_STORE_STATUS';

/**
 * Update Store status
 */
export function updateStoreStatus( storeStatus ) {
    return { type: UPDATE_STORE_STATUS, data: storeStatus};
}

