import { UPDATE_STORE_STATUS_DONE, GOT_ERROR_STORE_STATUS, } from '../actions/storeStatus.actions';

const initialState = {
    updateStoreStatus:[],
    err_update:null,
}

const storeStatusReducer = (state = initialState, action) => {  
    switch (action.type) {
        case UPDATE_STORE_STATUS_DONE:

            //let updateStoreStatus = action.payload;
            return {
                ...state,
                updateStoreStatus: action.payload
            }
            break;
        case GOT_ERROR_STORE_STATUS:            
            return {
                ...state,
                err_update: action.payload
            }
        
        default:
            return state;
    }
}

export default storeStatusReducer;