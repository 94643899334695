import { HTTP_ERRORS } from '../../components/Common/constants';
import ManagerService from '../../services/managerService';

import { GET_MANAGERS, CREATE_MANAGER, UPDATE_MANAGER, 
  DELETE_MANAGER, GET_MANAGERS_DONE, UPDATE_MANAGER_DONE, 
  DELETE_MANAGER_DONE, CREATE_MANAGER_DONE, GOT_ERROR_MANAGER, 
  INVALID_TOKEN_ERROR, EMAIL_EXIST_ALREADY
} from '../actions/actions';

const manager = store => next => action => {
    let dispatch = store.dispatch;
    if (action.type === GET_MANAGERS) {       
      // Make an API call to login the server            
      ManagerService.getManagers(action.queryData).then(result => {        
        dispatch({ type: GET_MANAGERS_DONE, payload: result, queryData:action.queryData });        
      })
      .catch(err=>{
        if(err === HTTP_ERRORS.InvalidToken)
        {
          dispatch({ type: INVALID_TOKEN_ERROR, payload: err });
        }
        else
        {
          // dispatch({ type: GOT_ERROR_MANAGER, payload: err })
        }
      })
    } else if (action.type === CREATE_MANAGER) {      
      ManagerService.createManager(action.data).then(result => {
        //console.log("CreateManager = ", result)
        dispatch({ type: CREATE_MANAGER_DONE, payload:result });
      })
      .catch(err=>{
        if(err === HTTP_ERRORS.InvalidToken)
        {
          dispatch({ type: INVALID_TOKEN_ERROR, payload: err });
        }
        else if(err === HTTP_ERRORS.emailIsExistAlready)
        {
          dispatch({ type: EMAIL_EXIST_ALREADY})
        }
        else
        {
          dispatch({ type: GOT_ERROR_MANAGER, payload: err })
        }
      })
    } else if (action.type === UPDATE_MANAGER) {
      ManagerService.updateManager(action.data).then(result => {        
        dispatch({ type: UPDATE_MANAGER_DONE, payload:action.data });
      })
      .catch(err=>{
        if(err === HTTP_ERRORS.InvalidToken)
        {
          dispatch({ type: INVALID_TOKEN_ERROR, payload: err });
        }
        else
        {
          dispatch({ type: GOT_ERROR_MANAGER, payload: err })
        }
      })      
    } else if (action.type === DELETE_MANAGER) {  
      ManagerService.deleteManager(action.data._id).then(result => {       
        dispatch({ type: DELETE_MANAGER_DONE, payload:action.data });
      })
      .catch(err=>{
        if(err === HTTP_ERRORS.InvalidToken)
        {
          dispatch({ type: INVALID_TOKEN_ERROR, payload: err });
        }
        else
        {
          dispatch({ type: GOT_ERROR_MANAGER, payload: err })
        }
      }) 
    }
  
    return next(action)
  }

export default manager;