export const GET_ADDONS = 'GET_ADDONS';
export const GET_ADDONS_GROUP = 'GET_ADDONS_GROUP';

export const CREATE_ADDON = 'CREATE_ADDON';
export const DELETE_ADDON = 'DELETE_ADDON';
export const UPDATE_ADDON = 'UPDATE_ADDON';
export const GOT_ERROR_ADDON = 'GOT_ERROR_ADDON';

export const UPDATE_ADDON_DONE = 'UPDATE_ADDON_DONE';
export const DELETE_ADDON_DONE = "DELETE_ADDON_DONE";
export const CREATE_ADDON_DONE = "CREATE_ADDON_DONE";

export const GET_ADDONS_DONE = 'GET_ADDONS_DONE';
export const GET_ADDONS_GROUP_DONE = 'GET_ADDONS_GROUP_DONE';

export const OPEN_NEW_ADDON_DIALOG = 'OPEN_NEW_ADDON_DIALOG';
export const OPEN_EDIT_ADDON_DIALOG = 'OPEN_EDIT_ADDON_DIALOG';
export const CLOSE_ADDON_DIALOG = 'CLOSE_ADDON_DIALOG';

export const OPEN_NEW_ADDONITEM_DIALOG = 'OPEN_NEW_ADDONITEM_DIALOG';
export const OPEN_EDIT_ADDONITEM_DIALOG = 'OPEN_EDIT_ADDONITEM_DIALOG';
export const CLOSE_ADDONITEM_DIALOG = 'CLOSE_ADDONITEM_DIALOG';
/**
 * Get addon list
 */
export function getAddons(queryData) {    
    return { type: GET_ADDONS, queryData:queryData };
}

/**
 * Get addonsGroup list
 */
 export function getAddonsGroup(queryData) {    

    return { type: GET_ADDONS_GROUP, queryData:queryData };
}


/**
 * Create Addon
 */
export function createAddon(addonData) {
    return { type: CREATE_ADDON, data:addonData };
}

/**
 * Update Addon
 */
export function updateAddon(addonData) {
    return { type: UPDATE_ADDON, data:addonData };
}

/**
 * Delete Addon
 */
export function deleteAddon(addonData) {
    return { type: DELETE_ADDON, data:addonData };
}

export function openNewAddonDialog()
{
    return {
        type: OPEN_NEW_ADDON_DIALOG
    }
}

export function openEditAddonDialog(addonData)
{
    return {
        type: OPEN_EDIT_ADDON_DIALOG, data:addonData
    }
}

export function closeAddonDialog()
{
    return {
        type: CLOSE_ADDON_DIALOG
    }
}

export function openNewAddonItemDialog()
{
    return {
        type: OPEN_NEW_ADDONITEM_DIALOG
    }
}

export function openEditAddonItemDialog(data, index)
{
    return {
        type: OPEN_EDIT_ADDONITEM_DIALOG, data:data, index:index
    }
}

export function closeAddonItemDialog()
{
    return {
        type: CLOSE_ADDONITEM_DIALOG
    }
}

