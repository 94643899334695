
import _ from 'lodash';
import moment from 'moment'
import { JWT_ACCESS_TOKEN } from '../components/Common/constants';

class UtilService {

    static filterArrayByString(mainArr, searchText)
    {
        if ( searchText === '' )
        {
            return mainArr;
        }

        searchText = searchText.toLowerCase();

        return mainArr.filter(itemObj => {
            return this.searchInObj(itemObj, searchText);
        });
    };

    static searchInObj(itemObj, searchText)
    {
        for ( const prop in itemObj )
        {
            if ( !itemObj.hasOwnProperty(prop) )
            {
                continue;
            }

            const value = itemObj[prop];

            if ( typeof value === 'string' )
            {
                if ( this.searchInString(value, searchText) )
                {
                    return true;
                }
            }

            else if ( Array.isArray(value) )
            {
                if ( this.searchInArray(value, searchText) )
                {
                    return true;
                }
            }

            if ( typeof value === 'object' )
            {
                if ( this.searchInObj(value, searchText) )
                {
                    return true;
                }
            }
        }
    }

    static searchInArray(arr, searchText)
    {
        for ( const value of arr )
        {
            if ( typeof value === 'string' )
            {
                if ( this.searchInString(value, searchText) )
                {
                    return true;
                }
            }

            if ( typeof value === 'object' )
            {
                if ( this.searchInObj(value, searchText) )
                {
                    return true;
                }
            }
        }
    }

    static searchInString(value, searchText)
    {
        return value.toLowerCase().includes(searchText);
    }

    static generateGUID()
    {
        function S4()
        {
            return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
        }

        return S4() + S4();
    }

    static toggleInArray(item, array)
    {
        if ( array.indexOf(item) === -1 )
        {
            array.push(item);
        }
        else
        {
            array.splice(array.indexOf(item), 1);
        }
    }

    static handleize(text)
    {
        return text.toString().toLowerCase()
            .replace(/\s+/g, '-')           // Replace spaces with -
            .replace(/\W+/g, '')       // Remove all non-word chars
            .replace(/--+/g, '-')         // Replace multiple - with single -
            .replace(/^-+/, '')             // Trim - from start of text
            .replace(/-+$/, '');            // Trim - from end of text
    }

    static setRoutes(config)
    {
        let routes = [...config.routes];

        if ( config.settings || config.auth )
        {
            routes = routes.map((route) => {
                let auth = config.auth ? [...config.auth] : [];
                auth = route.auth ? [...auth, ...route.auth] : auth;
                return {
                    ...route,
                    settings: {...config.settings, ...route.settings},
                    auth
                };
            });
        }

        return [...routes];
    }

    static generateRoutesFromConfigs(configs)
    {
        let allRoutes = [];
        configs.forEach((config) => {
            allRoutes = [
                ...allRoutes,
                ...this.setRoutes(config)
            ]
        });
        return allRoutes;
    }

    static findById(o, id)
    {
        //Early return
        if ( o.id === id )
        {
            return o;
        }
        let result, p;
        for ( p in o )
        {
            if ( o.hasOwnProperty(p) && typeof o[p] === 'object' )
            {
                result = this.findById(o[p], id);
                if ( result )
                {
                    return result;
                }
            }
        }
        return result;
    }


    static difference(object, base)
    {
        function changes(object, base)
        {
            return _.transform(object, function (result, value, key) {
                if ( !_.isEqual(value, base[key]) )
                {
                    result[key] = (_.isObject(value) && _.isObject(base[key])) ? changes(value, base[key]) : value;
                }
            });
        }

        return changes(object, base);
    }

    static getDate(dateTimeStr) {
        if (!dateTimeStr || dateTimeStr === "")
            return ""

        return moment.unix(new Date(dateTimeStr).getTime() / 1000).format("YYYY-MM-DD");
    }

    static getMonth(dateTimeStr) {
        if (!dateTimeStr || dateTimeStr === "")
            return ""

        return moment.unix(new Date(dateTimeStr).getTime() / 1000).format("YYYY-MM");
    }
    static getTime(dateTimeStr) {
        return moment.unix(new Date(dateTimeStr).getTime() / 1000).format("HH:mm:ss");
    }
    static getDateTimeISO(dateTimeStr) {
        //return `${moment.unix(new Date(dateTimeStr).getTime() / 1000).toISOString(true).substring(0, 23)}Z`;
        var ddd = moment(new Date(dateTimeStr)).toISOString(false);
        //console.log("*************** ", ddd)
        var date = new Date(ddd);

        return date.toISOString();
    }

    static getTimeExceptSecond(dateTimeStr) {
        //console.log("** dateTimeStr = **", dateTimeStr, moment.unix(new Date(dateTimeStr).getTime() / 1000).format("HH:mm"))
        // return moment.unix(new Date(dateTimeStr).getTime() / 1000).format("HH:mm");
        return moment(dateTimeStr).format("HH:mm");
    }
    static getDateTimeByFormat(ts, format) {
        return moment.unix(new Date(ts).getTime() / 1000).format(format);
    }
    static getDateStrFromMoment(momentObj, format) {
        return momentObj.format(format);
    }

    static getDateFromFormat(str, format) {
        if (str == null) {
            return new Date();
        }
        return moment(str, format).toDate();
    }

    static formatDate(date, format = "DD/MM/YYYY") {
        // return moment(new Date(date)).format(format);
        return moment(date).format(format);
    }

    static getGeneralDateFormat () {
        return "DD/MM/YYYY";
    }

    static getJWTToken = () => {
        return localStorage.getItem(JWT_ACCESS_TOKEN);
    }

    static getPathOfUrl = (url, removeFirstSlash = false) => {
        let pathName = "";
        
        if(url) {
            const urlObj = new URL(url);
            pathName = removeFirstSlash ? urlObj.pathname.substring(1) : urlObj.pathname;
        }

        return pathName;
    }

}

export default UtilService;
