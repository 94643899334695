import {Base64} from 'js-base64';

class EnvDecode
{
    static getEnvPath(encoded_str){
        if(encoded_str === undefined)
        {
            return "";
        }
        return Base64.decode(encoded_str);
    }
}

export default EnvDecode;