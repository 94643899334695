import { GET_ACTIVE_BRANDS_DONE, GET_INACTIVE_BRANDS_DONE, GOT_ERROR_BRAND, 
    CREATE_BRAND_DONE, UPDATE_BRAND_DONE, DELETE_BRAND_DONE, GET_CENTRAL_BRAND_DONE, INSERT_BRANDS_DONE,
    OPEN_NEW_BRAND_DIALOG, OPEN_EDIT_BRAND_DIALOG, CLOSE_BRAND_DIALOG} from '../actions/brand.actions';

const initialState = {
    central_brands: [],
    active_brands:[],    
    inactive_brands:[],

    active_brands_count: 0,
    inactive_brands_count: 0,

    err_brand:null,
    brandDialog     : {
        type : 'new',
        props: {
            open: false
        },
        data : null
    }
}

const brandReducer = (state = initialState, action) => {    
    switch (action.type) {
        case GET_ACTIVE_BRANDS_DONE:
            return {
                ...state,
                active_brands: action.payload.data,
                active_brands_count: action.payload.count
            }
            
        case GET_INACTIVE_BRANDS_DONE:  
            return {
                ...state,
                inactive_brands: action.payload.data,
                inactive_brands_count: action.payload.count
            }
        case GET_CENTRAL_BRAND_DONE:
            return{
                ...state,
                central_brands: action.payload
            }
        case GOT_ERROR_BRAND:            
            return {
                ...state,
                err_brand: action.payload
            }
        case CREATE_BRAND_DONE:
            let brand = action.payload.data.ops[0];
            
            if (brand.status === 1) {                
                return {
                    ...state,
                    active_brands: [brand, ...state.active_brands]
                }

            } else {                
                return {
                    ...state,
                    inactive_brands: [brand,...state.inactive_brands]
                }
            }
        case INSERT_BRANDS_DONE:{
            let brands = action.payload.data;
            let itemsBrand = [...state.active_brands]
            brands.map( bd => {
                itemsBrand.push(bd)
                return bd
            })
            return {
                ...state,
                active_brands: itemsBrand
            }
        }
        case UPDATE_BRAND_DONE:
            let updateBrand = action.payload.data;

            let itemsBrand = [...state.active_brands];
            let itemsInactiveBrand = [...state.inactive_brands];
            
            if (updateBrand.status === 1) {
               
                let isFound = itemsBrand.find((_brand) => {return _brand._id === updateBrand._id});
                // If status is updated
                if (!isFound) {
                    itemsBrand = [updateBrand, ...itemsBrand]
                    itemsInactiveBrand =  itemsInactiveBrand.filter(_brand => updateBrand._id !== _brand._id);
                }

                itemsBrand = itemsBrand.map((_brand) => {
                    if ( _brand._id === updateBrand._id )
                    {
                        return updateBrand;
                    }
                    return _brand;
                });
                return {
                    ...state,
                    active_brands: itemsBrand,
                    inactive_brands: itemsInactiveBrand
                }

            } else {
                let isFound = itemsInactiveBrand.find((_brand) => {return _brand._id === updateBrand._id});
                // If status is updated
                if (!isFound) {
                    itemsInactiveBrand = [updateBrand, ...itemsInactiveBrand]
                    itemsBrand =  itemsBrand.filter(_brand => updateBrand._id !== _brand._id);
                }
                
                itemsInactiveBrand = itemsInactiveBrand.map((_brand) => {
                    if ( _brand._id === updateBrand._id )
                    {
                        return updateBrand;
                    }
                    return _brand;
                });
                return {
                    ...state,
                    active_brands: itemsBrand,
                    inactive_brands: itemsInactiveBrand
                }
            }     
        case DELETE_BRAND_DONE:
            let id = action.payload._id;
            if (action.payload.status === 1) {
                let itemsBrand = [...state.active_brands];
                itemsBrand = itemsBrand.filter(_brand => id !== _brand._id);

                return {
                    ...state,
                    active_brands: itemsBrand
                }

            } else {                        
                let itemsBrand = [...state.inactive_brands];
                itemsBrand = itemsBrand.filter(_brand => id !== _brand._id);
                return {
                    ...state,
                    inactive_brands: itemsBrand
                }
            }
        case OPEN_NEW_BRAND_DIALOG:
            // let status = action.payload.status;

            return {
                ...state,
                brandDialog: {
                    type : 'new',
                    props: {
                        open: true,
                        status: 0
                    },
                    data : action.data
                }
            };
            
        case OPEN_EDIT_BRAND_DIALOG:
            return {
                ...state,
                brandDialog: {
                    type : 'edit',
                    props: {
                        open: true
                    },
                    data : action.data
                }
            };
        case CLOSE_BRAND_DIALOG:
            return {
                ...state,
                brandDialog: {
                    type : 'new',
                    props: {
                        open: false
                    },
                    data : null
                }
            };
                
        default:
            return state;
    }
}

export default brandReducer;