import { action, makeObservable, observable } from "mobx";


const fakeData = [
    {
        serviceType: 1,
        title:'Pick up order',
        body:'198476670',
        orderId: 198476670,
        storeAcceptExpireTime: 2700,
        firstName: 'Frijdastraat22b',
        lastName: '2288EZRijswijk',
        status: 4,
        customerDetails:{
            name:'Jane Doe'
        },
        drop:{
            floatNumber: 3,
            landMark: 4,
            addressLine1: 1,
        },
        Items:[
            {
                itemName:"Patat",
                quantity: 2 ,
                unitPrice: 2.5,
                finalPrice: 5,
                addOns : [ 
                    {
                        addOnGroup : [ 
                            {
                                name : "Extra kaas",
                                price : 1,
                                id : "5ef20bef2e3b6d9863eedc26"
                            }, 
                            {
                                name : "Extra vlees",
                                price : 2,
                                id : "5ef20bef2e3b6d9863eedc23"
                            }
                        ],
                        id : "5ef20be82e3b6d9863eedc13",
                        packId : "604634fa3c5817a6b8be9a30"
                    }, 
                    {
                        addOnGroup : [ 
                            {
                                name : "Sambal",
                                price : 0.5,
                                id : "5ef20bef2e3b6d9863eedc20"
                            }, 
                            {
                                name : "Knoflooksaus",
                                price : 0.5,
                                id : "5ef20bef2e3b6d9863eedc25"
                            }, 
                            {
                                name : "Mayonaise",
                                price : 0.5,
                                id : "5ef20bef2e3b6d9863eedc21"
                            }
                        ],
                        id : "5ef20be82e3b6d9863eedc14",
                        packId : "604634fa3c5817a6b8be9a31"
                    }
                ],    
            },
            {
                itemName:"Salade genovese",
                quantity: 2 ,
                unitPrice: 4,
                finalPrice: 8,
            },
            {
                itemName:"Lahmancum met kaas",
                quantity: 1 ,
                unitPrice: 12,
                finalPrice: 12,
            },
        ],
        dueDateTime: '15.03.2021 15:00',
        paymentTypeMsg: 'Cash',
        discount: 5,
        storeDeliveryFee: 3,
        subTotalAmount:25,
        totalAmount: 29.5,
        currencySymbol: '€',
        start_time: '15:30',
        start_date: '15.03.21',
        end_time: '16:00',
        statusMsg:'OK',
        statusText:'Your order has been accepted by the restaurant and shall be ready soon.',
    },
]


class MainStore {

    notifications = [];
    //notifications = fakeData;
    visible = false;
    signupAccountId = 0;
    constructor() {
        makeObservable(this, {
            notifications: observable,
            push: action
        })
    }

    push(notification) {
        // this.notifications = [notification, ...this.notifications];
        this.notifications = [...this.notifications, notification];
    }

    remove(notification) {
        this.notifications = this.notifications.filter(({orderId}) => notification.orderId !== orderId);
    }

}

export default new MainStore();