// import brand from "../../views/brand/brand";

export const GET_TICKETS = 'GET_TICKETS';
export const GET_TICKETS_DONE = 'GET_TICKETS_DONE';
export const GOT_ERROR_TICKET = 'GOT_ERROR_TICKET';
/**
 * Get TICKET list
 */
export function getTickets( userId ) {
    return { type: GET_TICKETS, data: userId };
}

