import axios from 'axios';
import EnvDecode from '../common/EnvDecode';
import { 
    HTTP_ERRORS, 
    isValidToken, 
    AXIOS_AUTH,
    AXIOS_LANGUAGE,
    APP_LANG_KEY
} from '../components/Common/constants';

const BASE_URL = EnvDecode.getEnvPath(process.env.React_App_API_SERVER_URL);

var AddonService = {
    createAddon (addon) {
        return new Promise((resolve, reject) => {            
            if(!isValidToken(axios.defaults.headers.common[AXIOS_AUTH]))
            {
                reject(HTTP_ERRORS.InvalidToken);
            }
            else
            {  
                axios.defaults.headers.common[AXIOS_LANGUAGE] = localStorage.getItem(APP_LANG_KEY) || 'en';
                axios.post( `${BASE_URL}` + '/api/addon/create', addon)
                .then(response => {                    
                    if ( response.status === 200 )
                    {                       
                        resolve(response.data);                        
                    }
                    else
                    {   
                        reject(response.data);
                    }
                })
                .catch(err=>{
                    reject(err);
                })
            }
        });
    },
    updateAddon (addon) {

        let req = addon
        delete req.checked

        return new Promise((resolve, reject) => {       
            if(!isValidToken(axios.defaults.headers.common[AXIOS_AUTH]))
            {
                reject(HTTP_ERRORS.InvalidToken);
            }
            else
            {
                axios.defaults.headers.common[AXIOS_LANGUAGE] = localStorage.getItem(APP_LANG_KEY) || 'en';
                axios.patch(  `${BASE_URL}` + '/api/addon/update/new', req)
                .then(response => {         
                    if ( response.status === 200 )
                    {                       
                        resolve(response.data);                        
                    }
                    else
                    {   
                        reject(response.data);
                    }
                })
                .catch(err=>{
                    reject(err);
                })
            }
        });
    },
    deleteAddon ( data ) {
        return new Promise((resolve, reject) => {   
            if(!isValidToken(axios.defaults.headers.common[AXIOS_AUTH]))
            {
                reject(HTTP_ERRORS.InvalidToken);
            }
            else
            {
                axios.defaults.headers.common[AXIOS_LANGUAGE] = localStorage.getItem(APP_LANG_KEY) || 'en';
                axios.delete(  `${BASE_URL}` + '/api/addon/delete/' + data._id )
                .then(response => {                    
                    if ( response.status === 200 )
                    {                       
                        resolve(response.data);                        
                    }
                    else
                    {   
                        reject(response.data);
                    }
                })
                .catch(err=>{
                    reject(err);
                })
            }
        });
    },

    getAddons (queryData) {
    
        let pageNum = queryData.pageNum
        let query = queryData.query === "" ? 0 : queryData.query
        let url =  `${BASE_URL}` + '/api/addon/getByStatusWithQuery/' + queryData.storeId + "/" + queryData.status + "/" + + pageNum + "/" + query;
        return new Promise((resolve, reject) => {    
            if(!isValidToken(axios.defaults.headers.common[AXIOS_AUTH]))
            {
                reject(HTTP_ERRORS.InvalidToken);
            }
            else
            {
                axios.defaults.headers.common[AXIOS_LANGUAGE] = localStorage.getItem(APP_LANG_KEY) || 'en';
                axios.get(url)
                .then(response => {
                    if ( response.status === 200 )
                    {                           
                        resolve(response.data);                        
                    }
                    else
                    {   
                        reject(response.data);
                    }
                })
                .catch(err=>{
                    reject(err);
                })
            }        
        });
    },

    getAddonsGroup ( queryData ) {
        let url =  `${BASE_URL}` + '/api/addon/getAllByStatus/' + queryData.storeId + "/" + queryData.status;
        //console.log( 'url =', url )
        return new Promise((resolve, reject) => {    
            if(!isValidToken(axios.defaults.headers.common[AXIOS_AUTH]))
            {
                reject(HTTP_ERRORS.InvalidToken);
            }
            else
            {
                axios.defaults.headers.common[AXIOS_LANGUAGE] = localStorage.getItem(APP_LANG_KEY) || 'en';
                axios.get(url)
                .then(response => {
                    if ( response.status === 200 )
                    {                           
                        resolve(response.data);                        
                    }
                    else
                    {   
                        reject(response.data);
                    }
                })
                .catch(err=>{
                    reject(err);
                })
            }        
        });
    }
}

export default AddonService;