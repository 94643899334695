export const GET_MANAGERS = 'GET_MANAGERS';
export const CREATE_MANAGER = 'CREATE_MANAGER';
export const DELETE_MANAGER = 'DELETE_MANAGER';
export const UPDATE_MANAGER = 'UPDATE_MANAGER';
export const GOT_ERROR_MANAGER = 'GOT_ERROR_MANAGER';
export const EMAIL_EXIST_ALREADY = 'EMAIL_EXIST_ALREADY';

export const UPDATE_MANAGER_DONE = 'UPDATE_MANAGER_DONE';
export const DELETE_MANAGER_DONE = "DELETE_MANAGER_DONE";
export const CREATE_MANAGER_DONE = "CREATE_MANAGER_DONE";

export const GET_MANAGERS_DONE = 'GET_MANAGERS_DONE';

export const OPEN_NEW_MANAGER_DIALOG = 'OPEN_NEW_MANAGER_DIALOG';
export const OPEN_EDIT_MANAGER_DIALOG = 'OPEN_EDIT_MANAGER_DIALOG';
export const CLOSE_MANAGER_DIALOG = 'CLOSE_MANAGER_DIALOG';
/**
 * Get active schedule list
 */
export function getManagers(queryData) {    
    return { type: GET_MANAGERS, queryData:queryData };
}

/**
 * Create Manager
 */
export function createManager(scheduleData) {
    return { type: CREATE_MANAGER, data:scheduleData };
}

/**
 * Update Manager
 */
export function updateManager(scheduleData) {
    return { type: UPDATE_MANAGER, data:scheduleData };
}

/**
 * Delete Manager
 */
export function deleteManager(scheduleData) {
    return { type: DELETE_MANAGER, data:scheduleData };
}

export function openNewManagerDialog()
{
    return {
        type: OPEN_NEW_MANAGER_DIALOG
    }
}

export function openEditManagerDialog(data)
{
    return {
        type: OPEN_EDIT_MANAGER_DIALOG, data:data
    }
}

export function closeManagerDialog()
{
    return {
        type: CLOSE_MANAGER_DIALOG
    }
}

