import WalletService from '../../services/walletService';

import { GET_WALLETS, GET_WALLETS_DONE, GOT_ERROR_WALLETS } from '../actions/wallets.actions';

const wallet = store => next => action => {
    let dispatch = store.dispatch;
    if (action.type === GET_WALLETS) {       
      WalletService.getWallets(action.data).then(result => {
        dispatch({ type: GET_WALLETS_DONE, payload: result });
      })
      .catch(err=>{
        dispatch({ type: GOT_ERROR_WALLETS, payload: err })
      })
    }
  
    return next(action)
  }

export default wallet;