import React, {Component} from 'react';
import { Input, Modal, ModalHeader, ModalBody} from 'reactstrap';
import FormValidator from '../../components/Common/FormValidator.js';
import {bindActionCreators} from 'redux';
import * as actions from '../../store/actions/actions';
import {connect} from 'react-redux';
import _ from 'lodash';
import utilService from '../../common/utilService'
import { Table, Tbody, Tr, Td } from 'react-super-responsive-table'
//import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css'
///////////////////////

const newChangePwdState = {
    email:'',
    new_pwd:'',
    conf_pwd:'',
};

class ChangePasswordDialog extends Component {
    constructor(props) {
        super(props);
        
        let userData = JSON.parse(localStorage.getItem('userData'))
        this.state = {
            formChangePwd: {
                email: (userData !== null && userData !== undefined) ? userData.userEmail : '',
                name: (userData !== null && userData !== undefined) ? userData.name : '',
                new_pwd:'',
                conf_pwd:'',
            },
            isLoading: false
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot)
    {

        /**
         * After Dialog Open
         */
        // if ( !prevProps.changePwdDlg.props.open && this.props.changePwdDlg.props.open )
        // {
        //     /**
        //      * Dialog type: 'edit'
        //      * Update State
        //      */        
        // }
        
    }

    /**
      * Validate input using onChange event
      * @param  {String} formName The name of the form in the state object
      * @return {Function} a function used for the event
      */
    validateOnChange = event => {
        const input = event.target;
        const form = input.form
        const value = input.type === 'checkbox' ? input.checked : input.value;

        const result = FormValidator.validate(input);

        this.setState({
            [form.name]: {
                ...this.state[form.name],
                [input.name]: value,
                errors: {
                    ...this.state[form.name].errors,
                    [input.name]: result
                }
            }
        });
    }

    onSubmit = e => {
        
        const form = e.target;
        e.preventDefault()

        const inputs = [...form.elements].filter(i => ['INPUT', 'SELECT'].includes(i.nodeName))

        const { errors, hasError } = FormValidator.bulkValidate(inputs)
        
        console.log('errors', errors, hasError)

        
        this.setState({
            [form.name]: {
                ...this.state[form.name],
                errors
            }
        });

        let check_pwd = this.state.formChangePwd.new_pwd === this.state.formChangePwd.conf_pwd

        if (!hasError && check_pwd) 
        {
            console.log("Submit => ", this.state.formChangePwd.email, this.state.formChangePwd.new_pwd)
            this.props.actions.changePassword(this.state.formChangePwd.email, this.state.formChangePwd.new_pwd);
            this.props.onClose();
        }
        else
        {
            this.props.actions.openConfirmDialog({
                title:utilService.getLangByCode('Error'), 
                description: utilService.getLangByCode('Please enter password again'),
                action_type:'', 
                dialog_type:'error',
                cancel_btn:'Cancel'
            })
        }

        console.log(hasError ? 'Form has errors. Check!' : 'Form Submitted!')
    }

    /* Simplify error check */
    hasError = (formName, inputName, method) => {
        return  this.state[formName] &&
                this.state[formName].errors &&
                this.state[formName].errors[inputName] &&
                this.state[formName].errors[inputName][method]
    }

    render()
    {
        //const {changePwdDlg, closeChangePwdDlg} = this.props;
        
        return (
            <Modal                    
                    isOpen={this.props.isOpen}
                    toggle={() => this.props.onClose()}
                    className="font-arial"
                >
                <ModalHeader toggle={() => this.props.onClose()} className="bg-green text-center">
                    <div className="d-flex justify-content-center"> 
                        <h3 className="change-pwd-title" data-test="headerTitle">
                            {"Change Password"}
                        </h3>
                    </div>                        
                </ModalHeader>
                <ModalBody>
                    <form
                        className="mb-3" 
                        name="formChangePwd" 
                        onSubmit={this.onSubmit}
                    >
                        <Table className="w-100 fs-16" id="table-login">
                            <Tbody>
                                <Tr>
                                    <Td className="" style={{paddingLeft:'0px'}}>
                                        <span className="fs-14" data-test="userNameLabel">
                                            {utilService.getLangByCode('User name')}
                                        </span>
                                    </Td>
                                    <Td className="" style={{paddingLeft:'0px'}}>
                                        <Input
                                            name="email" 
                                            className="login-form-control"       
                                            // placeholder={utilService.getLangByCode('Enter your email')}
                                            // invalid={this.hasError('formChangePwd','email','required')||this.hasError('formChangePwd','email','email')}
                                            // onChange={this.validateOnChange}
                                            // data-validate='["required", "email"]'
                                            value={this.state.formChangePwd.name}
                                            disabled
                                        />
                                        { this.hasError('formChangePwd','email','required') && <span className="invalid-feedback">{utilService.getLangByCode('Field is required')}</span> }
                                        { this.hasError('formChangePwd','email','email') && <span className="invalid-feedback">{utilService.getLangByCode('Field must be valid email')}</span> }
                                    </Td>
                                </Tr>
                                <Tr>
                                    <Td className="" style={{paddingLeft:'0px'}}>
                                        <span className="fs-14" data-test="newPwdLabel">
                                            {utilService.getLangByCode('New password')}
                                        </span>
                                    </Td>
                                    <Td className="" style={{paddingLeft:'0px'}}>
                                        <Input type="password"
                                            id="id-password"
                                            name="new_pwd"
                                            //className="login-form-control"                                       
                                            placeholder={utilService.getLangByCode('Enter new password')}
                                            invalid={this.hasError('formChangePwd','new_pwd','required') || this.hasError('formChangePwd','new_pwd','password')}
                                            onChange={this.validateOnChange}
                                            data-validate='["required", "password"]'
                                            value={this.state.formChangePwd.old_pwd}
                                        />
                                        {this.hasError('formChangePwd','new_pwd','required') && <span className="invalid-feedback">{utilService.getLangByCode('Field is required')}</span>}
                                        {this.hasError('formChangePwd','new_pwd','password') && <span className="invalid-feedback">{utilService.getLangByCode('Password needs at least 8 characters')}</span>}
                                    </Td>
                                </Tr>
                                <Tr>
                                    <Td className="" style={{paddingLeft:'0px'}}>
                                        <span className="fs-14" data-test="confirmPwdLabel">
                                            {utilService.getLangByCode('Confirm password')}
                                        </span>
                                    </Td>
                                    <Td className="" style={{paddingLeft:'0px'}}>
                                        <Input type="password"
                                            id="id_conf_pwd"
                                            name="conf_pwd"
                                            //className="login-form-control"                                       
                                            placeholder={utilService.getLangByCode('Confirm password')}
                                            invalid={this.hasError('formChangePwd','conf_pwd','required')}
                                            onChange={this.validateOnChange}
                                            data-validate='["required"]'
                                            value={this.state.formChangePwd.old_pwd}
                                        />
                                        {/* <div className="input-group-append">
                                            <span className="input-group-text text-muted bg-transparent border-left-0">
                                                <em className="fa fa-lock"></em>
                                            </span>
                                        </div> */}
                                        <span className="invalid-feedback">{utilService.getLangByCode('Field is required')}</span>
                                    </Td>
                                </Tr>
                            </Tbody>
                        </Table>
                        <div className="w-100 text-center">
                            <button className="btn bg-red text-white  fs-16" 
                                data-test="btnCancel"
                                type="button"
                                style={{borderWidth:'2px', width:'120px', marginRight:'10px', marginLeft:'10px'}} 
                                onClick={() => this.props.onClose()}
                            >
                                {utilService.getLangByCode('Cancel')}
                            </button>
                            <button className="btn fs-16" 
                                data-test="btnSubmit"
                                type="submit"
                                style={{width:'120px', borderColor:'#A7BA13', color:'#A7BA13', marginRight:'10px', marginLeft:'10px', borderWidth:'2px'}} 
                            >
                                {utilService.getLangByCode('Submit')}
                            </button>
                        </div> 
                    </form>
                </ModalBody>
            </Modal>
        
            
        );
    }
}


const mapStateToProps = ({auth}) => ({ userData: auth.userData})
const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) });
export default connect(mapStateToProps, mapDispatchToProps)(ChangePasswordDialog);
