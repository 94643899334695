import React from "react";
import { useReactToPrint } from "react-to-print";
import OrderRenderContent from "./orderRenderContent";

export const RenderTemplate = ({
  order,
  orderForAndroid,
  getAddonName,
  onClose,
}) => {
  const componentRef = React.useRef(null);

  const reactToPrintContent = () => {
    return componentRef.current;
  };

  const handlePrint = useReactToPrint({
    documentTitle: "Order Detail",
    copyShadowRoots: true,
  });
  return (
    <div id="printSection" ref={componentRef}>
      <OrderRenderContent
        onClose={onClose}
        order={order}
        orderForAndroid={orderForAndroid}
        handlePrint={() => handlePrint(reactToPrintContent)}
        getAddonName={getAddonName}
      />
    </div>
  );
};
