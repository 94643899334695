import utilService from '../../common/utilService'
import { HTTP_ERRORS } from '../../components/Common/constants';
import { FORGOT_SUCCESS, PASSWORD_CHANGED, LOGIN_SUCCESS, LOGIN_FAIL, LOGOUT, 
    IMAGE_UPLOAD_DONE, API_ERROR, GET_PROFILE_DONE,
    UPDATE_PROFILE_DONE, GET_ZONES_DONE, INVALID_TOKEN_ERROR,
    LOGOUT_BY_INVALID_TOKEN, GET_All_CITIES_DONE
} from '../actions/actions';

const initialState = {
    login_status: false,
    forgot_sent: false,
    pass_changed: false,
    err:null,
    token: null,
    storeAdminMqttChannel:'',
    userData:{},
    profile:{
        storeProfileData: {
            name:["", ""],
            ownerPhone:'',
            businessNumber:'',
            description:["", ""],
            serviceZones:[],
            coordinates:{},
            socialLinks:{}
        },
        storeCategories: [],
        storeSubCategories: [],
        countryList: [],
        language: []        
    },
    zones: [],
    cities: [],
    activeLang :{
        langCode: utilService.getLanguage() || 'en'
    },

    uploadedImage: null,
    
}

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOGIN_SUCCESS:
            localStorage.setItem("userData", JSON.stringify(action.payload.data) )
            //console.log("*** LOG_SUCCESS ***",  action.payload.data)
            localStorage.setItem("mqttClient", "None");
            localStorage.removeItem("rct");
            return {
                ...state,
                login_status: true,
                err: null,
                token: action.payload.data.authToken,
                userData:action.payload.data,
                //storeAdminMqttChannel:action.payload.storeAdminMqttChannel
            }
        case FORGOT_SUCCESS:
                return {
                    ...state,
                    forgot_sent: true,
                    err: null
                }
        case PASSWORD_CHANGED:
                return {
                    ...state,
                    pass_changed: true,
                    err: null
                }            
        case LOGIN_FAIL:            
            localStorage.removeItem("userData")
            localStorage.setItem("mqttClient", null);
            return {
                ...state,
                err: action.payload
            }
        case LOGOUT:
            localStorage.removeItem("userData")
            localStorage.setItem("mqttClient", null);
            localStorage.removeItem("rct");
            return {
                ...state,
                login_status: false,
                token: null,
                storeAdminMqttChannel:''
            }
        case LOGOUT_BY_INVALID_TOKEN:
            return {
                ...state,
                err: null,
                login_status: false
            }
        case API_ERROR:            
            return {
                ...state,
                err: action.payload
            }
        case INVALID_TOKEN_ERROR:
            return {
                ...state,
                err: HTTP_ERRORS.InvalidToken
            }
        case GET_PROFILE_DONE:
            
            let activeLang = action.payload.data && action.payload.data.language.find(lang => {
                return lang.Active === 1;
            })

            if(activeLang) {
                activeLang.langCode = utilService.getLanguage() || activeLang.langCode;
            }
            
            return {
                ...state,                
                profile:action.payload.data,
                activeLang:activeLang?activeLang:state.activeLang
            }
        case GET_ZONES_DONE:
            return {
                ...state,                
                zones:action.payload.data,                
            }
        case GET_All_CITIES_DONE:
            return {
                ...state,
                cities: action.payload.data,
            }
        case UPDATE_PROFILE_DONE:
            return {
                ...state,                
                profile: {
                    ...state.profile,
                    storeProfileData: action.payload.data.data,
                }
            }
        
        case IMAGE_UPLOAD_DONE: 
            return {
                ...state,                
                uploadedImage:action.payload.data,                
            }
        default:
            return state;
    }
}

export default authReducer;