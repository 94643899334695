import { GET_INVOICES_SUCCESS, GET_INVOICE_BUFFER_SUCCESS } from "../actions/invoice.actions";

const initialState = {
  invoices: [],
  invoices_cnt: 0,
  invoice_err: null,
}

const invoiceReducer = (state = initialState, action) => {    
  switch (action.type) {
      case GET_INVOICES_SUCCESS:
        return {
            ...state,
            invoices: action.payload.data
        }
      // case GET_INVOICE_BUFFER_SUCCESS:            
      //   return {
      //       ...state,
      //   }   
      default:
          return state;
  }
}

export default invoiceReducer;