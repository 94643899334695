import {OPEN_CONFIRM_DIALOG, CLOSE_CONFIRM_DIALOG} from '../actions/common.actions';

const initialState = {
    err_common:null,
    confirmDialog     : {
        type : 'new',
        props: {
            open: false
        },
        data : null
    }
}

const commonReducer = (state = initialState, action) => {    
    switch (action.type) {
        case OPEN_CONFIRM_DIALOG:
            return {
                ...state,
                confirmDialog: {
                    type : 'new',
                    props: {
                        open: true
                    },
                    data : action.payload
                }
            };
        case CLOSE_CONFIRM_DIALOG:
            return {
                ...state,
                confirmDialog: {
                    type : 'new',
                    props: {
                        open: false
                    },
                    data : null
                }
            };
                
        default:
            return state;
    }
}

export default commonReducer;