import axios from 'axios';
import EnvDecode from '../common/EnvDecode';
import { 
    HTTP_ERRORS, 
    isValidToken, 
    AXIOS_AUTH,
    AXIOS_LANGUAGE,
    APP_LANG_KEY
} from '../components/Common/constants';


const BASE_URL = EnvDecode.getEnvPath(process.env.React_App_API_SERVER_URL);

var FAQService = {
    getFAQs () {
        return new Promise((resolve, reject) => {
            if(!isValidToken(axios.defaults.headers.common[AXIOS_AUTH]))
            {
                reject(HTTP_ERRORS.InvalidToken);
            }
            else
            {
                axios.defaults.headers.common[AXIOS_LANGUAGE] = localStorage.getItem(APP_LANG_KEY) || 'en';
                axios.get( `${BASE_URL}` + '/faq/getAll')
                .then(response => {
                    if ( response.status === 200 )
                    {   
                        resolve(response.data.data);                        
                    }
                    else
                    {   
                        reject(response.data.data);
                    }
                })
                .catch(err=>{
                    reject(err);
                })
            }       
        });
    },
    
}

export default FAQService;