import axios from 'axios';
import EnvDecode from '../common/EnvDecode';
import { 
    HTTP_ERRORS, 
    isValidToken, 
    AXIOS_AUTH, 
    AXIOS_LANGUAGE,
    APP_LANG_KEY
} from '../components/Common/constants';

const BASE_URL = EnvDecode.getEnvPath(process.env.React_App_API_SERVER_URL);

var ManagerService = {
    createManager (manager) {

        delete manager.id
        delete manager.status

        return new Promise((resolve, reject) => {     
            if(!isValidToken(axios.defaults.headers.common[AXIOS_AUTH]))
            {
                reject(HTTP_ERRORS.InvalidToken);
            }
            else
            {   
                axios.defaults.headers.common[AXIOS_LANGUAGE] = localStorage.getItem(APP_LANG_KEY) || 'en';
                axios.post( `${BASE_URL}` + '/franchise/storemanager', manager)
                .then(response => {   
                    if ( response.status === 200 )
                    {                       
                        resolve(response.data);                        
                    }
                    else
                    {   
                        reject(response.data);
                    }
                })
                .catch(err=>{
                    console.log("ManService_EmailIsExist =>", err.response)  
                    if( err.response.status === 300)
                    {
                        reject(err.response.status)
                    }
                    else
                    {
                        reject(err);
                    }
                })
            }       
        });
    },
    updateManager (manager) {
        let changedPassword = ( manager.passwordChanged === undefined || manager.passwordChanged === false )
        ? 0 : 1;
        //console.log("UpdateManager_ChangePassword=>", manager.passwordChanged, changedPassword)
        let req = {
            "_id":  manager._id, 
            "name": manager.name,
            "email": manager.email,        
            "storeId": manager.storeId,    
            "storeName": manager.storeName,    
            "accepts": manager.accepts ? manager.accepts : "",    
            "phone": manager.phone,    
            "cityId": manager.cityId,    
            "cityName": manager.cityName,    
            "countryCode": manager.countryCode,
            "receivedOrderEmail": manager.receivedOrderEmail,
            "dispatcherUserType": manager.dispatcherUserType || 1,
            "dispatcherUserTypeMsg": manager.dispatcherUserTypeMsg ? manager.dispatcherUserTypeMsg : "",
            "password": manager.password,
            "seqId": manager.seqId || 0,
            "userType": manager.userType || 2,
            "addedBy": manager.addedBy,
            "status": manager.status,
            "passwordChanged": changedPassword 
        }

        //delete manager.addedBy
        return new Promise((resolve, reject) => {  
            if(!isValidToken(axios.defaults.headers.common[AXIOS_AUTH]))
            {
                reject(HTTP_ERRORS.InvalidToken);
            }
            else
            {   
                axios.defaults.headers.common[AXIOS_LANGUAGE] = localStorage.getItem(APP_LANG_KEY) || 'en';
                axios.patch( `${BASE_URL}` + '/franchise/storemanager', req)
                .then(response => {                    
                    if ( response.status === 200 )
                    {                       
                        resolve(response.data);                        
                    }
                    else
                    {   
                        reject(response.data);
                    }
                })
                .catch(err=>{
                    reject(err);
                })
            } 
        });
    },
    deleteManager (id) {
        return new Promise((resolve, reject) => {      
            if(!isValidToken(axios.defaults.headers.common[AXIOS_AUTH]))
            {
                reject(HTTP_ERRORS.InvalidToken);
            }
            else
            {   
                axios.defaults.headers.common[AXIOS_LANGUAGE] = localStorage.getItem(APP_LANG_KEY) || 'en';
                axios.delete( `${BASE_URL}` + '/franchise/storemanager/' + id)
                .then(response => {                    
                    if ( response.status === 200 )
                    {                       
                        resolve(response.data);                        
                    }
                    else
                    {   
                        reject(response.data);
                    }
                })
                .catch(err=>{
                    reject(err);
                })
            }      
        });
    },

    getManagers (queryData) {
        
        let status = queryData.status
        let pageNum = queryData.pageNum
        let query = queryData.query === "" ? 0 : queryData.query
        let url = `${BASE_URL}` + '/franchise/storemanagerWithQuery/' 
        + queryData.storeId + "/" + status + "/" + pageNum + "/" + query;

        return new Promise((resolve, reject) => {      
            if(!isValidToken(axios.defaults.headers.common[AXIOS_AUTH]))
            {
                reject(HTTP_ERRORS.InvalidToken);
            }
            else
            {   
                axios.defaults.headers.common[AXIOS_LANGUAGE] = localStorage.getItem(APP_LANG_KEY) || 'en';
                axios.get(url)
                .then(response => {                    
                    if ( response.status === 200 )
                    {   
                        resolve(response.data);                        
                    }
                    else
                    {   
                        reject(response.data);
                    }
                })
                .catch(err=>{
                    reject(err);
                })
            }      
        });
    },
}

export default ManagerService;