import { HTTP_ERRORS } from '../../components/Common/constants';
import ScheduleService from '../../services/scheduleService';

import { GET_SCHEDULES, CREATE_SCHEDULE, UPDATE_SCHEDULE, SHOW_ALERT,
  DELETE_SCHEDULE, GET_SCHEDULES_DONE, UPDATE_SCHEDULE_DONE, 
  DELETE_SCHEDULE_DONE, CREATE_SCHEDULE_DONE, GOT_ERROR_SCHEDULE, 
  ADD_SLOT, DELETE_SLOT, ADD_SLOT_DONE, DELETE_SLOT_DONE, OPEN_CONFIRM_DIALOG, 
  INVALID_TOKEN_ERROR
} from '../actions/actions';

const schedule = store => next => action => {
    let dispatch = store.dispatch;
    if (action.type === GET_SCHEDULES) {       
      // Make an API call to login the server            
      ScheduleService.getSchedules(action.queryData).then(result => {        
        dispatch({ type: GET_SCHEDULES_DONE, payload: result, queryData:action.queryData });
      })
      .catch(err=>{
        if(err === HTTP_ERRORS.InvalidToken)
        {
          dispatch({ type: INVALID_TOKEN_ERROR, payload: err });
        }
        else
        {
          dispatch({ type: GOT_ERROR_SCHEDULE, payload: err })
        }
      })
    } else if (action.type === CREATE_SCHEDULE) {      
      ScheduleService.createSchedule(action.data).then(result => {
        dispatch({ type: CREATE_SCHEDULE_DONE, payload:result });
      })
      .catch(err=>{
        if(err === HTTP_ERRORS.InvalidToken)
        {
          dispatch({ type: INVALID_TOKEN_ERROR, payload: err });
        }
        else
        {
          dispatch({ type: GOT_ERROR_SCHEDULE, payload: err })
        }
      })
    } else if (action.type === UPDATE_SCHEDULE) {
      ScheduleService.updateSchedule(action.data).then(result => {        
        dispatch({ type: UPDATE_SCHEDULE_DONE, payload:action.data });        
      })
      .catch(err=>{
        if(err === HTTP_ERRORS.InvalidToken)
        {
          dispatch({ type: INVALID_TOKEN_ERROR, payload: err });
        }
        else
        {
          dispatch({ type: GOT_ERROR_SCHEDULE, payload: err })
        }
      })      
    } else if (action.type === DELETE_SCHEDULE) {      
      ScheduleService.deleteSchedule(action.data).then(result => {       
        dispatch({ type: DELETE_SCHEDULE_DONE, payload:action.data });
      })
      .catch(err=>{
        if(err === HTTP_ERRORS.InvalidToken)
        {
          dispatch({ type: INVALID_TOKEN_ERROR, payload: err });
        }
        else
        {
          dispatch({ type: GOT_ERROR_SCHEDULE, payload: err })
        }
      }) 
    } else if (action.type === ADD_SLOT) {      
      ScheduleService.addSlot(action.data).then(result => {       
        dispatch({ type: ADD_SLOT_DONE, payload:action.data });
      })
      .catch(err=>{
        if(err === HTTP_ERRORS.InvalidToken)
        {
          dispatch({ type: INVALID_TOKEN_ERROR, payload: err });
        }
        else
        {
          dispatch({ type: GOT_ERROR_SCHEDULE, payload: err })
        }
      }) 
    } else if (action.type === DELETE_SLOT) {      
      ScheduleService.deleteSlot(action.data).then(result => {       
        dispatch({ type: DELETE_SLOT_DONE, payload: action.data });
      })
      .catch(err=>{
        if(err === HTTP_ERRORS.InvalidToken)
        {
          dispatch({ type: INVALID_TOKEN_ERROR, payload: err });
        }
        else
        {
          dispatch({ type: GOT_ERROR_SCHEDULE, payload: err })
        }
      }) 
    } else if (action.type === SHOW_ALERT) {      
      dispatch({ type: OPEN_CONFIRM_DIALOG, 
        payload: {
          title: action.data.title, 
          description: action.data.desc,
          action_type:'', 
          dialog_type: action.data.type
        }
      });
    } 

  
    return next(action)
  }

export default schedule;