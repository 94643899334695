import axios from 'axios';
import { ORDER_STATUS } from '../components/Common/constants';
import EnvDecode from '../common/EnvDecode';
import { 
    HTTP_ERRORS, 
    isValidToken, 
    AXIOS_AUTH,
    AXIOS_LANGUAGE,
    APP_LANG_KEY
} from '../components/Common/constants';


const BASE_URL = EnvDecode.getEnvPath(process.env.React_App_API_SERVER_URL);

var OrdersService = {
    dispatchOrder ( order ) {
        // let order = {
        //     "orderId": orderId,
        //     "timestamp": timeStamp
        // }
        return new Promise((resolve, reject) => {     
            if(!isValidToken(axios.defaults.headers.common[AXIOS_AUTH]))
            {
                reject(HTTP_ERRORS.InvalidToken);
            }
            else
            {
                axios.defaults.headers.common[AXIOS_LANGUAGE] = localStorage.getItem(APP_LANG_KEY) || 'en';
                axios.patch( `${BASE_URL}` +  '/dispatchOrder', order)
                .then(response => {                    
                    if ( response.status === 200 )
                    {                       
                        resolve(response.data);                        
                    }
                    else
                    {   
                        reject(response.data);
                    }
                })
                .catch(err=>{
                    reject(err);
                })
            }       
        });
    },
    updateOrderStatus ( statusData ) {
        
        //console.log("** UpdateOrderStatus **", statusData)
        // For notification
        let status = statusData.status
        let notifyIndex;
        if(status == ORDER_STATUS.accepted || status == ORDER_STATUS.rejected || status == ORDER_STATUS.cancelled)
        {
            notifyIndex = statusData.notifyIndex
            delete statusData.notifyIndex
        }

        return new Promise((resolve, reject) => {    
            if(!isValidToken(axios.defaults.headers.common[AXIOS_AUTH]))
            {
                reject(HTTP_ERRORS.InvalidToken);
            }
            else
            {
                axios.defaults.headers.common[AXIOS_LANGUAGE] = localStorage.getItem(APP_LANG_KEY) || 'en';
                axios.patch( `${BASE_URL}` + '/franchise/order/status', statusData)
                .then(response => {                    
                    if ( response.status === 200 )
                    {                       
                        resolve(response.data);    
                    }
                    else
                    {   
                        reject(response.data);
                    }
                })
                .catch(err=>{
                    reject(err);
                })
            }        
        });
    },
    completeOrder (id, userId) {

        let orderId = {
            "orderId": id,
            userId
        }

        return new Promise((resolve, reject) => {   
            if(!isValidToken(axios.defaults.headers.common[AXIOS_AUTH]))
            {
                reject(HTTP_ERRORS.InvalidToken);
            }
            else
            {
                axios.defaults.headers.common[AXIOS_LANGUAGE] = localStorage.getItem(APP_LANG_KEY) || 'en';
                axios.post( `${BASE_URL}` + '/franchise/order/complete', orderId )
                .then(response => {                    
                    if ( response.status === 200 )
                    {                       
                        resolve(response.data);                
                    }
                    else
                    {   
                        reject(response.data);
                    }
                })
                .catch(err=>{
                    reject(err);
                })
            }         
        });
    },

    getOrders ( queryData ) {
        let url = `${BASE_URL}` + "/franchise/ordersWithBookingType" 
        //console.log("GET Order =>", queryData)
        return new Promise((resolve, reject) => {  
            if(!isValidToken(axios.defaults.headers.common[AXIOS_AUTH]))
            {
                reject(HTTP_ERRORS.InvalidToken);
            }
            else
            {
                axios.defaults.headers.common[AXIOS_LANGUAGE] = localStorage.getItem(APP_LANG_KEY) || 'en';
                axios.post(url, queryData)
                .then(response => {                    
                    if ( response.status === 200 )
                    {   
                        resolve(response.data);                        
                    }
                    else
                    {   
                        reject(response.data);
                    }
                })
                .catch(err=>{
                    reject(err);
                })
            }         
        });
    },

    // old get all orders at once
    getAllOrders (  queryData ) {
        let fromDate = queryData.fromDate === "" ? '0' : queryData.fromDate
        let toDate = queryData.toDate === "" ? '0' : queryData.toDate
        let search = queryData.search === "" ? '0' : queryData.search
        let bookingType = queryData.bookingType
        let serviceType = queryData.serviceType
        let url = `${BASE_URL}` + "/ordersWithBookingType/" 
            + queryData.cityId + "/" + queryData.index + "/" 
            + queryData.franchiseId + "/" + queryData.storeId + "/" + bookingType + "/" + serviceType
            + "/" + fromDate + "/" + toDate 
            + "/" + search;

        return new Promise((resolve, reject) => {  
            if(!isValidToken(axios.defaults.headers.common[AXIOS_AUTH]))
            {
                reject(HTTP_ERRORS.InvalidToken);
            }
            else
            {
                axios.defaults.headers.common[AXIOS_LANGUAGE] = localStorage.getItem(APP_LANG_KEY) || 'en';
                axios.get(url)
                .then(response => {                    
                    if ( response.status === 200 )
                    {   
                        resolve(response.data);                        
                    }
                    else
                    {   
                        reject(response.data);
                    }
                })
                .catch(err=>{
                    reject(err);
                })
            }         
        });
    }
}

export default OrdersService;