
export const GET_WALLETS = 'GET_WALLETS';
export const GET_WALLETS_DONE = "GET_WALLETS_DONE";
export const GOT_ERROR_WALLETS = "GOT_ERROR_WALLETS";
/**
 * Get Wallet list
 */
export function getWallets( data ) {
    return { type: GET_WALLETS, data: data };
}

