export const LOGIN = 'LOGIN';
export const FORGOT = 'FORGOT';
export const FORGOT_SUCCESS = 'FORGOT_SUCCESS';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const PASSWORD_CHANGED = 'PASSWORD_CHANGED';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';

export const LOGIN_STOREID = 'LOGIN_STOREID';
export const LOGIN_STOREID_SUCCESS = 'LOGIN_STOREID_SUCCESS';

export const CREATE_BLOCKLED_USER = "CREATE_BLOCKLED_USER";
export const CREATE_BLOCKLED_USER_SUCCESS = "CREATE_BLOCKLED_USER_SUCCESS";
export const CREATE_BLOCKLED_USER_ERROR = "CREATE_BLOCKLED_USER_ERROR";


export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';
export const LOGOUT_BY_INVALID_TOKEN = "LOGOUT_BY_INVALID_TOKEN";
export const SET_SESSION = 'SET_SESSION';
export const GET_PROFILE = 'GET_PROFILE';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const GET_ZONES = "GET_ZONES"
export const GET_All_CITIES = "GET_All_CITIES";
export const API_ERROR = 'API_ERROR';
export const INVALID_TOKEN_ERROR = "INVALID_TOKEN_ERROR";
export const EMAIL_EXIST_ALREADY = "EMAIL_EXIST_ALREADY";
export const IMAGE_UPLOAD = 'IMAGE_UPLOAD'

export const GET_PROFILE_DONE = 'GET_PROFILE_DONE';
export const UPDATE_PROFILE_DONE = 'UPDATE_PROFILE_DONE';
export const GET_ZONES_DONE = "GET_ZONES_DONE"
export const GET_All_CITIES_DONE = "GET_All_CITIES_DONE";
export const IMAGE_UPLOAD_DONE = 'IMAGE_UPLOAD_DONE'

export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
/**
 * Change current theme path
 */
export function login(email, password) {
    return { type: LOGIN, data:{email, password} };
}

export function forgot(email) {
    return { type: FORGOT, data:{email} };
}

export function resetPassword(token, password, passwordConfirm) {
    return { type: RESET_PASSWORD, data:{token, password, passwordConfirm} };
}

export function loginWithStoreId( storeId, superToken, fcmUserTopic ) {
    return { type: LOGIN_STOREID, data:{storeId, superToken, fcmUserTopic} };
}

export function logout() {
    return { type: LOGOUT };
}

export function logoutByInvalidToken() {
    return { type: LOGOUT_BY_INVALID_TOKEN };
}


export function createBlockedUser( data ) {
    return { type: CREATE_BLOCKLED_USER, data: data };
}

export function setSession(sessionData) {
    return { type: SET_SESSION, data:sessionData };
}

export function getProfile() {
    return { type: GET_PROFILE };
}

export function getZones( data) {
    return { type: GET_ZONES, data: data };
}

export function getAllCities() {
    return { type: GET_All_CITIES };
}

export function updateProfile(profileData) {
    return { type: UPDATE_PROFILE, data:profileData};
}

export function imageUpload( imgData ) {
    return { type: IMAGE_UPLOAD, data: imgData };
}

export function changePassword(email, pwd)
{
    return {
        type: CHANGE_PASSWORD, 
        data: {email:email, password:pwd}
    }
}
