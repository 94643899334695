export const GET_INVOICES = 'GET_INVOICES';
export const GET_INVOICES_SUCCESS = 'GET_INVOICES_SUCCESS';
export const GET_INVOICES_FAILED = 'GET_INVOICES_FAILED';

export const GET_INVOICE_BUFFER = 'GET_INVOICE_BUFFER';
export const GET_INVOICE_BUFFER_SUCCESS = 'GET_INVOICE_BUFFER_SUCCESS';
export const GET_INVOICE_BUFFER_FAILED = 'GET_INVOICE_BUFFER_FAILED';
/**
 * Get FAQ list
 */
export function getInvoices(query) {
    return { 
      type: GET_INVOICES,
      data: query
    };
}

export function getInvoiceBuffer(fileName) {
  return {
    type: GET_INVOICE_BUFFER,
    data: fileName
  }
}
