import React from "react";
import utilService from "../../common/utilService";
import {
  ORDER_SERVICE_TYPE,
  ORDER_STATUS,
} from "../../components/Common/constants";
import utilService2 from "../../services/utilService";
import { RenderModal } from "./renderModal";
import { RenderTemplate } from "./renderTemplate";

///////////////////////
const OrderDetail = (props) => {
  const {
    order,
    isOpenDetail,
    onCloseDetail,
    style,
    isTemplateMode = false,
  } = props;

  const onClose = () => {
    onCloseDetail();
  };

  const getFee = (order_item) => {
    if (order_item.deliveryCharge) {
      return (
        (order_item.currencySymbol ?? " ") +
        order_item.deliveryCharge.toFixed(2)
      );
    }
    return order_item.currencySymbol + "0";
  };

  const getFeeAmount = (order) => {
    // if(order.transcationFee){
    //     return order.transcationFee.toFixed(2)
    // }
    if (order.deliveryCharge) {
      return order.deliveryCharge.toFixed(2);
    }

    return 0;
  };
  const getTotalAmount = (order) => {
    return (
      order.currencySymbol +
      (
        parseFloat(order.subTotalAmount) + parseFloat(getFeeAmount(order))
      ).toFixed(2)
    );
  };

  const getAddonName = (addedOnData) => {
    if (typeof addedOnData.name === "object") {
      return localStorage.getItem("jq-appLang") === "en"
        ? addedOnData.name?.en ?? ""
        : addedOnData.name?.nl ?? "";
    }

    return addedOnData.name;
  };

  let orderForAndroid = {};
  if (order) {
    orderForAndroid.orderHeader =
      order.status === ORDER_STATUS.created &&
      order.serviceType === ORDER_SERVICE_TYPE.delivery
        ? utilService.getLangByCode("Delivery new order details")
        : order.status === ORDER_STATUS.created &&
          order.serviceType === ORDER_SERVICE_TYPE.pickup
        ? utilService.getLangByCode("Pickup new order details")
        : order.status === ORDER_STATUS.accepted
        ? utilService.getLangByCode("Accepted order details")
        : order.status === ORDER_STATUS.orderReady ||
          order.status === ORDER_STATUS.orderPicked
        ? utilService.getLangByCode("Pickup ready order details")
        : order.status === ORDER_STATUS.inDispatch
        ? utilService.getLangByCode("Delivery ready order details")
        : order.status === ORDER_STATUS.orderCompleted ||
          order.status === ORDER_STATUS.orderDeliveryCompleted
        ? utilService.getLangByCode("Completed order details")
        : order.status === ORDER_STATUS.orderExpired
        ? utilService.getLangByCode("Expired order details")
        : order.status === ORDER_STATUS.cancelled
        ? utilService.getLangByCode("Cancelled order details")
        : order.status === ORDER_STATUS.rejected
        ? utilService.getLangByCode("Rejected order details")
        : "";
    orderForAndroid.placedAt = utilService.getLangByCode("Order placed at");
    orderForAndroid.created =
      utilService2.formatDate(order.timeStamp.created.isoDate) +
      "-" +
      utilService2.formatDate(order.timeStamp.created.isoDate, "HH:mm");
    orderForAndroid.due =
      utilService2.formatDate(order.dueDatetime) +
      "-" +
      utilService2.formatDate(order.dueDatetime, "HH:mm"); // moment(order.bookingDate).format('MM.DD.YY') + "-" + moment(order.bookingDate).format('HH:mm')
    orderForAndroid.dropAddress = order.drop.addressLine1;
    orderForAndroid.customerMobile =
      order.customerDetails.countryCode + order.customerDetails.mobile;
    orderForAndroid.customerName = order.customerDetails.name;
    orderForAndroid.items = order.Items;
    orderForAndroid.currencySymbol = order.currencySymbol;
    orderForAndroid.deliveryFee = getFee(order);
    orderForAndroid.totalAmount = getTotalAmount(order);

    orderForAndroid.donation = (order.accouting.donate ?? 0).toFixed(2);
    orderForAndroid.txFee = (order.transcationFee ?? 0).toFixed(2);
  }
  return isTemplateMode ? (
    <RenderTemplate
      order={order}
      orderForAndroid={orderForAndroid}
      getAddonName={getAddonName}
      onClose={onClose}
    />
  ) : (
    <RenderModal
      isOpenDetail={isOpenDetail}
      onClose={onClose}
      style={style}
      order={order}
      orderForAndroid={orderForAndroid}
      getAddonName={getAddonName}
    />
  );
};

export default OrderDetail;
