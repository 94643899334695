import { GET_CATEGORIES_DONE, GOT_ERROR_CATEGORY, UPDATE_STORE_CATEGORY_DONE, UPDATE_STORE_PRODUCT_DONE,GET_ALL_CATEGORIES_DONE,
    CREATE_CATEGORY_DONE, CREATE_SUB_CATEGORY_DONE, CREATE_SUB_SUB_CATEGORY_DONE, UPDATE_CATEGORY_DONE, DELETE_CATEGORY_DONE, GET_SUB_CATEGORIES_DONE, GET_SUB_SUB_CATEGORIES_DONE,
    OPEN_NEW_CATEGORY_DIALOG, OPEN_EDIT_CATEGORY_DIALOG, CLOSE_CATEGORY_DIALOG, UPDATE_CATEGORY_SORTING_DONE} from '../actions/category.actions';

import CategoryService from '../../services/categoryService';
import { CATEGORY_STATUS } from '../../components/Common/constants';

const initialState = {
    pending_categories:[],
    approved_categories:[],    
    rejected_categories:[],  
    deleted_categories:[],
    pending_categories_count: 0,
    approved_categories_count: 0,    
    rejected_categories_count: 0, 
    deleted_categories_count: 0,
    
    categories: [],
    subCategories: [],
    subSubCategories: [],

    err_category:null,
    categoryDialog     : {
        type : 'new',
        props: {
            open: false
        },
        data : null
    }
}

const categoryReducer = (state = initialState, action) => {   
    switch (action.type) {
        case GET_CATEGORIES_DONE:
            if (action.queryData.status === 0) {
                return {
                    ...state,
                    pending_categories: (action.payload.data.data) || [],
                    pending_categories_count: action.payload.data.count
                }
            } else if (action.queryData.status === 1) {
                return {
                    ...state,
                    approved_categories: (action.payload.data.data) || [],
                    approved_categories_count: action.payload.data.count
                }
            } else if (action.queryData.status === 2) {
                return {
                    ...state,
                    deleted_categories: (action.payload.data.data) || [],
                    deleted_categories_count: action.payload.data.count
                }
            } else {
                return {
                    ...state,
                    rejected_categories: (action.payload.data.data) || [],
                    rejected_categories_count: action.payload.data.count
                }
            }        
        case GET_ALL_CATEGORIES_DONE:
            return {
                ...state,
                categories: (action.payload.data) || []
            }
        case GET_SUB_CATEGORIES_DONE:
            return {
                ...state,
                subCategories: (action.payload.data) || []
            }
        case GET_SUB_SUB_CATEGORIES_DONE:
            return {
                ...state,
                subSubCategories: (action.payload.data) || []
            }
        case GOT_ERROR_CATEGORY:            
            return {
                ...state,
                err_category: action.payload
            }
        case CREATE_CATEGORY_DONE:
            let category = action.payload.data.ops[0];      
            let firstCategory = {
                'firstCategory': {
                    id: category._id,
                    imageUrl: category.imageUrl,
                    categoryName: category.categoryName,
                    categoryDesc: category.categoryDesc,
                    status:       category.status
                },
                'storeId': category.storeid
            }
            if (category.status === CATEGORY_STATUS.CREATED) {      
                CategoryService.updateStoreCategory( firstCategory );          
                return {
                    ...state,
                    pending_categories: [category, ...state.pending_categories]
                }
            } else if (category.status === CATEGORY_STATUS.APPROVED) { 
                CategoryService.updateStoreCategory( firstCategory );               
                return {
                    ...state,
                    approved_categories: [category, ...state.approved_categories]
                }
            } else if (category.status === CATEGORY_STATUS.DELETED) {                 
                CategoryService.updateStoreCategory( firstCategory );          
                return {
                    ...state,
                    deleted_categories: [category, ...state.deleted_categories]
                }
            }        
            else {                
                CategoryService.updateStoreCategory( firstCategory );          
                return {
                    ...state,
                    rejected_categories: [category, ...state.rejected_categories]
                }
            }          
        case CREATE_SUB_CATEGORY_DONE:
            let subCategory = action.payload.data.ops[0];   
            //console.log( 'sub cate reducer =', subCategory )   
            return {
                ...state,
                subCategories: [subCategory, ...state.subCategories]
            }
        case CREATE_SUB_SUB_CATEGORY_DONE:
            let subSubCategory = action.payload.data.ops[0];      
            //console.log( 'sub sub cate reducer =', subSubCategory )   
            return {
                ...state,
                subSubCategories: [subSubCategory, ...state.subSubCategories]
            }   
        case UPDATE_CATEGORY_DONE:   {
            let updateCategory = action.payload.data;
            let updateCategoryId = action.payload._id;

            //console.log("** Cagegory Updated **", updateCategory, updateCategoryId)
            
            updateCategory._id =  updateCategoryId

            let firstCategory = {
                _id: updateCategoryId,
                type:1,
                storeId: updateCategory.storeid
            }
            let itemsPending = [...state.pending_categories];
            let itemsApproved = [...state.approved_categories];
            let itemsRejected = [...state.rejected_categories];
            let itemsDeleted = [...state.deleted_categories];
            
            if (updateCategory.status === CATEGORY_STATUS.CREATED) {
            
                let isFound = itemsPending.find((_brand) => {return _brand._id === updateCategoryId});
                // If status is updated
                if (!isFound) {
                    itemsPending = [updateCategory, ...itemsPending]
                    itemsApproved =  itemsApproved.filter(_brand => updateCategoryId !== _brand._id);
                    itemsRejected =  itemsRejected.filter(_brand => updateCategoryId !== _brand._id);
                    itemsDeleted =  itemsDeleted.filter(_brand => updateCategoryId !== _brand._id);
                }

                itemsPending = itemsPending.map((_brand) => {
                    if ( _brand._id === updateCategoryId )
                    {
                        return updateCategory;
                    }
                    return _brand;
                });

                CategoryService.updateStoreProduct(firstCategory)
                
                return {
                    ...state,
                    pending_categories: itemsPending,
                    approved_categories: itemsApproved,
                    rejected_categories: itemsRejected,
                    deleted_categories:  itemsDeleted
                }

            } else if (updateCategory.status === CATEGORY_STATUS.APPROVED) {
            
                let isFound = itemsApproved.find((_brand) => {return _brand._id === updateCategoryId});
                // If status is updated
                if (!isFound) {
                    itemsApproved = [updateCategory, ...itemsApproved]
                    itemsPending =  itemsPending.filter(_brand => updateCategoryId !== _brand._id);
                    itemsRejected =  itemsRejected.filter(_brand => updateCategoryId !== _brand._id);
                    itemsDeleted =  itemsDeleted.filter(_brand => updateCategoryId !== _brand._id);
                }

                itemsApproved = itemsApproved.map((_brand) => {
                    if ( _brand._id === updateCategoryId )
                    {
                        return updateCategory;
                    }
                    return _brand;
                });

                CategoryService.updateStoreProduct(firstCategory)

                return {
                    ...state,
                    pending_categories: itemsPending,
                    approved_categories: itemsApproved,
                    rejected_categories: itemsRejected,
                    deleted_categories:  itemsDeleted
                }

            }  else if (updateCategory.status === CATEGORY_STATUS.DELETED) {
            
                let isFound = itemsDeleted.find((_brand) => {return _brand._id === updateCategoryId});
                // If status is updated
                if (!isFound) {
                    itemsDeleted = [updateCategory, ...itemsDeleted]
                    itemsApproved =  itemsApproved.filter(_brand => updateCategoryId !== _brand._id);
                    itemsRejected =  itemsRejected.filter(_brand => updateCategoryId !== _brand._id);
                    itemsPending =  itemsPending.filter(_brand => updateCategoryId !== _brand._id);
                }

                itemsDeleted = itemsDeleted.map((_brand) => {
                    if ( _brand._id === updateCategoryId )
                    {
                        return updateCategory;
                    }
                    return _brand;
                });

                CategoryService.updateStoreProduct(firstCategory)

                return {
                    ...state,
                    pending_categories: itemsPending,
                    approved_categories: itemsApproved,
                    rejected_categories: itemsRejected,
                    deleted_categories:  itemsDeleted
                }

            }   
            else {
            
                let isFound = itemsDeleted.find((_brand) => {return _brand._id === updateCategoryId});
                // If status is updated
                if (!isFound) {
                    itemsRejected = [updateCategory, ...itemsRejected]
                    itemsApproved =  itemsApproved.filter(_brand => updateCategoryId !== _brand._id);
                    itemsPending =  itemsPending.filter(_brand => updateCategoryId !== _brand._id);
                    itemsDeleted =  itemsDeleted.filter(_brand => updateCategoryId !== _brand._id);
                }

                itemsRejected = itemsRejected.map((_brand) => {
                    if ( _brand._id === updateCategoryId )
                    {
                        return updateCategory;
                    }
                    return _brand;
                });

                CategoryService.updateStoreProduct(firstCategory)

                return {
                    ...state,
                    pending_categories: itemsPending,
                    approved_categories: itemsApproved,
                    rejected_categories: itemsRejected,
                    deleted_categories:  itemsDeleted
                }

            }   
        }        
        break;
        case UPDATE_CATEGORY_SORTING_DONE:
        {
            return {
                ...state,
                sortSaved: action.payload,
                err: null
            }
        }
        case UPDATE_STORE_CATEGORY_DONE:
        {
            return {
                ...state                    
            }  
        }
        case UPDATE_STORE_PRODUCT_DONE:
        {
            return {
                ...state                    
            }  
        }           
        case DELETE_CATEGORY_DONE:
            let id = action.payload._id;
            
            if (action.payload.status === CATEGORY_STATUS.CREATED) {
                let itemsManager = [...state.pending_categories];
                itemsManager = itemsManager.filter(_category => id !== _category._id);
                return {
                    ...state,
                    pending_categories: itemsManager,
                    deleted_categories: [{...action.payload, status:4}, ...state.deleted_categories]
                }

            } else if (action.payload.status === CATEGORY_STATUS.APPROVED) {
                let itemsManager = [...state.approved_categories];
                itemsManager = itemsManager.filter(_category => id !== _category._id);
                return {
                    ...state,
                    approved_categories: itemsManager
                }
            } else if (action.payload.status === CATEGORY_STATUS.DELETED) {
                let itemsManager = [...state.deleted_categories];
                itemsManager = itemsManager.filter(_category => id !== _category._id);
                return {
                    ...state,
                    deleted_categories: itemsManager
                }
            }
            else  {
                let itemsManager = [...state.deleted_categories];
                itemsManager = itemsManager.filter(_category => id !== _category._id);
                return {
                    ...state,
                    rejected_categories: itemsManager
                }
            }
            break;
        case OPEN_NEW_CATEGORY_DIALOG:
            return {
                ...state,
                categoryDialog: {
                    type : 'new',
                    props: {
                        open: true
                    },
                    data : null
                }
            };
            
        case OPEN_EDIT_CATEGORY_DIALOG:
            return {
                ...state,
                categoryDialog: {
                    type : 'edit',
                    props: {
                        open: true
                    },
                    data : action.data
                }
            };
        case CLOSE_CATEGORY_DIALOG:
            return {
                ...state,
                categoryDialog: {
                    type : 'new',
                    props: {
                        open: false
                    },
                    data : null
                }
            };
                
        default:
            return state;
    }
}

export default categoryReducer;