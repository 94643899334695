import { combineReducers } from 'redux';

import settingsReducer from './settings.reducer.js';
import themesReducer from './themes.reducers.js';
import authReducer from './auth.reducers.js';
import brandReducer from './brand.reducers.js';
import scheduleReducer from './schedule.reducers.js';
import managerReducer from './manager.reducers.js';
import categoryReducer from './category.reducers.js';
import addonReducer from './addon.reducers.js';
import commonReducer from './common.reducers.js';

import ordersReducer from './orders.reducers.js';
import storeMenuReducer from './storeMenu.reducers.js';
import storeStatusReducer from './storeStatus.reducers.js';
import offerReducer from './offer.reducers.js';
import walletReducer from './wallet.reducers.js';
import faqReducer from './faq.reducers.js';
import ticketReducer from './ticket.reducers.js';
import invoiceReducer from './invoice.reducer.js';


export default combineReducers({
    settings: settingsReducer,
    theme: themesReducer,
    auth: authReducer,
    brand: brandReducer,
    schedule:scheduleReducer,
    manager:managerReducer,
    category:categoryReducer,
    addon:addonReducer,
    common:commonReducer,
    orders:ordersReducer,
    storeMenu: storeMenuReducer,
    updateStatus:storeStatusReducer,
    offer: offerReducer,
    wallet: walletReducer,
    faq: faqReducer,
    ticket: ticketReducer,
    invoices: invoiceReducer
});
