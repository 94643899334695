import React from "react";

import { CardBody } from "reactstrap";
import utilService from "../../common/utilService";
import {
  COLORS,
  ORDER_BOOKING_TYPE,
  ORDER_SERVICE_TYPE,
} from "../../components/Common/constants";

const OrderRenderContent = ({
  order,
  orderForAndroid,
  onClose,
  handlePrint,
  getAddonName,
}) => {
  return (
    <div>
      <hr style={{ borderTopWidth: "2px", margin: 0 }} />
      <CardBody
        className="d-flex"
        style={{
          justifyContent: "space-between",
          paddingLeft: 0,
          paddingRight: 0,
        }}
      >
        <div>{orderForAndroid.placedAt}</div>
        <div style={{ color: "#21478F", textAlign: "end" }}>
          {orderForAndroid.created}
        </div>
      </CardBody>
      {order.bookingType === ORDER_BOOKING_TYPE.SCHEDULE && (
        <CardBody
          className="d-flex"
          style={{
            justifyContent: "space-between",
            paddingLeft: 0,
            paddingRight: 0,
          }}
        >
          <div>{utilService.getLangByCode("Desired time")}</div>
          <div style={{ color: "#21478F" }}>{orderForAndroid.due}</div>
        </CardBody>
      )}
      <CardBody
        className="d-flex"
        style={{
          justifyContent: "space-between",
          paddingLeft: 0,
          paddingRight: 0,
          flexDirection: "row",
        }}
      >
        <div className="mr-3 w-50">
          {utilService.getLangByCode("Delivery address")}
        </div>
        {order.serviceType === ORDER_SERVICE_TYPE.delivery && (
          <div style={{ color: "#21478F", textAlign: "left" }}>
            {orderForAndroid.dropAddress}
          </div>
        )}
        {order.serviceType === ORDER_SERVICE_TYPE.pickup && (
          <div style={{ color: "#21478F" }}>
            {utilService.getLangByCode("Pickup")}
            {/* {utilService.getLangByCode("Address")} */}
          </div>
        )}
      </CardBody>
      <CardBody
        className="d-flex"
        style={{
          justifyContent: "space-between",
          paddingLeft: 0,
          paddingRight: 0,
        }}
      >
        <div>{utilService.getLangByCode("Customer details")}</div>
        <div style={{ color: "#21478F" }}>
          <span>
            <span>{orderForAndroid.customerName} - </span>
            <span>{orderForAndroid.customerMobile}</span>
          </span>
        </div>
      </CardBody>

      <hr style={{ borderTopWidth: "2px", margin: 0 }} />
      <CardBody
        className="d-flex"
        style={{
          justifyContent: "space-between",
          color: "#21478F",
          fontWeight: "bold",
          paddingLeft: 0,
          paddingRight: 0,
        }}
      >
        <div>{utilService.getLangByCode("Item")}</div>
        <div>{utilService.getLangByCode("Price")}</div>
      </CardBody>
      <hr style={{ borderTopWidth: "2px", margin: 0 }} />
      {orderForAndroid.items &&
        orderForAndroid.items.map((data, index) => {
          return (
            <div
              style={{ paddingLeft: 0, paddingRight: 0 }}
              key={`SubItem${index}`}
            >
              <CardBody
                className="d-flex"
                style={{
                  justifyContent: "space-between",
                  alignItems: "center",
                  paddingLeft: 0,
                  paddingRight: 0,
                }}
              >
                {/* <div className="d-flex" style={{justifyContent:'space-between'}}> */}
                <div style={{ flex: 2 }}>{data.itemName}</div>
                <div style={{ flex: 1 }}>
                  {data.quantity +
                    " x " +
                    orderForAndroid.currencySymbol +
                    data.unitPrice.toFixed(2)}
                </div>
                {/* </div> */}
                {/* <div style={{color:'#21478F'}}>{order.currencySymbol + data.finalPrice.toFixed(2)}</div> */}
                <div style={{ color: "#21478F" }}>
                  {orderForAndroid.currencySymbol +
                    (data.quantity * data.unitPrice).toFixed(2)}
                </div>
              </CardBody>
              {data.addOns &&
                data.addOns.map((addOnData, addOnIndex) => {
                  return (
                    <div key={`addonItem${addOnIndex}`}>
                      {addOnData.addOnGroup &&
                        addOnData.addOnGroup.map((addedOnData, sub_index) => {
                          if (addedOnData.price !== undefined) {
                            return (
                              <CardBody
                                className="d-flex"
                                style={{
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  paddingLeft: "30px",
                                  paddingRight: 0,
                                  fontSize: "12px",
                                }}
                                key={`SubAddOnItem${sub_index}`}
                              >
                                <div style={{ flex: 2 }}>
                                  {"+" + getAddonName(addedOnData)}
                                </div>
                                <div style={{ flex: 1 }}>
                                  {data.quantity +
                                    " x " +
                                    order.currencySymbol +
                                    Number(addedOnData.price).toFixed(2)}
                                </div>
                                <div style={{ color: "#21478F" }}>
                                  {orderForAndroid.currencySymbol +
                                    (
                                      data.quantity * Number(addedOnData.price)
                                    ).toFixed(2)}
                                </div>
                              </CardBody>
                            );
                          }
                        })}
                    </div>
                  );
                })}
            </div>
          );
        })}
      <hr style={{ borderTopWidth: "2px", margin: 0 }} />
      <CardBody
        className="d-flex"
        style={{
          justifyContent: "space-between",
          paddingLeft: 0,
          paddingRight: 0,
        }}
      >
        <div>{utilService.getLangByCode("Delivery fee")}</div>
        <div style={{ color: "#21478F" }}>
          {<div>{orderForAndroid.deliveryFee}</div>}
        </div>
      </CardBody>
      <hr style={{ borderTopWidth: "2px", margin: 0 }} />
      <CardBody
        className="d-flex"
        style={{
          justifyContent: "space-between",
          color: "#21478F",
          fontWeight: "bold",
          paddingLeft: 0,
          paddingRight: 0,
        }}
      >
        <div>{utilService.getLangByCode("Total")}</div>
        {order.currencySymbol && order.subTotalAmount && (
          <div style={{ color: "#21478F", fontWeight: "bold" }}>
            {orderForAndroid.totalAmount}
          </div>
        )}
      </CardBody>
      {orderForAndroid.extraNote ? (
        <>
          <hr style={{ borderTopWidth: "2px", margin: 0 }} />
          <CardBody
            className="d-flex"
            style={{
              justifyContent: "space-between",
              color: "#21478F",
              fontWeight: "bold",
              paddingLeft: 0,
              paddingRight: 0,
            }}
          >
            <div>{utilService.getLangByCode("Notes")}</div>
            <div
              style={{
                color: "#21478F",
                fontWeight: "bold",
                marginLeft: "30px",
              }}
            >
              {orderForAndroid.extraNote}
            </div>
          </CardBody>
        </>
      ) : null}
      <CardBody
        className="d-flex"
        style={{ justifyContent: "center", paddingLeft: 0, paddingRight: 0 }}
      >
        <div className="d-flex" style={{ justifyContent: "center" }}>
          <button
            className="btn bg-btn-approve text-white"
            onClick={() => {
              onClose();
            }}
            style={{ width: "100px", borderWidth: "2px" }}
          >
            {utilService.getLangByCode("Close")}
          </button>
          <button
            id="printBtn"
            className="btn bg-red"
            onClick={() => handlePrint()}
            style={{
              width: "100px",
              borderWidth: "2px",
              borderColor: COLORS.RED,
              color: COLORS.WHITE,
              marginLeft: "20px",
            }}
          >
            {utilService.getLangByCode("Print")}
          </button>
        </div>
      </CardBody>
    </div>
  );
};

export default OrderRenderContent;
