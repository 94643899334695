import { HTTP_ERRORS } from '../../components/Common/constants';
import OrdersService from '../../services/ordersService';

import { DISPATCH_ORDER, UPDATE_ORDER_STATUS, COMPLETE_ORDER,
  GET_ORDERS, GOT_ERROR_ORDER, DISPATCH_ORDER_DONE, UPDATE_ORDER_STATUS_DONE, 
  COMPLETE_ORDER_DONE, GET_ORDERS_DONE, OPEN_CONFIRM_DIALOG,
  UPDATE_EXPIRED_STATUS,
  INVALID_TOKEN_ERROR
} from '../actions/actions';

const orders = store => next => action => {

    let dispatch = store.dispatch;
    if (action.type === DISPATCH_ORDER) {       
      // Make an API call to login the server            
      OrdersService.dispatchOrder(action.data).then(result => {       
        dispatch({ type: DISPATCH_ORDER_DONE, payload: result, queryData: action.data });
      })
      .catch(err=>{
        if(err === HTTP_ERRORS.InvalidToken)
        {
          dispatch({ type: INVALID_TOKEN_ERROR, payload: err });
        }
        else
        {
          dispatch({ type: GOT_ERROR_ORDER, payload: err })
        }
      })
    } else if (action.type === UPDATE_ORDER_STATUS) {      
      OrdersService.updateOrderStatus(action.data).then(result => {
        dispatch({ type: UPDATE_ORDER_STATUS_DONE, payload:result, queryData: action.data });
      })
      .catch(err=>{
        if(err === HTTP_ERRORS.InvalidToken)
        {
          dispatch({ type: INVALID_TOKEN_ERROR, payload: err });
        }
        else
        {
          dispatch({ type: GOT_ERROR_ORDER, payload: err })
        }
      })
    } else if (action.type === COMPLETE_ORDER) {
      OrdersService.completeOrder(action.data, action.userId).then(result => {        
        dispatch({ type: COMPLETE_ORDER_DONE, payload:result, queryData: action.data });        
      })
      .catch(err=>{
        if(err === HTTP_ERRORS.InvalidToken)
        {
          dispatch({ type: INVALID_TOKEN_ERROR, payload: err });
        }
        else
        {
          dispatch({ type: GOT_ERROR_ORDER, payload: err })
        }
      })      
    } else if (action.type === GET_ORDERS) {  
      
      if ( action.data.fromDate > action.data.toDate ){
        dispatch({ type: OPEN_CONFIRM_DIALOG, payload: {
          title:'Error ', 
          description:"Please select start and end date correctly",
          action_type:'', 
          dialog_type:'warning'
        }});
      }else{
        OrdersService.getOrders(action.data).then(result => {       
          dispatch({ type: GET_ORDERS_DONE, payload:result, queryData: action.data });
        })
        .catch(err=>{
          if(err === HTTP_ERRORS.InvalidToken)
          {
            dispatch({ type: INVALID_TOKEN_ERROR, payload: err });
          }
          else
          {
            dispatch({ type: GOT_ERROR_ORDER, payload: err })
          }
        }) 
      }
      
      
    }
    // else if (action.type === UPDATE_EXPIRED_STATUS) {      
    //   dispatch({ type: UPDATE_EXPIRED_STATUS, payload: action.data});
    // }
  
    return next(action)
  }

export default orders;