import { HTTP_ERRORS } from '../../components/Common/constants';
import StoreMenuService from '../../services/storeMenuService';

import { GET_STORE_MENU, CREATE_STORE_MENU, UPDATE_STORE_MENU, 
  DELETE_STORE_MENU, GET_STORE_MENU_DONE, UPDATE_STORE_MENU_DONE,
  DELETE_STORE_MENU_DONE, CREATE_STORE_MENU_DONE, 
  GOT_ERROR_STORE_MENU,
  INVALID_TOKEN_ERROR} from '../actions/actions';

const manager = store => next => action => {
    let dispatch = store.dispatch;
    if (action.type === GET_STORE_MENU) {       
      // Make an API call to login the server            
      StoreMenuService.getStoreMenu(action.queryData).then(result => {        
        dispatch({ type: GET_STORE_MENU_DONE, payload: result, queryData:action.queryData });
      })
      .catch(err=>{
        if(err === HTTP_ERRORS.InvalidToken)
        {
          dispatch({ type: INVALID_TOKEN_ERROR, payload: err });
        }
        else
        {
          dispatch({ type: GOT_ERROR_STORE_MENU, payload: err })
        }
      })
    } else if (action.type === CREATE_STORE_MENU) {      
      StoreMenuService.createStoreMenu(action.data).then(result => {
        dispatch({ type: CREATE_STORE_MENU_DONE, payload:result });
      })
      .catch(err=>{
        if(err === HTTP_ERRORS.InvalidToken)
        {
          dispatch({ type: INVALID_TOKEN_ERROR, payload: err });
        }
        else
        {
          dispatch({ type: GOT_ERROR_STORE_MENU, payload: err })
        }
      })
    } else if (action.type === UPDATE_STORE_MENU) {
      StoreMenuService.updateStoreMenu(action.data).then(result => {        
        dispatch({ type: UPDATE_STORE_MENU_DONE, payload:action.data });        
      })
      .catch(err=>{
        if(err === HTTP_ERRORS.InvalidToken)
        {
          dispatch({ type: INVALID_TOKEN_ERROR, payload: err });
        }
        else
        {
          dispatch({ type: GOT_ERROR_STORE_MENU, payload: err })
        }
      })      
    } else if (action.type === DELETE_STORE_MENU) {      
      StoreMenuService.deleteStoreMenu(action.data._id).then(result => {       
        dispatch({ type: DELETE_STORE_MENU_DONE, payload:action.data });
      })
      .catch(err=>{
        if(err === HTTP_ERRORS.InvalidToken)
        {
          dispatch({ type: INVALID_TOKEN_ERROR, payload: err });
        }
        else
        {
          dispatch({ type: GOT_ERROR_STORE_MENU, payload: err })
        }
      }) 
    }
  
    return next(action)
  }

export default manager;