// GLOBAL CONSTANTS
// -----------------------------------
export const USER_DATA = "userData";
export const AXIOS_AUTH = "Authorization";
export const AXIOS_LANGUAGE = "language";
export const JWT_ACCESS_TOKEN = "jwt_access_token";
export const CUSTOMER_PAGE_PREVIEW_WINDOW_NAME = "customer-page-preview";
export const APP_LANG_KEY = "jq-appLang";

export const isValidToken = (token) => {
  let jwt_access_token = localStorage.getItem(JWT_ACCESS_TOKEN);
  if( jwt_access_token !== undefined && jwt_access_token !== null )
  {
    jwt_access_token = "Bearer " + jwt_access_token;
  }

  if(jwt_access_token === token)
  {
      return true;
  }
  return false;
}

export const getUserData = () => {
  let userData = localStorage.getItem( USER_DATA );
  if ( userData !== undefined && userData !== null ) {
    userData = JSON.parse( userData );
    return userData;
  }

  return null;
}

export const getLanguage = () => {
  let lang = localStorage.getItem(APP_LANG_KEY);
  console.log("lang =>", lang)
  return lang || "en";
}

export const FCM_ACTION_TYPE = {
  ORDER: 1,
  LOGOUT: 12,
}

export const DISPATCHER_USER_TYPE = {
  CentralUser: 0,   // user created on super site (Custom service)
  Manager:1,         // store manager created on store site
  Packer:2,          // packer created on store site
  FranchiseManager: 3
};

export const APP_COLORS = {
    'primary':                '#5d9cec',
    'success':                '#27c24c',
    'info':                   '#23b7e5',
    'warning':                '#ff902b',
    'danger':                 '#f05050',
    'inverse':                '#131e26',
    'green':                  '#37bc9b',
    'pink':                   '#f532e5',
    'purple':                 '#7266ba',
    'dark':                   '#3a3f51',
    'yellow':                 '#fad732',
    'gray-darker':            '#232735',
    'gray-dark':              '#3a3f51',
    'gray':                   '#dde6e9',
    'gray-light':             '#e4eaec',
    'gray-lighter':           '#edf1f2'
};

export const APP_MEDIAQUERY = {
    'desktopLG':             1200,
    'desktop':                992,
    'tablet':                 768,
    'mobile':                 480
};

export const STORE_STATUS = {
  ACTIVE_STORE: 1,
  INACTIVE_STORE: 2,
  //DELETED_STORE:3, ??
}

/* defined order status for getOrders */
export const QUERY_ORDER_STATUS = {
  NEW_ORDER : 1,
  ACCEPTED_ORDER : 2,
  PICKUP_READY_ORDER : 3,
  DELIVERY_READY_ORDER : 4,
  COMPLETED_ORDER : 5,
  CANCELLED_ORDER : 6
}
/* ------------ Order Flow -------------- */
/* **deliveryOrder **
  newOrder ( 1 ) => unassignOrders ( 4 ) => unassignOrders ( 40 ) => completedOrders ( 15 )
*/
/* ** PickupOrder **
  newOrder ( 1 ) => pickupOrders ( 4 ) => pickupOrders ( 5 ) => completedOrders ( 7 )
/* -------------------------------------- */

export const ORDER_STATUS = {
    created: 1,         // 'created'
    cancelled: 2,       // 'cancelled'
    rejected: 3,        // 'rejected'
    accepted: 4,        // 'accpeted'
    orderReady: 5,      // 'orderReady'
    orderPicked: 6,     // 'orderPicked'
    orderCompleted: 7,  // 'orderCompleted_pickup'
    orderDeliveryCompleted: 15, // 'orderCompleted_delivery'
    orderExpired: 20,   // 'orderExpired'
    inDispatch: 40      // 'inDispatch'
  }
export const ORDER_SERVICE_TYPE = {
  both:0,
  delivery:1,
  pickup:2,
}
  
export const ORDER_BOOKING_TYPE = {
  ASAP: 1,
  SCHEDULE: 2
}

export const COLORS = {
  GENERAL     : '#707070',
  RED         : '#ff0000',
  BLUE        : '#097bed',
  GREEN       : '#39baa6',
  LIGHTRED    : '#de5351',
  MAINGREEN   : '#39baa6',
  DARK        : '#4c4c4c',
  DARK2       : '#868686',

  ORANGE      : '#f5911f',
  PURPLE      : '#6881c0',
  LIGHTORANGE : '#f9d3b8',
  CYAN        : '#14B1F8',
  LIGHTPURPLE : '#D7CCEA',
  WHITEPURPLE : '#ECE9F2',
  YELLOW      : '#FFE63E',
  BLACK       : '#000000',
  GREY1       : '#666666',
  GREY2       : '#b7b7b7',
  GREY3       : '#c5c5c5',
  GREY4       : '#eaeaea',
  LABELGREY   : '#888888',
  WHITE       : '#ffffff',
  SKY         : '#27a0d4',
  SKY2        : '#929fd0',
  PINK        : "#ec1765",
  TITLECOLOR  : "#061c46",
  Ultramarine : "#21478f",
  LIGHTGREEN  : "#a7ba13",
  SEPARATOR   : "#e8ecf4",
}

export const ADDON_STATUS = {
  PENDING: 0,
  APPROVED: 1,
  REJECTED: 2,
  DELETED: 3,
  OUT_OF_STOCK: 5,
}

export const CATEGORY_STATUS = {
  CREATED:0,
  APPROVED: 1,
  DELETED:2,
  REJECTED:3,
}

export const PAYMENT_TYPE = {
  CASH:2,
  MULTISAFEPAY: 4,
}

export const PRODUCT_STATUS = {
  PENDING: 0,
  APPROVED: 1,
  DELETED: 2,
  REJECTED: 3,
  BANNED: 4,
  OUT_OF_STOCK: 5,
  ALL: 6,
}

export const MANAGER_STATUS = {
  APPROVED: 1,
  LOGGED_IN: 2,
  LOGGED_OUT: 3,
  REMOVED: 4
}

export const SCHEDULE_STATUS = {
  ACTIVE : 1,
  EXPIRED : 2,
  DELETED : 3,
}

export const BROWSER_DEVICE_ID = {
  MICROSOFT_EDGE:   1,
  INTERNET_EXPLORER: 2,
  FIREFOX: 3,
  OPERA: 4,
  UCBROWSER: 5,
  SAMSUNG: 6,
  GOOGLE_CHROME: 7,
  SAFARI: 8,
  OTHER: 9
}


export const getBrowserId = () => {
  var test = function(regexp) {return regexp.test(window.navigator.userAgent)}
  switch (true) {
      case test(/edg/i): 
          return BROWSER_DEVICE_ID.MICROSOFT_EDGE; //"Microsoft Edge";
      case test(/trident/i): 
          return BROWSER_DEVICE_ID.INTERNET_EXPLORER; //"Microsoft Internet Explorer";
      case test(/firefox|fxios/i): 
          return BROWSER_DEVICE_ID.FIREFOX;   //"Mozilla Firefox";
      case test(/opr\//i): 
          return BROWSER_DEVICE_ID.OPERA;     //"Opera";
      case test(/ucbrowser/i): 
          return BROWSER_DEVICE_ID.UCBROWSER; //"UC Browser";
      case test(/samsungbrowser/i): 
          return BROWSER_DEVICE_ID.SAMSUNG;   // "Samsung Browser";
      case test(/chrome|chromium|crios/i): 
          return BROWSER_DEVICE_ID.GOOGLE_CHROME;  //"Google Chrome";
      case test(/safari/i): 
          return BROWSER_DEVICE_ID.SAFARI; //"Apple Safari";
      default: 
          return BROWSER_DEVICE_ID.OTHER; //"Other";
  }
}

export const HTTP_ERRORS = {
  Unauthorized: 401,
  BadRequest: 400,
  NotFound: 404,
  Forbidden: 403,
  InternalServerError: 500,
  GatewayTimeout: 504,

  InvalidToken: 498,
  OK: 200,
  emailIsExistAlready: 300  // custom err code
}

export const OFFERS_STATUS = {
  NEW : 0,
  ACTIVE: 1,
  IN_ACTIVE: 2,
  EXPIRED: 3
}

export const OFFER_TYPE = {
  FLAT_DISCOUNT: 0,
  PERCENT_DISCOUNT: 1
}

export const OS = {
  Windows: 1,
  Linux: 2,
  MacOS: 3,
  IOS: 4,
  Android: 5
}

export const getOS = () => {
  var userAgent = window.navigator.userAgent,
      platform = window.navigator.platform || window.navigator?.userAgentData?.platform,
      macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
      windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
      iosPlatforms = ['iPhone', 'iPad', 'iPod'],
      os = null;
  console.log('macPlatform:', macosPlatforms, 'windowsPlatforms:', windowsPlatforms, 
      'iosPlatforms:',iosPlatforms, 'platform:', platform)
  if (macosPlatforms.indexOf(platform) !== -1) {
    os = OS.MacOS;
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    os = OS.IOS;
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = OS.indows;
  } else if (/Android/.test(userAgent)) {
    os = OS.Android;
  } else if (!os && /Linux/.test(platform)) {
    os = OS.Linux;
  }

  return os;
}
