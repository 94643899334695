import { MANAGER_STATUS } from '../../components/Common/constants';
import { GET_MANAGERS_DONE, GOT_ERROR_MANAGER, 
    CREATE_MANAGER_DONE, UPDATE_MANAGER_DONE, 
    DELETE_MANAGER_DONE, OPEN_NEW_MANAGER_DIALOG, 
    OPEN_EDIT_MANAGER_DIALOG, CLOSE_MANAGER_DIALOG,
    EMAIL_EXIST_ALREADY
} from '../actions/manager.actions';

const initialState = {
    approved_managers:[],
    loggedin_managers:[],
    logout_managers:[],
    deleted_managers:[],

    approved_managers_count: 0,
    loggedin_managers_count: 0,
    logout_managers_count: 0,
    deleted_managers_count: 0,
    err_manager:null,
    is_email_exist: null,
    managerDialog     : {
        type : 'new',
        props: {
            open: false
        },
        data : null
    }
}

const managerReducer = (state = initialState, action) => {    
    //console.log("Manager reducer = ", action)
    switch (action.type) {
        case GET_MANAGERS_DONE:
            // //console.log( 'get schedule reducer =', action.payload.data, action.payload.data.data, action.payload.data.count )
            let data = action.payload.data.data
            let count = action.payload.data.count
            if (action.queryData.status === MANAGER_STATUS.APPROVED) {
                return {
                    ...state,
                    is_email_exist: false,
                    approved_managers: data || [],
                    approved_managers_count: count
                }    
            } 
            else if (action.queryData.status === MANAGER_STATUS.LOGGED_IN) {
                return {
                    ...state,
                    is_email_exist: false,
                    loggedin_managers: data || [],
                    loggedin_managers_count: count
                }    
            } 
            else if (action.queryData.status === MANAGER_STATUS.LOGGED_OUT) {
                return {
                    ...state,
                    is_email_exist: false,
                    logout_managers: data || [],
                    logout_managers_count: count
                }    
            }
            else if (action.queryData.status === MANAGER_STATUS.REMOVED) {
                return {
                    ...state,
                    is_email_exist: false,
                    deleted_managers: data || [],
                    deleted_managers_count: count
                }    
            }     
            break;
        case CREATE_MANAGER_DONE: // Status: only approved
            let manager = action.payload.data;           

            if (manager.status === MANAGER_STATUS.APPROVED) {                
                return {
                    ...state,
                    approved_managers: [manager, ...state.approved_managers],
                    approved_managers_count: state.approved_managers_count + 1,
                    is_email_exist: false,
                    managerDialog: {
                        type : 'new',
                        props: {
                            open: false
                        },
                        data : null
                    }
                }
            }
            else
            {
                return {
                    ...state,
                    is_email_exist: false,
                    err_manager: "Manager status is not approved"
                }
            }
        case UPDATE_MANAGER_DONE:            

            let updateManager = action.payload;

            let itemsApproved = [...state.approved_managers];
            let itemsLoggedIn = [...state.loggedin_managers];
            let itemsLogout = [...state.logout_managers];
            let itemsDeleted  = [...state.deleted_managers];
            
            let isFoundApproved = itemsApproved.find((_manager) => {return _manager._id === updateManager._id});
            let isFoundLoggedIn = itemsLoggedIn.find((_manager) => {return _manager._id === updateManager._id});
            let isFoundLoggedOut = itemsLogout.find((_manager) => {return _manager._id === updateManager._id});
            let isFoundDeleted = itemsDeleted.find((_manager) => {return _manager._id === updateManager._id});

            let approved_cnt = state.approved_managers_count;
            let login_cnt = state.loggedin_managers_count;
            let logout_cnt = state.logout_managers_count;
            let deleted_cnt = state.deleted_managers_count;

            if (updateManager.status === MANAGER_STATUS.APPROVED) {

                approved_cnt = isFoundApproved ? approved_cnt - 1 : approved_cnt;
                login_cnt = isFoundLoggedIn ? login_cnt - 1 : login_cnt;
                logout_cnt = isFoundLoggedOut ? logout_cnt - 1 : logout_cnt;
                deleted_cnt = isFoundDeleted ? deleted_cnt - 1 : deleted_cnt;
                // If status is updated
                if (!isFoundApproved) {
                    itemsApproved = [updateManager, ...itemsApproved]
                    itemsLoggedIn =  itemsLoggedIn.filter(_manager => updateManager._id !== _manager._id);
                    itemsLogout   =  itemsLogout.filter(_manager => updateManager._id !== _manager._id);
                    itemsDeleted  =  itemsDeleted.filter(_manager => updateManager._id !== _manager._id);

                    approved_cnt += 1;
                }

                itemsApproved = itemsApproved.map((_manager) => {
                    if ( _manager._id === updateManager._id )
                    {
                        return updateManager;
                    }
                    return _manager;
                });
                return {
                    ...state,
                    approved_managers:  itemsApproved,
                    loggedin_managers:  itemsLoggedIn,
                    logout_managers:    itemsLogout,
                    deleted_managers:   itemsDeleted,

                    approved_managers_count: approved_cnt,
                    loggedin_managers_count: login_cnt,
                    logout_managers_count: logout_cnt,
                    deleted_managers_count: deleted_cnt,
                    is_email_exist: false,
                    managerDialog: {
                        type : 'new',
                        props: {
                            open: false
                        },
                        data : null
                    }
                }

            } else if (updateManager.status === MANAGER_STATUS.LOGGED_IN) {

                approved_cnt = isFoundApproved ? approved_cnt - 1 : approved_cnt;
                login_cnt = isFoundLoggedIn ? login_cnt - 1 : login_cnt;
                logout_cnt = isFoundLoggedOut ? logout_cnt - 1 : logout_cnt;
                deleted_cnt = isFoundDeleted ? deleted_cnt - 1 : deleted_cnt;

                // If status is updated
                if (!isFoundLoggedIn) {
                    itemsLoggedIn   = [updateManager, ...itemsLoggedIn]
                    itemsApproved   =  itemsApproved.filter(_manager => updateManager._id !== _manager._id);
                    itemsLogout     =  itemsLogout.filter(_manager => updateManager._id !== _manager._id);
                    itemsDeleted   =  itemsDeleted.filter(_manager => updateManager._id !== _manager._id);

                    login_cnt += 1;
                }

                itemsLoggedIn = itemsLoggedIn.map((_manager) => {
                    if ( _manager._id === updateManager._id )
                    {
                        return updateManager;
                    }
                    return _manager;
                });
                return {
                    ...state,
                    approved_managers:  itemsApproved,
                    loggedin_managers:  itemsLoggedIn,
                    logout_managers:    itemsLogout,
                    deleted_managers:   itemsDeleted,

                    approved_managers_count: approved_cnt,
                    loggedin_managers_count: login_cnt,
                    logout_managers_count: logout_cnt,
                    deleted_managers_count: deleted_cnt,
                    is_email_exist: false,
                    managerDialog: {
                        type : 'new',
                        props: {
                            open: false
                        },
                        data : null
                    }
                }

            } else if (updateManager.status === MANAGER_STATUS.LOGGED_OUT) {
            
                approved_cnt = isFoundApproved ? approved_cnt - 1 : approved_cnt;
                login_cnt = isFoundLoggedIn ? login_cnt - 1 : login_cnt;
                logout_cnt = isFoundLoggedOut ? logout_cnt - 1 : logout_cnt;
                deleted_cnt = isFoundDeleted ? deleted_cnt - 1 : deleted_cnt;

                // If status is updated
                if (!isFoundLoggedOut) {
                    itemsLogout   = [updateManager, ...itemsLogout]
                    itemsApproved   =  itemsApproved.filter(_manager => updateManager._id !== _manager._id);
                    itemsLoggedIn     =  itemsLoggedIn.filter(_manager => updateManager._id !== _manager._id);
                    itemsDeleted   =  itemsDeleted.filter(_manager => updateManager._id !== _manager._id);

                    logout_cnt += 1;
                }

                itemsLogout = itemsLogout.map((_manager) => {
                    if ( _manager._id === updateManager._id )
                    {
                        return updateManager;
                    }
                    return _manager;
                });
                return {
                    ...state,
                    approved_managers:  itemsApproved,
                    loggedin_managers:  itemsLoggedIn,
                    logout_managers:    itemsLogout,
                    deleted_managers:   itemsDeleted,

                    approved_managers_count: approved_cnt,
                    loggedin_managers_count: login_cnt,
                    logout_managers_count: logout_cnt,
                    deleted_managers_count: deleted_cnt,
                    is_email_exist: false,
                    managerDialog: {
                        type : 'new',
                        props: {
                            open: false
                        },
                        data : null
                    }
                }

            } else if (updateManager.status === MANAGER_STATUS.REMOVED) {
            
                approved_cnt = isFoundApproved ? approved_cnt - 1 : approved_cnt;
                login_cnt = isFoundLoggedIn ? login_cnt - 1 : login_cnt;
                logout_cnt = isFoundLoggedOut ? logout_cnt - 1 : logout_cnt;
                deleted_cnt = isFoundDeleted ? deleted_cnt - 1 : deleted_cnt;

                // If status is updated
                if (!isFoundDeleted) {
                    itemsDeleted   = [updateManager, ...itemsDeleted]
                    itemsApproved   =  itemsApproved.filter(_manager => updateManager._id !== _manager._id);
                    itemsLoggedIn     =  itemsLoggedIn.filter(_manager => updateManager._id !== _manager._id);
                    itemsLogout   =  itemsLogout.filter(_manager => updateManager._id !== _manager._id);

                    deleted_cnt += 1;
                }

                itemsDeleted = itemsDeleted.map((_manager) => {
                    if ( _manager._id === updateManager._id )
                    {
                        return updateManager;
                    }
                    return _manager;
                });
                return {
                    ...state,
                    approved_managers:  itemsApproved,
                    loggedin_managers:  itemsLoggedIn,
                    logout_managers:    itemsLogout,
                    deleted_managers:   itemsDeleted,

                    approved_managers_count: approved_cnt,
                    loggedin_managers_count: login_cnt,
                    logout_managers_count: logout_cnt,
                    deleted_managers_count: deleted_cnt,
                    is_email_exist: false,
                    managerDialog: {
                        type : 'new',
                        props: {
                            open: false
                        },
                        data : null
                    }
                }

            }   
            break;
        case DELETE_MANAGER_DONE: // Status : only Removed

            let id = action.payload._id;
            
            if (action.payload.status === MANAGER_STATUS.REMOVED) {
                let itemsManager = [...state.deleted_managers];
                itemsManager = itemsManager.filter(_manager => id !== _manager._id);
                return {
                    ...state,
                    is_email_exist: false,
                    deleted_managers: itemsManager,
                    deleted_managers_count: state.deleted_managers_count - 1
                }
            }
            else
            {
                return {
                    ...state,
                    is_email_exist: false,
                    err_manager: "Manager status is not removed"
                }
            }
            break;
        case OPEN_NEW_MANAGER_DIALOG:
            return {
                ...state,
                is_email_exist: false,
                managerDialog: {
                    type : 'new',
                    props: {
                        open: true
                    },
                    data : null
                }
            };
            
        case OPEN_EDIT_MANAGER_DIALOG:
            return {
                ...state,
                is_email_exist: false,
                managerDialog: {
                    type : 'edit',
                    props: {
                        open: true
                    },
                    data : action.data
                }
            };
        case CLOSE_MANAGER_DIALOG:
            return {
                ...state,
                is_email_exist: false,
                managerDialog: {
                    type : 'new',
                    props: {
                        open: false
                    },
                    data : null
                }
            };
        case GOT_ERROR_MANAGER:
            return {
                ...state,
                is_email_exist: false,
                err_manager: action.payload
            }
        case EMAIL_EXIST_ALREADY:
            return {
                ...state,
                is_email_exist: true
            }
        default:
            return {
                ...state,
                is_email_exist: false
            };
    }
}

export default managerReducer;