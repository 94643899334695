export const GET_SCHEDULES = 'GET_SCHEDULES';
export const CREATE_SCHEDULE = 'CREATE_SCHEDULE';
export const DELETE_SCHEDULE = 'DELETE_SCHEDULE';
export const UPDATE_SCHEDULE = 'UPDATE_SCHEDULE';
export const GOT_ERROR_SCHEDULE = 'GOT_ERROR_SCHEDULE';
export const ADD_SLOT = "ADD_SLOT";
export const DELETE_SLOT = "DELETE_SLOT";

export const UPDATE_SCHEDULE_DONE = 'UPDATE_SCHEDULE_DONE';
export const DELETE_SCHEDULE_DONE = "DELETE_SCHEDULE_DONE";
export const CREATE_SCHEDULE_DONE = "CREATE_SCHEDULE_DONE";
export const GET_SCHEDULES_DONE = 'GET_SCHEDULES_DONE';
export const ADD_SLOT_DONE = 'ADD_SLOT_DONE';
export const DELETE_SLOT_DONE = 'DELETE_SLOT_DONE'; 


export const OPEN_NEW_SCHEDULE_DIALOG = 'OPEN_NEW_SCHEDULE_DIALOG';
export const OPEN_EDIT_SCHEDULE_DIALOG = 'OPEN_EDIT_SCHEDULE_DIALOG';
export const CLOSE_SCHEDULE_DIALOG = 'CLOSE_SCHEDULE_DIALOG';

export const SHOW_ALERT = "SHOW_ALERT";
/**
 * Get active schedule list
 */
export function getSchedules(queryData) {    
    return { type: GET_SCHEDULES, queryData:queryData };
}

/**
 * Create Schedule
 */
export function createSchedule(scheduleData) {
    return { type: CREATE_SCHEDULE, data:scheduleData };
}

/**
 * Update Schedule
 */
export function updateSchedule(scheduleData) {
    return { type: UPDATE_SCHEDULE, data:scheduleData };
}

/**
 * Delete Schedule
 */
export function deleteSchedule(scheduleData) {
    return { type: DELETE_SCHEDULE, data:scheduleData };
}

/**
 * Add Slot
 */
 export function addSlot(slotData) {
    return { type: ADD_SLOT, data:slotData };
}

/**
 * Delete Slot
 */
 export function deleteSlot(slotData) {
    return { type: DELETE_SLOT, data:slotData };
}

export function showAlert( alertData ) {
    return { type: SHOW_ALERT, data: alertData };
}


export function openNewScheduleDialog()
{
    return {
        type: OPEN_NEW_SCHEDULE_DIALOG
    }
}

export function openEditScheduleDialog(data)
{
    return {
        type: OPEN_EDIT_SCHEDULE_DIALOG, data:data
    }
}

export function closeScheduleDialog()
{
    return {
        type: CLOSE_SCHEDULE_DIALOG
    }
}

