import { OFFERS_STATUS } from '../../components/Common/constants';
import { CREATE_OFFER_DONE, GET_OFFER_DONE, UPDATE_OFFER_DONE,GET_OFFERS_BY_STATUS_DONE,GET_PRODUCTS_BY_STORE_ID_DONE,
    UPDATE_OFFER_BY_STATUS_DONE, GET_CATEGORY_LIST_BY_STORE_ID_DONE, GET_SUB_CATEGORY_LIST_BY_STORE_ID_DONE,
    GET_SUB_SUB_CATEGORY_LIST_BY_STORE_ID_DONE, GET_CITIES_DONE, GET_OFFER_ERROR,
    OPEN_NEW_OFFER_DIALOG, OPEN_EDIT_OFFER_DIALOG, CLOSE_OFFER_DIALOG} from '../actions/offer.actions';

const initialState = {
    new_offers:[],
    active_offers:[],
    inactive_offers:[],
    expired_offers:[],

    new_offers_cnt: 0,
    active_offers_cnt: 0,
    inactive_offers_cnt: 0,
    expired_offers_cnt: 0,

    detailOffer:{},
    products:[],
    categoryList: [],
    subCategoryList: [],
    subSubCategoryList: [],
    cities: [],
    err_offer:null,
    msg:'',
    offerDialog     : {
        type : 'new',
        props: {
            open: false
        },
        data : null
    }  

}

const offerReducer = (state = initialState, action) => {    
    switch (action.type) {
        case CREATE_OFFER_DONE:
        {
            let newOffer = action.payload.data;

            let itemsNew = [...state.new_offers];
            itemsNew = [newOffer, ...itemsNew];

            let newOffersCnt = state.new_offers_cnt;
            newOffersCnt++;

            return {
                ...state,
                new_offers: itemsNew,
                new_offers_cnt: newOffersCnt,
            }
            return {
                ...state,
                msg: action.payload.message
            }           
            break;
        }
        case GET_OFFER_DONE:            
            return {
                ...state,
                detailOffer: action.payload
            }
        
        case GET_OFFERS_BY_STATUS_DONE:
        {
            console.log("Offer_Reducer=>", action)
            let offers_field = "";
            let offers_cnt_field = "";

            let status = action.queryData.status;
            let page_idx = action.queryData.skip;

            let offers = action.payload.data;
            let total_cnt = action.payload.totalCount;

            if (status === OFFERS_STATUS.NEW) 
            {
                offers_field = 'new_offers';
                offers_cnt_field = "new_offers_cnt";
            } 
            else if ( status === OFFERS_STATUS.ACTIVE ) 
            {
                offers_field = 'active_offers';
                offers_cnt_field = "active_offers_cnt";
            }
            else if ( status === OFFERS_STATUS.IN_ACTIVE ) 
            {
                offers_field = 'inactive_offers';
                offers_cnt_field = "inactive_offers_cnt";
            }
            else 
            {
                offers_field = 'expired_offers';
                offers_cnt_field = "expired_offers_cnt";
            }
            return {
                ...state,
                [offers_field]: offers || [],
                [offers_cnt_field]: total_cnt || 0,
            }            
        }
        case GET_PRODUCTS_BY_STORE_ID_DONE:
            return {
                ...state,
                products: action.payload
            }    
        case UPDATE_OFFER_DONE:    {
            let updateOffer = action.payload;

            let itemsNew = [...state.new_offers];
            let itemsActive = [...state.active_offers];
            let itemsInactive = [...state.inactive_offers];
            let itemsExpire = [...state.expired_offers];
            
            if (updateOffer.status === OFFERS_STATUS.NEW) {
                itemsNew = itemsNew.map((_offer) => {
                    if ( _offer.offerId === updateOffer.offerId )
                    {
                        return updateOffer;
                    }
                    return _offer;
                });
            } else if(updateOffer.status === OFFERS_STATUS.ACTIVE) {
                itemsActive = itemsActive.map((_offer) => {
                    if ( _offer.offerId === updateOffer.offerId )
                    {
                        return updateOffer;
                    }
                    return _offer;
                });
            } else if(updateOffer.status === OFFERS_STATUS.IN_ACTIVE) {
                itemsInactive = itemsInactive.map((_offer) => {
                    if ( _offer.offerId === updateOffer.offerId )
                    {
                        return updateOffer;
                    }
                    return _offer;
                });
            } else if(updateOffer.status === OFFERS_STATUS.EXPIRED) {
                itemsExpire = itemsExpire.map((_offer) => {
                    if ( _offer.offerId === updateOffer.offerId )
                    {
                        return updateOffer;
                    }
                    return _offer;
                });
            } 

            return {
                ...state,
                new_offers: itemsNew,
                active_offers: itemsActive,
                inactive_offers: itemsInactive,
                expired_offers: itemsExpire
            }
        }        
        case UPDATE_OFFER_BY_STATUS_DONE:
            let updateOffer = action.queryData.data;

            let itemsNew = [...state.new_offers];
            let itemsActive = [...state.active_offers];
            let itemsInactive = [...state.inactive_offers];
            let itemsExpire = [...state.expired_offers];

            let new_offers_cnt = state.new_offers_cnt;
            let active_offers_cnt = state.active_offers_cnt;
            let inactive_offers_cnt = state.inactive_offers_cnt;
            let expired_offers_cnt = state.expired_offers_cnt;
            
            if (updateOffer.status === OFFERS_STATUS.NEW) {
                let isFound = itemsNew.find((_offer) => {return _offer.offerId === updateOffer.offerId});
                // If status is updated
                if (!isFound) {
                    // isFound = itemsActive.find((_offer) => {return _offer.offerId === updateOffer.offerId});
                    // if ( isFound ){
                    //     let items = itemsActive.filter(_offer => updateOffer.offerId === _offer.offerId);
                    //     let item = items[0]
                    //     item.status = OFFERS_STATUS.NEW
                    //     itemsNew = [item, ...itemsNew]                        
                    // }
                    // itemsActive =  itemsActive.filter(_offer => updateOffer.offerId !== _offer.offerId);

                    // isFound = itemsInactive.find((_offer) => {return _offer.offerId === updateOffer.offerId});
                    // if ( isFound ){
                    //     let items = itemsInactive.filter(_offer => updateOffer.offerId === _offer.offerId);
                    //     let item = items[0]
                    //     item.status = OFFERS_STATUS.NEW
                    //     itemsNew = [item, ...itemsNew]                        
                    // }
                    // itemsInactive =  itemsInactive.filter(_offer => updateOffer.offerId !== _offer.offerId);

                    // isFound = itemsExpire.find((_offer) => {return _offer.offerId === updateOffer.offerId});
                    // if ( isFound ){
                    //     let items = itemsExpire.filter(_offer => updateOffer.offerId === _offer.offerId);
                    //     let item = items[0]
                    //     item.status = OFFERS_STATUS.NEW
                    //     itemsNew = [item, ...itemsNew]                        
                    // }
                    // itemsExpire =  itemsExpire.filter(_offer => updateOffer.offerId !== _offer.offerId);
                }else{
                    itemsNew = itemsNew.map((_offer) => {
                        if ( _offer.offerId === updateOffer.offerId )
                        {
                            return updateOffer;
                        }
                        return _offer;
                    });
                }
                return {
                    ...state,
                    new_offers: itemsNew,
                    active_offers: itemsActive,
                    inactive_offers: itemsInactive,
                    expired_offers: itemsExpire
                }

            } else if (updateOffer.status === OFFERS_STATUS.ACTIVE) {
                let isFound = itemsActive.find((_offer) => {return _offer.offerId === updateOffer.offerId});
                // If status is updated
                if (!isFound) {
                    isFound = itemsNew.find((_offer) => {return _offer.offerId === updateOffer.offerId});
                    if ( isFound ){
                        let items = itemsNew.filter(_offer => updateOffer.offerId === _offer.offerId);
                        let item = items[0]
                        item.status = OFFERS_STATUS.ACTIVE
                        itemsActive = [item, ...itemsActive]
                    }
                    itemsNew =  itemsNew.filter(_offer => updateOffer.offerId !== _offer.offerId);

                    isFound = itemsInactive.find((_offer) => {return _offer.offerId === updateOffer.offerId});
                    if ( isFound ){
                        let items = itemsInactive.filter(_offer => updateOffer.offerId === _offer.offerId);
                        let item = items[0]
                        item.status = OFFERS_STATUS.ACTIVE
                        itemsActive = [item, ...itemsActive]                        
                    }
                    itemsInactive =  itemsInactive.filter(_offer => updateOffer.offerId !== _offer.offerId);

                    isFound = itemsExpire.find((_offer) => {return _offer.offerId === updateOffer.offerId});
                    if ( isFound ){
                        let items = itemsExpire.filter(_offer => updateOffer.offerId === _offer.offerId);
                        let item = items[0]
                        item.status = OFFERS_STATUS.ACTIVE
                        itemsActive = [item, ...itemsActive]                        
                    }
                    itemsExpire =  itemsExpire.filter(_offer => updateOffer.offerId !== _offer.offerId);
                }else{
                    itemsActive = itemsActive.map((_offer) => {
                        if ( _offer.offerId === updateOffer.offerId )
                        {
                            return updateOffer;
                        }
                        return _offer;
                    });
                }
                return {
                    ...state,
                    new_offers: itemsNew,
                    active_offers: itemsActive,
                    inactive_offers: itemsInactive,
                    expired_offers: itemsExpire,
                    
                    new_offers_cnt: itemsNew.length,
                    active_offers_cnt: itemsActive.length,
                    inactive_offers_cnt: itemsInactive.length,
                    expired_offers_cnt: itemsExpire.length
                }

            } else if (updateOffer.status === OFFERS_STATUS.IN_ACTIVE) {
                let isFound = itemsInactive.find((_offer) => {return _offer.offerId === updateOffer.offerId});
                // If status is updated
                if (!isFound) {
                    isFound = itemsNew.find((_offer) => {return _offer.offerId === updateOffer.offerId});
                    if ( isFound ){
                        let items = itemsNew.filter(_offer => updateOffer.offerId === _offer.offerId);
                        let item = items[0]
                        item.status = OFFERS_STATUS.IN_ACTIVE
                        itemsInactive = [item, ...itemsInactive]                        
                    }
                    itemsNew =  itemsNew.filter(_offer => updateOffer.offerId !== _offer.offerId);

                    isFound = itemsActive.find((_offer) => {return _offer.offerId === updateOffer.offerId});
                    if ( isFound ){
                        let items = itemsActive.filter(_offer => updateOffer.offerId === _offer.offerId);
                        let item = items[0]
                        item.status = OFFERS_STATUS.IN_ACTIVE
                        itemsInactive = [item, ...itemsInactive]                        
                    }
                    itemsActive =  itemsActive.filter(_offer => updateOffer.offerId !== _offer.offerId);

                    isFound = itemsExpire.find((_offer) => {return _offer.offerId === updateOffer.offerId});
                    if ( isFound ){
                        let items = itemsExpire.filter(_offer => updateOffer.offerId === _offer.offerId);
                        let item = items[0]
                        item.status = OFFERS_STATUS.IN_ACTIVE
                        itemsInactive = [item, ...itemsInactive]                        
                    }
                    itemsExpire =  itemsExpire.filter(_offer => updateOffer.offerId !== _offer.offerId);
                }else{
                    itemsInactive = itemsInactive.map((_offer) => {
                        if ( _offer.offerId === updateOffer.offerId )
                        {
                            return updateOffer;
                        }
                        return _offer;
                    });
                }
                return {
                    ...state,
                    new_offers: itemsNew,
                    active_offers: itemsActive,
                    inactive_offers: itemsInactive,
                    expired_offers: itemsExpire,

                    new_offers_cnt: itemsNew.length,
                    active_offers_cnt: itemsActive.length,
                    inactive_offers_cnt: itemsInactive.length,
                    expired_offers_cnt: itemsExpire.length
                }

            } else if (updateOffer.status === OFFERS_STATUS.EXPIRED) {
               
                let isFound = itemsExpire.find((_offer) => {return _offer.offerId === updateOffer.offerId});
                // If status is updated
                if (!isFound) {
                    isFound = itemsNew.find((_offer) => {return _offer.offerId === updateOffer.offerId});
                    if ( isFound ){
                        let items = itemsNew.filter(_offer => updateOffer.offerId === _offer.offerId);
                        let item = items[0]
                        item.status = OFFERS_STATUS.EXPIRED
                        itemsExpire = [item, ...itemsExpire]                        
                    }
                    itemsNew =  itemsNew.filter(_offer => updateOffer.offerId !== _offer.offerId);

                    isFound = itemsActive.find((_offer) => {return _offer.offerId === updateOffer.offerId});
                    if ( isFound ){
                        let items = itemsActive.filter(_offer => updateOffer.offerId === _offer.offerId);
                        let item = items[0]
                        item.status = OFFERS_STATUS.EXPIRED
                        itemsExpire = [item, ...itemsExpire]                        
                    }
                    itemsActive =  itemsActive.filter(_offer => updateOffer.offerId !== _offer.offerId);

                    isFound = itemsInactive.find((_offer) => {return _offer.offerId === updateOffer.offerId});
                    if ( isFound ){
                        let items = itemsInactive.filter(_offer => updateOffer.offerId === _offer.offerId);
                        let item = items[0]
                        item.status = OFFERS_STATUS.EXPIRED
                        itemsExpire = [item, ...itemsExpire]                        
                    }
                    itemsInactive =  itemsInactive.filter(_offer => updateOffer.offerId !== _offer.offerId);
                }else{
                    itemsExpire = itemsExpire.map((_offer) => {
                        if ( _offer.offerId === updateOffer.offerId )
                        {
                            return updateOffer;
                        }
                        return _offer;
                    });
                }
                return {
                    ...state,
                    new_offers: itemsNew,
                    active_offers: itemsActive,
                    inactive_offers: itemsInactive,
                    expired_offers: itemsExpire
                }

            }           
            break;  
        case GET_CATEGORY_LIST_BY_STORE_ID_DONE:
            return {
                ...state,
                categoryList: action.payload
            };
            
        case GET_SUB_CATEGORY_LIST_BY_STORE_ID_DONE:
            return {
                ...state,
                subCategoryList: action.payload
            };
        case GET_SUB_SUB_CATEGORY_LIST_BY_STORE_ID_DONE:
            return {
                ...state,
                subSubCategoryList: action.payload
            };
                
        case GET_CITIES_DONE:
            return {
                ...state,
                cities: action.payload
            };
        case GET_OFFER_ERROR:
            return {
                ...state,
                err_offer: action.payload
            };
        case OPEN_NEW_OFFER_DIALOG:
            // let status = action.payload.status;

            return {
                ...state,
                offerDialog: {
                    type : 'new',
                    props: {
                        open: true,
                        status: 0
                    }
                }
            };
            
        case OPEN_EDIT_OFFER_DIALOG:
            return {
                ...state,
                offerDialog: {
                    type : 'edit',
                    props: {
                        open: true
                    },
                    data : action.data
                }
            };
        case CLOSE_OFFER_DIALOG:
            return {
                ...state,
                offerDialog: {
                    type : 'new',
                    props: {
                        open: false
                    },
                    data : null
                }
            };
        default:
            return state;
    }
}

export default offerReducer;