export const DISPATCH_ORDER = 'DISPATCH_ORDER';
export const UPDATE_ORDER_STATUS = 'UPDATE_ORDER_STATUS';
export const COMPLETE_ORDER = 'COMPLETE_ORDER';
export const GET_ORDERS = 'GET_ORDERS';
export const GOT_ERROR_ORDER = 'GOT_ERROR_MANAGER';

export const DISPATCH_ORDER_DONE = 'DISPATCH_ORDER_DONE';
export const UPDATE_ORDER_STATUS_DONE = 'UPDATE_ORDER_STATUS_DONE';
export const COMPLETE_ORDER_DONE = 'COMPLETE_ORDER_DONE';
export const GET_ORDERS_DONE = 'GET_ORDERS_DONE';

export const OPEN_NEW_ORDER_DIALOG = 'OPEN_NEW_ORDER_DIALOG';
export const OPEN_EDIT_ORDER_DIALOG = 'OPEN_EDIT_ORDER_DIALOG';
export const CLOSE_ORDER_DIALOG = 'CLOSE_ORDER_DIALOG';

export const UPDATE_EXPIRED_STATUS = "UPDATE_EXPIRED_STATUS";
/**
 * Get active schedule list
 */
export function dispatchOrder(order) {    
    return { type: DISPATCH_ORDER, data:order };
}

/**
 * Create Manager
 */
export function updateOrderStatus( status ) {
    return { type: UPDATE_ORDER_STATUS, data:status };
}

/**
 * Update Manager
 */
export function completeOrder( orderId, userId ) {
    return { type: COMPLETE_ORDER, data:orderId, userId };
}

/**
 * Delete Manager
 */
export function getOrders( queryData ) {
    return { type: GET_ORDERS, data:queryData };
}

export function openNewOrderDialog( status )
{
    return {
        type: OPEN_NEW_ORDER_DIALOG, data:status
    }
}

export function openEditOrderDialog(data)
{
    return {
        type: OPEN_EDIT_ORDER_DIALOG, data:data
    }
}

export function closeOrderDialog()
{
    return {
        type: CLOSE_ORDER_DIALOG
    }
}

export function updateExpiredStatus( status )
{
    return {
        type: UPDATE_EXPIRED_STATUS, 
        data: status
    }
}