import axios from 'axios';
import EnvDecode from '../common/EnvDecode';
import { 
    HTTP_ERRORS, 
    isValidToken, 
    AXIOS_AUTH,
    AXIOS_LANGUAGE,
    APP_LANG_KEY
} from '../components/Common/constants';
import FormData from 'form-data';
// const FormData = require('form-data');

const BASE_URL = EnvDecode.getEnvPath(process.env.React_App_API_SERVER_URL);

var ProfileService = {    
    updateProfile (profile) {
        console.log("** Save profile service **", profile)
        delete profile.errors

        return new Promise((resolve, reject) => {     
            if(!isValidToken(axios.defaults.headers.common[AXIOS_AUTH]))
            {
                reject(HTTP_ERRORS.InvalidToken);
            }
            else
            {      
                axios.defaults.headers.common[AXIOS_LANGUAGE] = localStorage.getItem(APP_LANG_KEY) || 'en'; 
                axios.patch(`${BASE_URL}` + '/franchise/store/profile', profile)
                .then(response => {                    
                    if ( response.status === 200 )
                    {                       
                        resolve(response);                        
                    }
                    else
                    {   
                        reject(response);
                    }
                })
                .catch(err=>{
                    reject(err);
                })
            }
        });
    },
    createAddressWithCocNumber ( coc_number ) {
        
        console.log( 'coc number', coc_number)
        return new Promise((resolve, reject) => {     
            if(!isValidToken(axios.defaults.headers.common[AXIOS_AUTH]))
            {
                reject(HTTP_ERRORS.InvalidToken);
            }
            else
            {       
                axios.post(`${BASE_URL}/createAddressWithCocNumber`, {"cocNumber": coc_number} )
                .then(response => {     
                    if ( response.status === 200 )
                    {                       
                        resolve(response);                        
                    }
                    else
                    {   
                        reject(response);
                    }
                })
                .catch(err=>{
                    reject(err);
                })
            }
        });
    },
    createMSPSignup ( req ) {
        
        console.log( 'createMSPSignup serviec called', req , `${BASE_URL}'/multisafe/account/create` )
        return new Promise((resolve, reject) => {     
            if(!isValidToken(axios.defaults.headers.common[AXIOS_AUTH]))
            {
                reject(HTTP_ERRORS.InvalidToken);
            }
            else
            {       
                axios.post(`${BASE_URL}/multisafe/account/create`, req)
                .then(response => {     
                    if ( response.status === 200 )
                    {                       
                        resolve(response);                        
                    }
                    else
                    {   
                        reject(response);
                    }
                })
                .catch(err=>{
                    reject(err);
                })
            }
        });
    },
    createMSPBank ( req ) {
        
        return new Promise((resolve, reject) => {     
            if(!isValidToken(axios.defaults.headers.common[AXIOS_AUTH]))
            {
                reject(HTTP_ERRORS.InvalidToken);
            }
            else
            {       
                axios.post(`${BASE_URL}/multisafe/bank/create`, req)
                .then(response => {                    
                    if ( response.status === 200 )
                    {                       
                        resolve(response);                        
                    }
                    else
                    {   
                        reject(response);
                    }
                })
                .catch(err=>{
                    reject(err);
                })
            }
        });
    },
    createMSPubo ( req ) {
        
        return new Promise((resolve, reject) => {     
            if(!isValidToken(axios.defaults.headers.common[AXIOS_AUTH]))
            {
                reject(HTTP_ERRORS.InvalidToken);
            }
            else
            {       
                axios.post(`${BASE_URL}/multisafe/ubo/create`, req)
                .then(response => {                    
                    if ( response.status === 200 )
                    {                       
                        resolve(response);                        
                    }
                    else
                    {   
                        reject(response);
                    }
                })
                .catch(err=>{
                    reject(err);
                })
            }
        });
    },
    createMSPWebsite ( req ) {
        
        return new Promise((resolve, reject) => {     
            if(!isValidToken(axios.defaults.headers.common[AXIOS_AUTH]))
            {
                reject(HTTP_ERRORS.InvalidToken);
            }
            else
            {       
                axios.post(`${BASE_URL}/multisafe/website/create`, req)
                .then(response => {                    
                    if ( response.status === 200 )
                    {                       
                        resolve(response);                        
                    }
                    else
                    {   
                        reject(response);
                    }
                })
                .catch(err=>{
                    reject(err);
                })
            }
        });
    },

    getProfile () {
        return new Promise((resolve, reject) => {     
            //console.log("VALIDATE =>", isValidToken(axios.defaults.headers.common[AXIOS_AUTH]), axios.defaults.headers.common[AXIOS_AUTH])
            if(!isValidToken(axios.defaults.headers.common[AXIOS_AUTH]))
            {
                reject(HTTP_ERRORS.InvalidToken);
            }
            else
            {
                axios.defaults.headers.common[AXIOS_LANGUAGE] = localStorage.getItem(APP_LANG_KEY) || 'en';
                axios.get(`${BASE_URL}` + '/franchise/store/profile')
                .then(response => {                
                    if ( response.status === 200 )
                    {                       
                        resolve(response.data);
                    }
                    else
                    {   
                        reject(response.data);
                    }
                })
                .catch(err => {
                    reject(err);
                })
            }
        });
    },

    getZones( data ) {
        return new Promise((resolve, reject) => {        
            if(!isValidToken(axios.defaults.headers.common[AXIOS_AUTH]))
            {
                reject(HTTP_ERRORS.InvalidToken);
            }
            else
            {
                axios.defaults.headers.common[AXIOS_LANGUAGE] = localStorage.getItem(APP_LANG_KEY) || 'en';
                axios.get(`${BASE_URL}` + '/franchise/store/profile/zones/' + data.cityId )
                .then(response => {                
                    if ( response.status === 200 )
                    {                       
                        resolve(response.data);                        
                    }
                    else
                    {   
                        reject(response.data);
                    }
                })
                .catch( err => {
                    reject(err);
                })
            }
        });
    },
    
    getAllCities() {
        return new Promise((resolve, reject) => {
            axios.defaults.headers.common[AXIOS_LANGUAGE] = localStorage.getItem(APP_LANG_KEY) || 'en';
            axios.get(`${BASE_URL}` + '/admin/city')
            .then(response => {                
                if ( response.status === 200 )
                {                       
                    resolve(response.data);                        
                }
                else
                {   
                    reject(response.data);
                }
            })
            .catch(err=>{
                if (err.response && err.response.status === 404) 
                {
                    reject(Error('Not found'))
                } 
                else 
                {
                    if (err.response) 
                    {
                        let errMsg = JSON.parse(err.response.data);                
                        reject(errMsg);
                    } 
                    else 
                    {
                        reject(JSON.parse(JSON.stringify(err)));
                    }                    
                }
            })
        });
    },
    
    async uploadImage( data ) {
        return new Promise((resolve, reject) => {      
            if(!isValidToken(axios.defaults.headers.common[AXIOS_AUTH]))
            {
                reject(HTTP_ERRORS.InvalidToken);
            }
            else
            {  
                const formData = new FormData();
                console.log('data->', data)
                formData.append("image", data.file)
                formData.append("type", data.type)
                formData.append("res", 'object')
                console.log("Updated Request Data11 =>", formData)
                // let headers = {
                //     'Content-Type':'multipart/form-data',
                // }
                console.log("Updated Request Data22 =>", formData)
                axios.defaults.headers.common[AXIOS_LANGUAGE] = localStorage.getItem(APP_LANG_KEY) || 'en';
                axios.post(`${BASE_URL}/utility/uploadImage`, formData
                )
                .then(response => {          
                    console.log('api response-success', response)      
                    if ( response.status === 200 )
                    {                       
                        resolve(response.data);                        
                    }
                    else
                    {   
                        reject(response.data);
                    }
                })
                .catch(err=>{
                    console.log('api response-err', err)
                    if (err.response && err.response.status === 404) {
                        reject(Error('Not found'))
                    } else {
                        if (err.response) {
                            let errMsg = JSON.parse(err.response.data);                
                            reject(errMsg);
                        } else {
                            reject(JSON.parse(JSON.stringify(err)));
                        }                    
                    }                 
                })
            }
        });
    }
}

export default ProfileService;