import axios from "axios";
import _ from "lodash";
import EnvDecode from "../common/EnvDecode";
import {
  HTTP_ERRORS,
  isValidToken,
  AXIOS_AUTH,
  AXIOS_LANGUAGE,
  APP_LANG_KEY,
} from "../components/Common/constants";

const BASE_URL = EnvDecode.getEnvPath(process.env.React_App_API_SERVER_URL);

var CategoryService = {
  createCategory(category) {
    return new Promise((resolve, reject) => {
      if (!isValidToken(axios.defaults.headers.common[AXIOS_AUTH])) {
        reject(HTTP_ERRORS.InvalidToken);
      } else {
        axios.defaults.headers.common[AXIOS_LANGUAGE] =
          localStorage.getItem(APP_LANG_KEY) || "en";
        axios
          .post(`${BASE_URL}` + "/api/storefirstCategory/create", category)
          .then((response) => {
            if (response.status === 200) {
              resolve(response.data);
            } else {
              reject(response.data);
            }
          })
          .catch((err) => {
            reject(err);
          });
      }
    });
  },

  createSubCategory(category) {
    return new Promise((resolve, reject) => {
      if (!isValidToken(axios.defaults.headers.common[AXIOS_AUTH])) {
        reject(HTTP_ERRORS.InvalidToken);
      } else {
        axios.defaults.headers.common[AXIOS_LANGUAGE] =
          localStorage.getItem(APP_LANG_KEY) || "en";
        axios
          .post(`${BASE_URL}` + "/api/storeSecondCategory/create", category)
          .then((response) => {
            if (response.status === 200) {
              resolve(response.data);
            } else {
              reject(response.data);
            }
          })
          .catch((err) => {
            reject(err);
          });
      }
    });
  },

  createSubSubCategory(category) {
    return new Promise((resolve, reject) => {
      if (!isValidToken(axios.defaults.headers.common[AXIOS_AUTH])) {
        reject(HTTP_ERRORS.InvalidToken);
      } else {
        axios.defaults.headers.common[AXIOS_LANGUAGE] =
          localStorage.getItem(APP_LANG_KEY) || "en";
        axios
          .post(`${BASE_URL}` + "/api/storeThirdCategory/create", category)
          .then((response) => {
            if (response.status === 200) {
              resolve(response.data);
            } else {
              reject(response.data);
            }
          })
          .catch((err) => {
            reject(err);
          });
      }
    });
  },

  updateCategory(category) {
    let categoryId = category._id;
    // delete category._id

    let req = {
      categoryId: categoryId,
      data: category,
    };

    return new Promise((resolve, reject) => {
      if (!isValidToken(axios.defaults.headers.common[AXIOS_AUTH])) {
        reject(HTTP_ERRORS.InvalidToken);
      } else {
        axios.defaults.headers.common[AXIOS_LANGUAGE] =
          localStorage.getItem(APP_LANG_KEY) || "en";
        axios
          .patch(`${BASE_URL}` + "/api/storefirstCategory/update", req)
          .then((response) => {
            if (response.status === 200) {
              resolve(response.data);
            } else {
              reject(response.data);
            }
          })
          .catch((err) => {
            const errorMessage = _.get(err, "response.data.message", "");
            reject({
              ...err,
              message: errorMessage || err.message,
            });
          });
      }
    });
  },
  updateCategorySorting(data) {
    let req = {
      data: data,
    };

    return new Promise((resolve, reject) => {
      if (!isValidToken(axios.defaults.headers.common[AXIOS_AUTH])) {
        reject(HTTP_ERRORS.InvalidToken);
      } else {
        axios.defaults.headers.common[AXIOS_LANGUAGE] =
          localStorage.getItem(APP_LANG_KEY) || "en";
        axios
          .patch(`${BASE_URL}` + "/api/storefirstCategory/sortUpdate", req)
          .then((response) => {
            if (response.status === 200) {
              resolve(response.data);
            } else {
              reject(response.data);
            }
          })
          .catch((err) => {
            reject(err);
          });
      }
    });
  },

  deleteCategory(id) {
    return new Promise((resolve, reject) => {
      if (!isValidToken(axios.defaults.headers.common[AXIOS_AUTH])) {
        reject(HTTP_ERRORS.InvalidToken);
      } else {
        axios.defaults.headers.common[AXIOS_LANGUAGE] =
          localStorage.getItem(APP_LANG_KEY) || "en";
        axios
          .delete(`${BASE_URL}` + "/api/storefirstCategory/delete/" + id)
          .then((response) => {
            if (response.status === 200) {
              resolve(response.data);
            } else {
              reject(response.data);
            }
          })
          .catch((err) => {
            reject(err);
          });
      }
    });
  },

  getCategories(queryData) {
    let url = "";
    let pageNum = queryData.pageNum;
    let query = queryData.query === "" ? 0 : queryData.query;

    url =
      `${BASE_URL}` +
      "/api/storefirstCategory/getByStatusWithQuery/" +
      queryData.storeId +
      "/" +
      queryData.status +
      "/" +
      pageNum +
      "/" +
      query;

    return new Promise((resolve, reject) => {
      if (!isValidToken(axios.defaults.headers.common[AXIOS_AUTH])) {
        reject(HTTP_ERRORS.InvalidToken);
      } else {
        axios.defaults.headers.common[AXIOS_LANGUAGE] =
          localStorage.getItem(APP_LANG_KEY) || "en";
        axios
          .get(url)
          .then((response) => {
            if (response.status === 200) {
              resolve(response.data);
            } else {
              reject(response.data);
            }
          })
          .catch((err) => {
            reject(err);
          });
      }
    });
  },

  getAllCategories(queryData) {
    let url = "";
    url =
      `${BASE_URL}` +
      "/api/storefirstCategory/getAllWithQuery/" +
      queryData.storeId;

    return new Promise((resolve, reject) => {
      if (!isValidToken(axios.defaults.headers.common[AXIOS_AUTH])) {
        reject(HTTP_ERRORS.InvalidToken);
      } else {
        axios.defaults.headers.common[AXIOS_LANGUAGE] =
          localStorage.getItem(APP_LANG_KEY) || "en";
        axios
          .get(url)
          .then((response) => {
            if (response.status === 200) {
              resolve(response.data);
            } else {
              reject(response.data);
            }
          })
          .catch((err) => {
            reject(err);
          });
      }
    });
  },

  updateStoreCategory(data) {
    let url = "";
    url = `${BASE_URL}` + "/store/update/categorires";

    return new Promise((resolve, reject) => {
      if (!isValidToken(axios.defaults.headers.common[AXIOS_AUTH])) {
        reject(HTTP_ERRORS.InvalidToken);
      } else {
        axios.defaults.headers.common[AXIOS_LANGUAGE] =
          localStorage.getItem(APP_LANG_KEY) || "en";
        axios
          .patch(url, data)
          .then((response) => {
            if (response.status === 200) {
              resolve(response.data);
            } else {
              reject(response.data);
            }
          })
          .catch((err) => {
            reject(err);
          });
      }
    });
  },

  updateStoreProduct(data) {
    let url = "";
    url = `${BASE_URL}` + "/updateToStoreProduct";

    return new Promise((resolve, reject) => {
      if (!isValidToken(axios.defaults.headers.common[AXIOS_AUTH])) {
        reject(HTTP_ERRORS.InvalidToken);
      } else {
        axios.defaults.headers.common[AXIOS_LANGUAGE] =
          localStorage.getItem(APP_LANG_KEY) || "en";
        axios
          .patch(url, data)
          .then((response) => {
            if (response.status === 200) {
              resolve(response.data);
            } else {
              reject(response.data);
            }
          })
          .catch((err) => {
            reject(err);
          });
      }
    });
  },

  getSubCategories(data) {
    let url = `${BASE_URL}` + "/api/storeSecondCategory/" + data.catId;

    return new Promise((resolve, reject) => {
      if (!isValidToken(axios.defaults.headers.common[AXIOS_AUTH])) {
        reject(HTTP_ERRORS.InvalidToken);
      } else {
        axios.defaults.headers.common[AXIOS_LANGUAGE] =
          localStorage.getItem(APP_LANG_KEY) || "en";
        axios
          .get(url)
          .then((response) => {
            if (response.status === 200) {
              resolve(response.data);
            } else {
              reject(response.data);
            }
          })
          .catch((err) => {
            reject(err);
          });
      }
    });
  },

  getSubSubCategories(data) {
    let url =
      `${BASE_URL}` +
      "/api/storeThirdCategory/" +
      data.catId +
      "/" +
      data.subCatId;

    return new Promise((resolve, reject) => {
      if (!isValidToken(axios.defaults.headers.common[AXIOS_AUTH])) {
        reject(HTTP_ERRORS.InvalidToken);
      } else {
        axios.defaults.headers.common[AXIOS_LANGUAGE] =
          localStorage.getItem(APP_LANG_KEY) || "en";
        axios
          .get(url)
          .then((response) => {
            if (response.status === 200) {
              resolve(response.data);
            } else {
              reject(response.data);
            }
          })
          .catch((err) => {
            reject(err);
          });
      }
    });
  },
};

export default CategoryService;
