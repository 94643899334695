import { HTTP_ERRORS } from '../../components/Common/constants';
import CategoryService from '../../services/categoryService';

import {
  GET_CATEGORIES, GET_ALL_CATEGORIES, GET_SUB_CATEGORIES,
  GET_SUB_SUB_CATEGORIES, GET_ALL_CATEGORIES_DONE,
  GET_SUB_CATEGORIES_DONE, GET_SUB_SUB_CATEGORIES_DONE,
  CREATE_CATEGORY, CREATE_SUB_CATEGORY, CREATE_SUB_SUB_CATEGORY,
  UPDATE_CATEGORY, UPDATE_CATEGORY_SORTING, UPDATE_STORE_PRODUCT, UPDATE_STORE_CATEGORY,
  UPDATE_STORE_CATEGORY_DONE, UPDATE_STORE_PRODUCT_DONE,
  DELETE_CATEGORY, GET_CATEGORIES_DONE, UPDATE_CATEGORY_DONE,
  DELETE_CATEGORY_DONE, CREATE_CATEGORY_DONE, CREATE_SUB_CATEGORY_DONE,
  CREATE_SUB_SUB_CATEGORY_DONE, GOT_ERROR_CATEGORY,
  INVALID_TOKEN_ERROR, UPDATE_CATEGORY_SORTING_DONE
} from '../actions/actions';

const category = store => next => action => {
    let dispatch = store.dispatch;
    if (action.type === GET_CATEGORIES) {       
      // Make an API call to login the server            
      CategoryService.getCategories(action.queryData).then(result => {        
        dispatch({ type: GET_CATEGORIES_DONE, payload: result, queryData:action.queryData });
      })
      .catch(err=>{ 
        if(err === HTTP_ERRORS.InvalidToken)
        {
          dispatch({ type: INVALID_TOKEN_ERROR, payload: err });
        }
        else
        {
          dispatch({ type: GOT_ERROR_CATEGORY, payload: err })
        }
      })
    } else if (action.type === GET_ALL_CATEGORIES ) {       
      // Make an API call to login the server            
      CategoryService.getAllCategories(action.queryData).then(result => {        
        dispatch({ type: GET_ALL_CATEGORIES_DONE, payload: result, queryData:action.queryData });
      })
      .catch(err=>{
        if(err === HTTP_ERRORS.InvalidToken)
        {
          dispatch({ type: INVALID_TOKEN_ERROR, payload: err });
        }
        else
        {
          dispatch({ type: GOT_ERROR_CATEGORY, payload: err })
        }
      })
    } else if (action.type === GET_SUB_CATEGORIES) {       
      // Make an API call to login the server            
      CategoryService.getSubCategories(action.queryData).then(result => {        
        dispatch({ type: GET_SUB_CATEGORIES_DONE, payload: result, queryData:action.queryData });
      })
      .catch(err=>{
        if(err === HTTP_ERRORS.InvalidToken)
        {
          dispatch({ type: INVALID_TOKEN_ERROR, payload: err });
        }
        else
        {
          dispatch({ type: GOT_ERROR_CATEGORY, payload: err })
        }
      })
    } else if (action.type === GET_SUB_SUB_CATEGORIES) {       
      // Make an API call to login the server            
      CategoryService.getSubSubCategories(action.queryData).then(result => {        
        dispatch({ type: GET_SUB_SUB_CATEGORIES_DONE, payload: result, queryData:action.queryData });
      })
      .catch(err=>{
        if(err === HTTP_ERRORS.InvalidToken)
        {
          dispatch({ type: INVALID_TOKEN_ERROR, payload: err });
        }
        else
        {
          dispatch({ type: GOT_ERROR_CATEGORY, payload: err })
        }
      })
    } else if (action.type === CREATE_CATEGORY) {      
      CategoryService.createCategory(action.data).then(result => {
        dispatch({ type: CREATE_CATEGORY_DONE, payload:result });
      })
      .catch(err=>{
        if(err === HTTP_ERRORS.InvalidToken)
        {
          dispatch({ type: INVALID_TOKEN_ERROR, payload: err });
        }
        else
        {
          dispatch({ type: GOT_ERROR_CATEGORY, payload: err })
        }
      })
    } else if (action.type === CREATE_SUB_CATEGORY) {      
      CategoryService.createSubCategory(action.data).then(result => {
        dispatch({ type: CREATE_SUB_CATEGORY_DONE, payload:result });
      })
      .catch(err=>{
        if(err === HTTP_ERRORS.InvalidToken)
        {
          dispatch({ type: INVALID_TOKEN_ERROR, payload: err });
        }
        else
        {
          dispatch({ type: GOT_ERROR_CATEGORY, payload: err })
        }
      })
    } else if (action.type === CREATE_SUB_SUB_CATEGORY) {      
      CategoryService.createSubSubCategory(action.data).then(result => {
        dispatch({ type: CREATE_SUB_SUB_CATEGORY_DONE, payload:result });
      })
      .catch(err=>{
        if(err === HTTP_ERRORS.InvalidToken)
        {
          dispatch({ type: INVALID_TOKEN_ERROR, payload: err });
        }
        else
        { 
          dispatch({ type: GOT_ERROR_CATEGORY, payload: err })
        }
      })
    } else if (action.type === UPDATE_CATEGORY) {
      
      let reqId = action.data._id
      CategoryService.updateCategory(action.data).then(result => {    
        dispatch({ type: UPDATE_CATEGORY_DONE, payload: { _id: reqId, data: action.data } });        
      })
      .catch(err=>{
        if(err === HTTP_ERRORS.InvalidToken)
        {
          dispatch({ type: INVALID_TOKEN_ERROR, payload: err });
        }
        else
        { 
          dispatch({ type: GOT_ERROR_CATEGORY, payload: err })
        }
      })      
    } else if (action.type === UPDATE_CATEGORY_SORTING) {

      let reqId = action.data._id
      CategoryService.updateCategorySorting(action.data).then(result => {
        dispatch({ type: UPDATE_CATEGORY_SORTING_DONE, payload: result });
      })
          .catch(err=>{
            if(err === HTTP_ERRORS.InvalidToken)
            {
              dispatch({ type: INVALID_TOKEN_ERROR, payload: err });
            }
            else
            {
              dispatch({ type: GOT_ERROR_CATEGORY, payload: err })
            }
          })
    } else if (action.type === UPDATE_STORE_CATEGORY) {
      CategoryService.updateStoreCategory(action.data).then(result => {        
        dispatch({ type: UPDATE_STORE_CATEGORY_DONE, payload:action.data });        
      })
      .catch(err=>{
        if(err === HTTP_ERRORS.InvalidToken)
        {
          dispatch({ type: INVALID_TOKEN_ERROR, payload: err });
        }
        else
        { 
          dispatch({ type: GOT_ERROR_CATEGORY, payload: err })
        }
      })      
    } else if (action.type === UPDATE_STORE_PRODUCT) {
      CategoryService.updateStoreProduct(action.data).then(result => {        
        dispatch({ type: UPDATE_STORE_PRODUCT_DONE, payload:action.data });        
      })
      .catch(err=>{
        if(err === HTTP_ERRORS.InvalidToken)
        {
          dispatch({ type: INVALID_TOKEN_ERROR, payload: err });
        }
        else
        { 
          dispatch({ type: GOT_ERROR_CATEGORY, payload: err })
        }
      })      
    }  
    else if (action.type === DELETE_CATEGORY) {  
      CategoryService.deleteCategory(action.data._id).then(result => {       
        dispatch({ type: DELETE_CATEGORY_DONE, payload:action.data });
      })
      .catch(err=>{
        if(err === HTTP_ERRORS.InvalidToken)
        {
          dispatch({ type: INVALID_TOKEN_ERROR, payload: err });
        }
        else
        { 
          dispatch({ type: GOT_ERROR_CATEGORY, payload: err })
        }
      }) 
    }
    
    return next(action)
  }

export default category;