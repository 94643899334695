import React, {Component} from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import FormValidator from '../../components/Common/FormValidator.js';
import _ from 'lodash';
import utilService from '../../common/utilService'

class LogoutWarningDialog extends Component {

    render()
    {
        return (
            <Modal                    
                    isOpen={this.props.isOpen}
                    toggle={() => this.props.onClose()}
                    //style={{marginTop:'300px'}}
                    className="modal-dialog-centered w500 font-arial"
                >
                <ModalHeader toggle={() => this.props.onClose()} className="bg-red text-center">
                    <div className="d-flex justify-content-center"> 
                        <h3 className="text-white" data-test="headerTitle">
                            {"Log out"}
                        </h3>
                    </div>
                </ModalHeader>
                <ModalBody 
                    style={{paddingLeft:'100px', paddingRight:'100px', fontSize:'20px', textAlign:'center', lineHeight:'3rem'}}
                >
                    <span>{"Are you sure, you want to log out?"}</span>
                </ModalBody>
                <ModalFooter className="d-flex justify-content-center mb-4" style={{border:0}}>
                    <button className="btn fs-16 btn-cancel" 
                        data-test="btnCancel"
                        type="button"
                        onClick={() => this.props.onClose()}
                    >
                        {utilService.getLangByCode('No')}
                    </button>
                    <button className="btn fs-16 btn-ok" 
                        data-test="btnSubmit"
                        type="submit"
                        onClick={() => this.props.onConfirm()}
                    >
                        {utilService.getLangByCode('Yes')}
                    </button>
                </ModalFooter>
            </Modal>
        );
    }
}

export default LogoutWarningDialog;
