import { HTTP_ERRORS } from '../../components/Common/constants';
import LoginService from '../../services/loginService';
import ProfileService from '../../services/profileService';

import { LOGIN,FORGOT,FORGOT_SUCCESS, PASSWORD_CHANGED ,RESET_PASSWORD, LOGIN_SUCCESS, LOGIN_STOREID, LOGIN_STOREID_SUCCESS, LOGIN_FAIL, LOGOUT, 
    CREATE_BLOCKLED_USER, GET_PROFILE, UPDATE_PROFILE, IMAGE_UPLOAD,
    API_ERROR, GET_PROFILE_DONE, UPDATE_PROFILE_DONE, OPEN_CONFIRM_DIALOG,
    GET_ZONES, GET_ZONES_DONE, IMAGE_UPLOAD_DONE, CHANGE_PASSWORD, 
    GET_All_CITIES, GET_All_CITIES_DONE,
    INVALID_TOKEN_ERROR, LOGOUT_BY_INVALID_TOKEN
  } from '../actions/actions';

const auth = store => next => action => {
    let dispatch = store.dispatch;
    if (action.type === LOGIN) {       
      // Make an API call to login the server
      LoginService.login(action.data.email, action.data.password)
      .then(result => {
        // Dispatch an action with the todos we received
        dispatch({ type: LOGIN_SUCCESS, payload: result });
      })
      .catch(err=>{
        dispatch({ type: LOGIN_FAIL, payload: err })
      })
    } 
    else if (action.type === LOGIN_STOREID ) {
      // Make an API call to login the server
      LoginService.loginWithStoreId(action.data.storeId, action.data.superToken, action.data.fcmUserTopic)
      .then(result => {
        // Dispatch an action with the todos we received
        dispatch({ type: LOGIN_SUCCESS, payload: result });
      })
      .catch(err=>{
        dispatch({ type: LOGIN_FAIL, payload: err })
      })
    } 
    else if (action.type === LOGIN_STOREID_SUCCESS ) {

    } 
    else if (action.type === LOGIN_SUCCESS) {
      //console.log('login success', action.payload)
      LoginService.setSession(action.payload.data.authToken);
      // dispatch({ type: GET_PROFILE});
    }
    else if (action.type === LOGOUT)
    {      
      LoginService.setSession(null);
    } 
    else if (action.type === LOGOUT_BY_INVALID_TOKEN )
    {
      LoginService.replaceSession();
    }
    else if ( action.type === CREATE_BLOCKLED_USER ){
      LoginService.createBlockedUser(action.data ).then( result => {
        //console.log( 'create blocked user =', result )
      })
    } 
    else if (action.type === GET_PROFILE) {
      ProfileService.getProfile().then(result => {
        dispatch({ type: GET_PROFILE_DONE, payload: result });
      })
      .catch(err=>{
        if(err === HTTP_ERRORS.InvalidToken)
        {
          dispatch({ type: INVALID_TOKEN_ERROR, payload: err });
        }
        else
        {
          dispatch({ type: API_ERROR, payload: err });    
        }
      })
    } 
    else if (action.type === GET_ZONES ) {
      ProfileService.getZones(action.data).then(result => {
        dispatch({ type: GET_ZONES_DONE, payload: result });
      })
      .catch(err=>{
        if(err === HTTP_ERRORS.InvalidToken)
        {
          dispatch({ type: INVALID_TOKEN_ERROR, payload: err });
        }
        else
        {
          dispatch({ type: API_ERROR, payload: err });
        }
      })
    } 
    else if (action.type === GET_All_CITIES)
    {
      ProfileService.getAllCities()
      .then(result => {
        dispatch({ type: GET_All_CITIES_DONE, payload: result });
      })
      .catch(err => {
        dispatch({ type: API_ERROR, payload: err });
      })
    }
    else if (action.type === UPDATE_PROFILE) {
      ProfileService.updateProfile(action.data).then(result => {
        dispatch({ type: UPDATE_PROFILE_DONE, payload: result });        
        dispatch({ type: OPEN_CONFIRM_DIALOG, payload: {
            title:'Profile saved', 
            description:"Successfully saved",
            action_type:'saveProfileSuccess', 
            bg_name:'bg-setting',
            dialog_type:'info'
          }
        });
      })
      .catch( err => {
        if(err === HTTP_ERRORS.InvalidToken)
        {
          dispatch({ type: INVALID_TOKEN_ERROR, payload: err });
        }
        else
        {
          dispatch({ type: API_ERROR, payload: err });
          dispatch({ type: OPEN_CONFIRM_DIALOG, payload: {
              title:'Profile save failed', 
              description:err.message,
              action_type:'saveProfileFailed', 
              dialog_type:'error'
            }
          });
        }
      })
    } else if (action.type === IMAGE_UPLOAD ) {
      ProfileService.uploadImage(action.data).then(result => {
        dispatch({ type: IMAGE_UPLOAD_DONE, payload: result });
      })
      .catch(err=>{
        if(err === HTTP_ERRORS.InvalidToken)
        {
          dispatch({ type: INVALID_TOKEN_ERROR, payload: err });
        }
        else
        {
          dispatch({ type: API_ERROR, payload: err });             
        }
      })
    } 
    else if (action.type === CHANGE_PASSWORD) {
      LoginService.changePassword(action.data).then (result => {
        dispatch({ 
          type: OPEN_CONFIRM_DIALOG, 
          payload: {
            title:'Changed password', 
            description:"Changed password successfully",
            action_type:'', 
            dialog_type:'info'
          }
        });
      })
      .catch(err => {
        if(err === HTTP_ERRORS.InvalidToken)
        {
          dispatch({ type: INVALID_TOKEN_ERROR, payload: err });
        }
        else
        {
          //dispatch({ type: API_ERROR, payload: err });
          dispatch({ type: OPEN_CONFIRM_DIALOG, payload: {
              title:'Change password failed', 
              description:err.message,
              action_type:'', 
              dialog_type:'error'
            }
          });
        }
      })
    }

    else if (action.type === FORGOT) {
      LoginService.forgotPassword(action.data).then (result => {
        dispatch({ type: FORGOT_SUCCESS, payload: result });  
      })
      .catch(err => {
        if(err === HTTP_ERRORS.InvalidToken)
        {
          dispatch({ type: INVALID_TOKEN_ERROR, payload: err });
        }
        else
        {
          dispatch({ type: API_ERROR, payload: err });
        }
      })
    }

    else if (action.type === RESET_PASSWORD) {
      LoginService.resetPassword(action.data).then (result => {
        dispatch({ type: PASSWORD_CHANGED, payload: result });   
      })
      .catch(err => {
        if(err === HTTP_ERRORS.InvalidToken)
        {
          dispatch({ type: INVALID_TOKEN_ERROR, payload: err });
        }
        else
        {
          dispatch({ type: API_ERROR, payload: err });
        }
      })
    }
  
    return next(action)
  }

export default auth;