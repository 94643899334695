import axios from 'axios';
import EnvDecode from '../common/EnvDecode';
import { HTTP_ERRORS, isValidToken, AXIOS_AUTH, AXIOS_LANGUAGE } from '../components/Common/constants';

const BASE_URL = EnvDecode.getEnvPath(process.env.React_App_OFFER_API_SERVER_URL);

var OfferService = {
    createOffer (offer) {
        console.log( 'Request Offer data =', offer )
        return new Promise((resolve, reject) => {            
            // resolve( resp )
            if(!isValidToken(axios.defaults.headers.common[AXIOS_AUTH]))
            {
                reject(HTTP_ERRORS.InvalidToken);
            }
            else
            {
                // axios.post( `${BASE_URL}` + '/createOffers/', offer)
                // let url = BASE_URL + '/createOffers/'
                // let token = axios.defaults.headers.common[AXIOS_AUTH]
                // fetch( url, {
                //     method:'POST',
                //     headers:{'Content-type':'application/json','Authorization': token},
                //     body:JSON.stringify(offer)
                // })
                // .then(response => {
                //     console.log("CreateOffer Resp=>", response)                
                //     if ( response.status === 200 )
                //     {                       
                //         resolve(response.data);                        
                //     }
                //     else
                //     {   
                //         reject(response.data);
                //     }
                // })
                // .catch(err=>{
                //     reject(err);
                // })
                delete axios.defaults.headers.common[AXIOS_LANGUAGE]
                axios.post( `${BASE_URL}/createOffers/`, offer)
                    .then(response => {   
                        if ( response.status === HTTP_ERRORS.OK )
                        {   
                            console.log("Created offers =>", response.data)
                            resolve(response.data.message);                
                        }
                        else if ( response.status === HTTP_ERRORS.NotFound) 
                        {
                            console.log("Created offers already =>", response.data.message)
                            resolve(response.data.message);                        
                        }
                        else
                        {   
                            reject(response.data);
                        }                    
                    })
                    .catch(err=>{
                        reject(err);
                    })
            }
        });
    },

    getOfferById ( id ) {
        // let resp = {
        //     "data": [
        //         {
        //             "offerId": "6018c2a12e4e03aafea0205b",
        //             "name": {
        //                 "en": "offertest",
        //                 "nl": "offertest"
        //             },
        //             "description": {
        //                 "en": "offertest description",
        //                 "nl": "offertest description"
        //             },
        //             "images": {
        //                 "thumbnail": "https://etenbucket.s3.amazonaws.com/uploadImage/offers/file2021223822.png",
        //                 "mobile": "https://etenbucket.s3.amazonaws.com/uploadImage/offers/file2021223822.png",
        //                 "image": "https://etenbucket.s3.amazonaws.com/uploadImage/offers/file2021223822.png"
        //             },
        //             "franchise": "",
        //             "franchiseName": "",
        //             "city": "5ed78fc3c8405e5132c58ac6",
        //             "cityName": "s-Hertogenbosch",
        //             "storeId": "5ee7379442d54701d560ac00",
        //             "storeName": "Sakana Den Bosch",
        //             "minimumPurchaseQty": 10,
        //             "globalUsageLimit": 1000,
        //             "perUserLimit": 50,
        //             "globalClaimCount": 0,
        //             "products": [
        //                 {
        //                     "productId": "5ef101ab2e3b6d9863edf6f6",
        //                     "productName": "Kani maki",
        //                     "offer": [],
        //                     "firstCategoryId": "5ef101a72e3b6d9863edf6ea",
        //                     "secondCategoryId": "",
        //                     "thirdCategoryId": "",
        //                     "units": [
        //                         {
        //                             "name": {
        //                                 "en": "Kani maki",
        //                                 "nl": "Kani maki"
        //                             },
        //                             "unitId": "5ef101ab2e3b6d9863edf6f5",
        //                             "price": {
        //                                 "en": "1.5",
        //                                 "nl": "1.5"
        //                             },
        //                             "status": "active",
        //                             "barcode": "",
        //                             "addOns": [],
        //                             "barcodeImg": "",
        //                             "floatValue": 1.5,
        //                             "availableQuantity": 1000,
        //                             "claimedQuantity": 0,
        //                             "sizeAttributes": []
        //                         }
        //                     ]
        //                 },
        //                 {
        //                     "productId": "5ef101ad2e3b6d9863edf6f8",
        //                     "productName": "Tori maki",
        //                     "offer": [],
        //                     "firstCategoryId": "5ef101a72e3b6d9863edf6ea",
        //                     "secondCategoryId": "",
        //                     "thirdCategoryId": "",
        //                     "units": [
        //                         {
        //                             "name": {
        //                                 "en": "Tori maki",
        //                                 "nl": "Tori maki"
        //                             },
        //                             "unitId": "5ef101ad2e3b6d9863edf6f7",
        //                             "price": {
        //                                 "en": "4.5",
        //                                 "nl": "4.5"
        //                             },
        //                             "status": "active",
        //                             "barcode": "",
        //                             "addOns": [],
        //                             "barcodeImg": "",
        //                             "floatValue": 4.5,
        //                             "availableQuantity": 1000,
        //                             "claimedQuantity": 0,
        //                             "sizeAttributes": []
        //                         }
        //                     ]
        //                 }
        //             ],
        //             "zones": [
        //                 "5eda1fa45b521f12006fdb17",
        //                 "5eda1fa55b521f12006fdb1c",
        //                 "5eda1fa55b521f12006fdb20",
        //                 "5eda1fb05b521f12006fdb70",
        //                 "5eda1fa75b521f12006fdb2a",
        //                 "5eda1fa55b521f12006fdb21",
        //                 "5eda1fa55b521f12006fdb22",
        //                 "5eda1fa45b521f12006fdb16",
        //                 "5eda1fa55b521f12006fdb1e",
        //                 "5eda1fa55b521f12006fdb1d",
        //                 "5eda1fa65b521f12006fdb27",
        //                 "5eda1fa75b521f12006fdb2c",
        //                 "5eda1fa75b521f12006fdb2e",
        //                 "5eda1fa55b521f12006fdb1f",
        //                 "5eda1fa65b521f12006fdb25",
        //                 "5eda1fa75b521f12006fdb2b",
        //                 "5eda1fb15b521f12006fdb76",
        //                 "5eda1fa45b521f12006fdb1b",
        //                 "5eda1fa45b521f12006fdb18",
        //                 "5eda1fa65b521f12006fdb24",
        //                 "5eda1fb15b521f12006fdb72",
        //                 "5eda1fa65b521f12006fdb23",
        //                 "5eda1fa45b521f12006fdb19",
        //                 "5eda1fa65b521f12006fdb29",
        //                 "5eda1fb15b521f12006fdb71",
        //                 "5eda1fb15b521f12006fdb77",
        //                 "5eda1fa45b521f12006fdb1a",
        //                 "5eda1fa65b521f12006fdb26",
        //                 "5eda1fa65b521f12006fdb28",
        //                 "5eda1fa75b521f12006fdb2d"
        //             ],
        //             "startDateTime": 1614038400,
        //             "startDateTimeISO": "2021-02-23T00:00:00",
        //             "endDateTime": 1614470400,
        //             "endDateTimeISO": "2021-02-28T00:00:00",
        //             "status": 2,
        //             "statusString": "Inactive",
        //             "offerType": 1,
        //             "offerTypeString": "Percentage Discount",
        //             "applicableOnString": "Product",
        //             "applicableOn": 0,
        //             "discountValue": 10,
        //             "termscond": "<p>Offer terms and conditions</p>\r\n",
        //             "howItWorkofferStatuss": "<p>Offer How it works description</p>\r\n",
        //             "howItWorks": "<p>Offer How it works description</p>\r\n",
        //             "abbrevation": "1",
        //             "abbrevationText": "Prefix",
        //             "currency": "EUR",
        //             "currencySymbol": "€"
        //         }
        //     ],
        //     "message": "success"
        // }
        return new Promise((resolve, reject) => {            
            // resolve(resp);        
            if(!isValidToken(axios.defaults.headers.common[AXIOS_AUTH]))
            {
                reject(HTTP_ERRORS.InvalidToken);
            }
            else
            {
                axios.get( `${BASE_URL}` + '/offerdetails/' + id)
                .then(response => {                    
                    if ( response.status === 200 )
                    {   
                        resolve(response.data);                        
                    }
                    else
                    {   
                        reject(response.data);
                    }                    
                })
                .catch(err=>{
                    reject(err);
                })
            }                
        });
    },

    updateOffer (offer) {
        // let resp = {
        //     "message": "Successfully Updated",
        //     "status": 1
        // }
        return new Promise((resolve, reject) => {            
            // resolve(resp);        
            if(!isValidToken(axios.defaults.headers.common[AXIOS_AUTH]))
            {
                reject(HTTP_ERRORS.InvalidToken);
            }
            else
            {
                delete axios.defaults.headers.common[AXIOS_LANGUAGE]
                axios.patch( `${BASE_URL}/patchOffers/`, { data: offer })
                .then(response => {                    
                    if ( response.status === 200 )
                    {                       
                        resolve(response.data);                        
                    }
                    else
                    {   
                        reject(response.data);
                    }                    
                })
                .catch(err=>{
                    reject(err);
                })
            }                
        });
    },

    getOffersByStatus ( status ) {
        return new Promise((resolve, reject) => {            
            // resolve( resp )
            if(!isValidToken(axios.defaults.headers.common[AXIOS_AUTH]))
            {
                reject(HTTP_ERRORS.InvalidToken);
            }
            else
            {
                console.log("GetOffer Status=> ", status)

                delete axios.defaults.headers.common[AXIOS_LANGUAGE]
                axios.post( `${BASE_URL}/offersFilter/`, status)
                    .then(response => {   
                        if ( response.status === HTTP_ERRORS.OK )
                        {   
                            console.log("Get Offers filter =>", response.data)
                            resolve(response.data);                        
                        }
                        else if ( response.status === HTTP_ERRORS.NotFound )
                        {
                            console.log("Get Offers Not Found =>")
                            let resp_data = {
                                data: response.data,
                                message: response.message,
                                totalCount: 0
                            }
                            resolve(resp_data);
                        }
                        else
                        {   
                            reject(response.data);
                        }                    
                    })
                    .catch(err=>{
                        reject(err);
                    })
                
                /* User Fetch */
                // let url = BASE_URL + '/offersFilter/'
                // let token = axios.defaults.headers.common[AXIOS_AUTH]
                // fetch( url, {
                //     method:'POST',
                //     headers:{'Content-type':'application/json','Authorization': token},
                //     body:JSON.stringify(status)
                // })
                // .then(response => response.json())
                // .then(response=>{
                //     if (response) {
                //         console.log("GetOffer Res  => ", response)
                //         if ( response.message === "success" )
                //         {         
                //             resolve(response);     
                //         }
                //         else
                //         {   
                //             let resp_data = {
                //                 data: response.data,
                //                 message: response.message,
                //                 totalCount: 0
                //             }
                //             resolve(resp_data);
                //         }
                //     }
                // })
                // .catch(err=>{
                //     reject(err);
                // })
            }
        });
    },

    getProductsByStoreId ( id ) {

        // let resp = [
        //     {
        //         "_id": {
        //             "$oid": "5ef101b72e3b6d9863edf700"
        //         },
        //         "productname": {
        //             "en": "Kani gunkan",
        //             "nl": "Kani gunkan"
        //         },
        //         "units": [
        //             {
        //                 "name": {
        //                     "en": "Kani gunkan",
        //                     "nl": "Kani gunkan"
        //                 },
        //                 "unitId": "5ef101b72e3b6d9863edf6ff",
        //                 "price": {
        //                     "en": "1.6",
        //                     "nl": "1.6"
        //                 },
        //                 "status": "active",
        //                 "barcode": "",
        //                 "addOns": [],
        //                 "barcodeImg": "",
        //                 "floatValue": 1.6,
        //                 "availableQuantity": 1000,
        //                 "claimedQuantity": 0,
        //                 "sizeAttributes": []
        //             }
        //         ],
        //         "firstCategoryId": "5ef101a72e3b6d9863edf6ec",
        //         "secondCategoryId": "",
        //         "thirdCategoryId": "",
        //         "catName": {
        //             "en": "Gunkan",
        //             "nl": "Gunkan",
        //             "id": "",
        //             "es": "",
        //             "fr": "",
        //             "ar": ""
        //         },
        //         "subCatName": {
        //             "en": "",
        //             "nl": ""
        //         },
        //         "subSubCatName": {
        //             "en": "",
        //             "nl": ""
        //         },
        //         "firstCategoryName": "Gunkan",
        //         "secondCategoryName": "",
        //         "thirdCategoryName": ""
        //     }
        // ]

        return new Promise((resolve, reject) => {            
            // resolve( resp )
            if(!isValidToken(axios.defaults.headers.common[AXIOS_AUTH]))
            {
                reject(HTTP_ERRORS.InvalidToken);
            }
            else
            {
                delete axios.defaults.headers.common[AXIOS_LANGUAGE]
                axios.get( `${BASE_URL}/products/` + id)
                    .then(response => {                    
                        if ( response.status === 200 )
                        {   
                            console.log("Get Products Resp  => ", response);
                            resolve(response.data);                        
                        }
                        else
                        {   
                            reject(response.data);
                        }                    
                    })
                    .catch(err=>{
                        reject(err);
                    })
            }
        });
    },

    updateOfferStatus ( data ) {  //(1: active, 2: deactive, 3:delete)
        // let resp = {
        //     "message": "Successfully Updated",
        //     "status": data.data.status
        // }
        return new Promise((resolve, reject) => {            
            if(!isValidToken(axios.defaults.headers.common[AXIOS_AUTH]))
            {
                reject(HTTP_ERRORS.InvalidToken);
            }
            else
            {
                // resolve( resp )
                axios.patch( `${BASE_URL}/offerStatus/`, data)
                    .then(response => {                    
                        if ( response.status === 200 )
                        {                       
                            resolve(response.data);                        
                        }
                        else
                        {   
                            reject(response.data);
                        }                    
                    })
                    .catch(err=>{
                        reject(err);
                    })
            }
        });
    },

    getCategoryListByStoreId ( id ) {
        // let resp  = [
        //     {
        //         "_id": {
        //             "$oid": "5ef101a72e3b6d9863edf6ee"
        //         },
        //         "categoryName": {
        //             "en": "Salades",
        //             "nl": "Salades",
        //             "id": "",
        //             "es": "",
        //             "fr": "",
        //             "ar": ""
        //         }
        //     }
        // ]
        return new Promise((resolve, reject) => {    
            // resolve( resp )        
            if(!isValidToken(axios.defaults.headers.common[AXIOS_AUTH]))
            {
                reject(HTTP_ERRORS.InvalidToken);
            }
            else
            {
                delete axios.defaults.headers.common[AXIOS_LANGUAGE]
                axios.get( `${BASE_URL}` + '/category/' + id)
                    .then(response => {   
                        if ( response.status === 200 )
                        {   
                            let data = JSON.parse(response.data.replace(/\bNaN\b/g, "null"));
                            console.log("Get Category List Resp =>", data)
                            resolve(data);                        
                        }
                        else
                        {   
                            reject(response.data);
                        }                    
                    })
                    .catch(err=>{
                        reject(err);
                    })
            }
        });
    },

    getSubCategoryListByStoreId ( id ) {
        // let resp = {}
        return new Promise((resolve, reject) => {    
            if(!isValidToken(axios.defaults.headers.common[AXIOS_AUTH]))
            {
                reject(HTTP_ERRORS.InvalidToken);
            }
            else
            {
                // resolve( resp )        
                let token = axios.defaults.headers.common[AXIOS_AUTH]
                delete axios.defaults.headers.common[AXIOS_LANGUAGE]
                axios.get( `${BASE_URL}` + '/subcategory/' + id)
                    .then(response => {   
                        if ( response.status === 200 )
                        {   
                            resolve(response.data);                        
                        }
                        else
                        {   
                            reject(response.data);
                        }                    
                    })
                    .catch(err=>{
                        reject(err);
                    })
            }
        });
    },

    getSubSubCategoryListByStoreId ( id ) {
        // let resp = {}
        return new Promise((resolve, reject) => {     
            if(!isValidToken(axios.defaults.headers.common[AXIOS_AUTH]))
            {
                reject(HTTP_ERRORS.InvalidToken);
            }
            else
            {
                // resolve( resp )
                axios.get( `${BASE_URL}` + '/subsubcategory/' + id)
                    .then(response => {
                        if ( response.status === 200 )
                        {   
                            resolve(response.data);                        
                        }
                        else
                        {   
                            reject(response.data);
                        }                    
                    })
                    .catch(err=>{
                        reject(err);
                    })
            }       
        });
    },

    getUnitListByProductId ( id ) {
        // let resp = {}
        return new Promise((resolve, reject) => {          
            if(!isValidToken(axios.defaults.headers.common[AXIOS_AUTH]))
            {
                reject(HTTP_ERRORS.InvalidToken);
            }
            else
            {
                // resolve( resp )
                axios.get( `${BASE_URL}` + '/produnits/' + id)
                    .then(response => {                    
                        if ( response.status === 200 )
                        {   
                            resolve(response.data);                        
                        }
                        else
                        {   
                            reject(response.data);
                        }                    
                    })
                    .catch(err=>{
                        reject(err);
                    })
            }  
        });
    },

    getCities () {
        // let resp = [
        //     {
        //         "cityId": {
        //             "$oid": "5ed78fc3c8405e5132c589e3"
        //         },
        //         "cityName": "Utrecht",
        //         "abbrevation": "1",
        //         "abbrevationText": "Prefix",
        //         "currency": "EUR",
        //         "currencySymbol": "€"
        //     }
        // ]
        return new Promise((resolve, reject) => {            
            // resolve( resp )
            if(!isValidToken(axios.defaults.headers.common[AXIOS_AUTH]))
            {
                reject(HTTP_ERRORS.InvalidToken);
            }
            else
            {
                axios.get( `${BASE_URL}` + '/cities')
                .then(response => {                    
                    if ( response.status === 200 )
                    {   
                        resolve(response.data);                        
                    }
                    else
                    {   
                        reject(response.data);
                    }                    
                })
                .catch(err=>{
                    reject(err);
                })
            }
        });
    },    
}

export default OfferService;