import axios from 'axios';
import EnvDecode from '../common/EnvDecode';
import { 
    AXIOS_LANGUAGE,
    APP_LANG_KEY
} from '../components/Common/constants';

const BASE_URL = EnvDecode.getEnvPath(process.env.React_App_API_SERVER_URL);
var BrandService = {
    createBrand (brand) {

        return new Promise((resolve, reject) => {       
            axios.defaults.headers.common[AXIOS_LANGUAGE] = localStorage.getItem(APP_LANG_KEY) || 'en';     
            axios.post( `${BASE_URL}` + '/api/brand/create', brand)
                .then(response => {                    
                    if ( response.status === 200 )
                    {                       
                        resolve(response.data);                        
                    }
                    else
                    {   
                        reject(response.data);
                    }
                })
                .catch(err=>{
                    reject(err);
                })
        });
    },



    updateBrand (brand) {
        let brandId = brand._id
        // delete brand._id
        delete brand.checked

        let params = {
            brandId: brandId,
            data: brand
        }

        return new Promise((resolve, reject) => {       
            axios.defaults.headers.common[AXIOS_LANGUAGE] = localStorage.getItem(APP_LANG_KEY) || 'en';     
            axios.patch(  `${BASE_URL}` + '/api/brand/update', params )
                .then(response => {                    
                    if ( response.status === 200 )
                    {                       
                        resolve(response.data);                        
                    }
                    else
                    {   
                        reject(response.data);
                    }
                })
                .catch(err=>{
                    reject(err);
                })
        });
    },

    deleteBrand ( brandId ) {
        return new Promise((resolve, reject) => {        
            axios.defaults.headers.common[AXIOS_LANGUAGE] = localStorage.getItem(APP_LANG_KEY) || 'en';    
            axios.delete(  `${BASE_URL}` + '/api/brand/remove/' + brandId )
                .then(response => {                    
                    if ( response.status === 200 )
                    {                       
                        resolve(response.data);                        
                    }
                    else
                    {   
                        reject(response.data);
                    }
                })
                .catch(err=>{
                    reject(err);
                })
        });
    },

    getCentralBrands () {
        return new Promise((resolve, reject) => {     
            axios.defaults.headers.common[AXIOS_LANGUAGE] = localStorage.getItem(APP_LANG_KEY) || 'en';       
            axios.get(  `${BASE_URL}` + '/api/centralBrands/')
                .then(response => {
                    if ( response.status === 200 )
                    {   
                        resolve(response.data.data);                        
                    }
                    else
                    {   
                        reject(response.data.data);
                    }
                })
                .catch(err=>{
                    reject(err);
                })
        });
    },

    insertBrands (brands) {

        //console.log( 'insert service =', brands )
        return new Promise((resolve, reject) => {      
            axios.defaults.headers.common[AXIOS_LANGUAGE] = localStorage.getItem(APP_LANG_KEY) || 'en';      
            axios.post(  `${BASE_URL}` + '/api/insertBrands', brands)
                .then(response => {                    
                    if ( response.status === 200 )
                    {                       
                        resolve(response.data);                        
                    }
                    else
                    {   
                        reject(response.data);
                    }
                })
                .catch(err=>{
                    reject(err);
                })
        });
    },


    getActiveBrands ( brandData ) {
        let pageNum = brandData.pageNum
        let query = brandData.query === "" ? 0 : brandData.query
            return new Promise((resolve, reject) => {
                axios.defaults.headers.common[AXIOS_LANGUAGE] = localStorage.getItem(APP_LANG_KEY) || 'en';
                axios.get(  `${BASE_URL}` + '/api/brand/statusWithQuery/1/' + pageNum + "/" + query )
                    .then(response => {
                        if ( response.status === 200 )
                        {   
                            resolve(response.data.data);                        
                        }
                        else
                        {   
                            reject(response.data.data);
                        }
                    })
                    .catch(err=>{
                        reject(err);
                    })
        });
    },

    getInactiveBrands ( brandData ) {
        let pageNum = brandData.pageNum
        let query = brandData.query === "" ? 0 : brandData.query

        return new Promise((resolve, reject) => { 
            axios.defaults.headers.common[AXIOS_LANGUAGE] = localStorage.getItem(APP_LANG_KEY) || 'en';           
            axios.get( `${BASE_URL}` + '/api/brand/statusWithQuery/0/' + pageNum + "/" + query )
                .then(response => {                    
                    if ( response.status === 200 )
                    {                       
                        resolve(response.data.data);                        
                    }
                    else
                    {   
                        reject(response.data.data);
                    }
                })
                .catch(err=>{
                    reject(err);
                })
        });
    }
    
}

export default BrandService;