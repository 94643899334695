import 'core-js/es6/string';
import 'core-js/es6/array';
import 'core-js/es6/map';
import 'core-js/es6/set';
import 'core-js/es6/object';
import 'core-js/es6/promise';
import 'core-js/es7/object';
import 'core-js/es7/array';
import 'raf/polyfill';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
//import { CookiesProvider } from 'react-cookie';
import App from './App';

//import './i18n';

import configureStore from './store/store';
import initTranslation from './common/localize'
import $ from 'jquery'
import Cache from './common/Cache'
import { registerServiceWorker } from './firebase/register-sw';

var loadingPage = true
var initialized = false

$(function(){
    initTranslation()        
    Cache.init((err, ret)=>{
        //console.log("index.js -> cache init", err, ret);
        if(err) {
            $.error('cache init: error');
            return;
        }
        // for FCM
        // registerServiceWorker();
        render();
        initialized = true
        if(!loadingPage) {
            $(".loading-landing-panel").remove();
        }
    })              
})

// window.onbeforeunload = function (e) {
//     window.onunload = function () {
//         window.localStorage.temp_user_data = window.localStorage.userData;
//         window.localStorage.temp_jwt_access_token = window.localStorage.jwt_access_token;

//         window.localStorage.removeItem("userData");
//         window.localStorage.removeItem("jwt_access_token");
//     }
//     console.log("BEFOREUNLOAD =>", e);
//     // var confirmMessage = "\o/";
//     // (e || window.event).returnValue = confirmMessage;
//     // return confirmMessage;
//     return undefined;
// };

// window.onload = function () {
//     console.log("ONLOAD =>");
//     let user_data = window.localStorage.temp_user_data || window.localStorage.userData;
//     let jwt_access_token = window.localStorage.temp_jwt_access_token || window.localStorage.jwt_access_token;

//     if(user_data === undefined || user_data === null)
//     {
//         window.localStorage.removeItem("userData");
//         window.localStorage.removeItem("jwt_access_token");
//     }
//     window.localStorage.userData = user_data;
//     window.localStorage.jwt_access_token = jwt_access_token;
//     window.localStorage.removeItem("temp_user_data");
//     window.localStorage.removeItem("temp_jwt_access_token");
// };

const store = configureStore();

const render = () => {
    ReactDOM.render(
        // <CookiesProvider>
            <Provider store={store}>
                <App />
            </Provider>,
        // </CookiesProvider>,
        document.getElementById('app')
    );
}
