import axios from 'axios';
import EnvDecode from '../common/EnvDecode';
import { 
    JWT_ACCESS_TOKEN, 
    AXIOS_AUTH,
    AXIOS_LANGUAGE,
    APP_LANG_KEY
} from '../components/Common/constants';


const BASE_URL = EnvDecode.getEnvPath(process.env.React_App_ZENDESK_URL);

// React_App_ZENDESK_URL = https://Meshi.zendesk.com
// React_App_ZENDESK_TOKEN = Y2xvdWRAZXRlbi5jb206QmV0aDAkaCNzdEVzd0AmMVNpUA==

var TicketService = {

    getAllTickets( userId ) {
       //console.log( ' ticket service =', `${BASE_URL}` + '/api/v2/users/' + userId + '/tickets/requested' )
        return new Promise((resolve, reject) => {
            delete axios.defaults.headers.common[AXIOS_LANGUAGE];
            axios.defaults.headers.common[AXIOS_AUTH] = 'Basic ' +  EnvDecode.getEnvPath(process.env.React_App_ZENDESK_TOKEN);

            axios.get( `${BASE_URL}` + '/api/v2/users/' + userId + '/tickets/requested',  
                        {headers: { 
                            "Content-Type": "application/json", 
                            "X-Frame-Options": "sameorigin", 
                            language: "en"}
                        } 
                )
                .then(response => {
                    let access_token = localStorage.getItem(JWT_ACCESS_TOKEN);
                    axios.defaults.headers.common[AXIOS_AUTH] = 'Basic ' +  access_token;
                    axios.defaults.headers.common[AXIOS_LANGUAGE] = localStorage.getItem(APP_LANG_KEY) || 'en';
                    if ( response.status === 200 )
                    {   
                        resolve(response.data.data);                        
                    }
                    else
                    {   
                        reject(response.data.data);
                    }
                })
                .catch(err=>{
                    let access_token = localStorage.getItem(JWT_ACCESS_TOKEN);
                    axios.defaults.headers.common[AXIOS_AUTH] = 'Basic ' +  access_token;
                    axios.defaults.headers.common[AXIOS_LANGUAGE] = localStorage.getItem(APP_LANG_KEY) || 'en';
                    reject(err);
                })
        });
    },    
}

export default TicketService;