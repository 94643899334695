import { HTTP_ERRORS } from '../../components/Common/constants';
import StoreStatusService from '../../services/storeStatusService';

import { 
  UPDATE_STORE_STATUS, GOT_ERROR_STORE_STATUS, 
  UPDATE_STORE_STATUS_DONE,
  INVALID_TOKEN_ERROR} from '../actions/actions';

const updateStatus = store => next => action => {
    let dispatch = store.dispatch;
    if (action.type === UPDATE_STORE_STATUS) {
        StoreStatusService.updateStoreStatus(action.data).then(result => {        
        dispatch({ type: UPDATE_STORE_STATUS_DONE, payload:action.data });        
      })
      .catch(err=>{
        if(err === HTTP_ERRORS.InvalidToken)
        {
          dispatch({ type: INVALID_TOKEN_ERROR, payload: err });
        }
        else
        {
          dispatch({ type: GOT_ERROR_STORE_STATUS, payload: err })
        }
      })      
    }
    return next(action)
  }

export default updateStatus;