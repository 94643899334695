import { GET_FAQS_DONE, GOT_ERROR_FAQ} from '../actions/actions';

const initialState = {
    faqs: [],
    err_faq:null,    
}

const faqReducer = (state = initialState, action) => {    

    switch (action.type) {
        case GET_FAQS_DONE:
            return {
                ...state,
                faqs: action.payload
            }
        case GOT_ERROR_FAQ:            
            return {
                ...state,
                err_faq: action.payload
            }   
        default:
            return state;
    }
}

export default faqReducer;