// import brand from "../../views/brand/brand";

export const GET_ACTIVE_BRANDS = 'GET_ACTIVE_BRANDS';
export const GET_INACTIVE_BRANDS = 'GET_INACTIVE_BRANDS';
export const CREATE_BRAND = 'CREATE_BRAND';
export const DELETE_BRAND = 'DELETE_BRAND';
export const UPDATE_BRAND = 'UPDATE_BRAND';
export const GET_CENTRAL_BRAND = 'GET_CENTRAL_BRAND';
export const INSERT_BRANDS = 'INSERT_BRANDS';

export const GOT_ERROR_BRAND = 'GOT_ERROR_BRAND';

export const UPDATE_BRAND_DONE = 'UPDATE_BRAND_DONE';
export const DELETE_BRAND_DONE = "DELETE_BRAND_DONE";
export const CREATE_BRAND_DONE = "CREATE_BRAND_DONE";
export const GET_CENTRAL_BRAND_DONE = 'GET_CENTRAL_BRAND_DONE';
export const INSERT_BRANDS_DONE = 'INSERT_BRANDS_DONE';

export const GET_ACTIVE_BRANDS_DONE = 'GET_ACTIVE_BRANDS_DONE';
export const GET_INACTIVE_BRANDS_DONE = 'GET_INACTIVE_BRANDS_DONE';

export const OPEN_NEW_BRAND_DIALOG = 'OPEN_NEW_BRAND_DIALOG';
export const OPEN_EDIT_BRAND_DIALOG = 'OPEN_EDIT_BRAND_DIALOG';
export const CLOSE_BRAND_DIALOG = 'CLOSE_BRAND_DIALOG';
/**
 * Get active brand list
 */
export function getActiveBrands( brandData ) {
    return { type: GET_ACTIVE_BRANDS, data: brandData };
}

/**
 * Get inactive brand list
 */
export function getInctiveBrands( brandData ) {
    return { type: GET_INACTIVE_BRANDS, data: brandData };
}

/**
 * Get central brand list
 */
 export function getCentralBrands() {
    return { type: GET_CENTRAL_BRAND };
}

/**
 * Create Brand
 */
export function createBrand(brandData) {
    return { type: CREATE_BRAND, data:brandData };
}
/**
 * Insert Brands
 */

export function insertBrands(brands) {
    return { type: INSERT_BRANDS, data:brands };
}

/**
 * Update Brand
 */
export function updateBrand(brandData) {
    return { type: UPDATE_BRAND, data:brandData };
}

/**
 * Delete Brand
 */
export function deleteBrand(brandData) {
    return { type: DELETE_BRAND, data:brandData };
}

export function openNewBrandDialog( status )
{
    return {
        type: OPEN_NEW_BRAND_DIALOG, data:status
    }
}

export function openEditBrandDialog(data)
{
    return {
        type: OPEN_EDIT_BRAND_DIALOG, data:data
    }
}

export function closeBrandDialog()
{
    return {
        type: CLOSE_BRAND_DIALOG
    }
}

