import settings from './settings.middleware.js'
import themes from './themes.middleware.js';
import auth from './auth.middleware.js';
import brand from './brand.middleware.js';
import schedule from './schedule.middleware.js';
import manager from './manager.middleware.js';
import category from './category.middleware.js';
import addon from './addon.middleware.js';
import orders from './orders.middleware.js';
import storeMenu from './storeMenu.middleware.js';
import updateStatus from './storeStatus.middleware.js';
import offer from './offer.middleware.js';
import wallet from './wallet.middleware.js';
import faq from './faq.middleware.js';
import ticket from './ticket.middleware.js';
import invoice from './invoice.middleware.js';

export default [
    settings,
    themes,
    auth,
    brand,
    schedule,
    manager,
    category,
    addon,
    orders,
    storeMenu,
    updateStatus,
    offer,
    wallet,
    faq,
    ticket,
    invoice,
]