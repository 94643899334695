// import brand from "../../views/brand/brand";

export const GET_FAQS = 'GET_FAQS';
export const GET_FAQS_DONE = 'GET_FAQS_DONE';
export const GOT_ERROR_FAQ = 'GOT_ERROR_FAQ';
/**
 * Get FAQ list
 */
export function getFAQs( ) {
    return { type: GET_FAQS };
}

