import { ORDER_SERVICE_TYPE, ORDER_STATUS, QUERY_ORDER_STATUS } from '../../components/Common/constants';
import { DISPATCH_ORDER_DONE, UPDATE_ORDER_STATUS_DONE, 
    COMPLETE_ORDER_DONE, GET_ORDERS_DONE, GOT_ERROR_ORDER,
    OPEN_NEW_ORDER_DIALOG, 
    OPEN_EDIT_ORDER_DIALOG, CLOSE_ORDER_DIALOG,
    UPDATE_EXPIRED_STATUS
} from '../actions/orders.actions';

const initialState = {
    new_orders: [],
    accepted_orders: [],
    inDispatch_orders: [],
    inProgress_orders:[],
    packageOrder: [],
    pickUpReady_orders: [],
    deliveryReady_orders: [],
    pickUp_orders: [],
    completed_orders: [],
    cancelled_orders: [],

    new_orders_count: 0,
    accepted_orders_count: 0,
    inDispatch_orders_count: 0,
    inProgress_orders_count: 0,
    packageOrder_count: 0,
    pickUpReady_orders_count: 0,
    deliveryReady_orders_count: 0,
    pickUp_orders_count: 0,
    completed_orders_count: 0,
    cancelled_orders_count: 0,

    orders_count: 0,

    orderDialog     : {
        type : 'new',
        props: {
            open: false
        },
        data : null
    },

    err_order:null,    
    msg: ''
}

const ordersReducer = (state = initialState, action) => {    
    let queryData = action.queryData;

    switch (action.type) {
        case DISPATCH_ORDER_DONE:{
            console.log("** Dispatched **", action.payload.data)
            let updatedOrder = null
            let itemsAccepted = [...state.accepted_orders];
            let itemsDeliveryReady = [...state.deliveryReady_orders];

            // Update updatedOrder
            itemsAccepted.map( _order => {
                if ( _order.orderId === queryData.orderId ){
                    updatedOrder = _order
                }
            })
            
            let accepted_orders_cnt = state.accepted_orders_count;
            let delivery_ready_orders_cnt = state.deliveryReady_orders_count;
            if (updatedOrder)
            {
                itemsAccepted =  itemsAccepted.filter(_order => _order._id !== updatedOrder._id);
                itemsDeliveryReady = [updatedOrder, ...itemsDeliveryReady]

                accepted_orders_cnt--;
                delivery_ready_orders_cnt++;
            }

            return {
                ...state,
                msg: action.payload,
                accepted_orders: itemsAccepted,
                accepted_orders_count: accepted_orders_cnt,
                
                deliveryReady_orders: itemsDeliveryReady,
                deliveryReady_orders_count: delivery_ready_orders_cnt
            }
        }
        case GOT_ERROR_ORDER:            
            return {
                ...state,
                err_manager: action.payload
            }
        case UPDATE_ORDER_STATUS_DONE:
        {
            // console.log("** Updated Order Status => NewOrders**", action)
            let updatedOrder = action.payload.data;
            let itemsAccepted = [...state.accepted_orders];
            let itemsInDispatch = [...state.inDispatch_orders];
            let itemsProgress = [...state.inProgress_orders];
            let itemsNew = [...state.new_orders];
            let itemsPackage = [...state.packageOrder];
            let itemsPickUpReady = [...state.pickUpReady_orders];
            let itemsDeliveryReady = [...state.deliveryReady_orders];
            let itemsPickUp = [...state.pickUp_orders];
            let itemsCompleted = [...state.completed_orders];
            let itemsCancelled = [...state.cancelled_orders];
            
            
            let isFoundNew = itemsNew.find((_order) => {return _order.orderId === updatedOrder.orderId});
            let isFoundAccepted = itemsAccepted.find((_order) => {return _order.orderId === updatedOrder.orderId});
            let isFoundPickupReady = itemsPickUpReady.find((_order) => {return _order.orderId === updatedOrder.orderId});
            let isFoundDeliveryReady = itemsDeliveryReady.find((_order) => {return _order.orderId === updatedOrder.orderId});
            let isFoundCompleted = itemsCompleted.find((_order) => {return _order.orderId === updatedOrder.orderId});
            let isFoundCancelled = itemsCancelled.find((_order) => {return _order.orderId === updatedOrder.orderId});
            
            itemsNew =  itemsNew.filter(_order => _order.orderId !== updatedOrder.orderId);
            itemsAccepted =  itemsAccepted.filter(_order => _order.orderId !== updatedOrder.orderId);
            itemsInDispatch =  itemsInDispatch.filter(_order => _order.orderId !== updatedOrder.orderId);
            itemsPickUp =  itemsPickUp.filter(_order => _order.orderId !== updatedOrder.orderId);
            itemsProgress =  itemsProgress.filter(_order => _order.orderId !== updatedOrder.orderId);
            itemsPackage =  itemsPackage.filter(_order => _order.orderId !== updatedOrder.orderId);
            itemsPickUpReady =  itemsPickUpReady.filter(_order => _order.orderId !== updatedOrder.orderId);
            itemsDeliveryReady =  itemsDeliveryReady.filter(_order => _order.orderId !== updatedOrder.orderId);
            itemsCompleted = itemsCompleted.filter(_order => _order.orderId !== updatedOrder.orderId);
            itemsCancelled = itemsCancelled.filter(_order => _order.orderId !== updatedOrder.orderId);


            let new_cnt = isFoundNew ? state.new_orders_count - 1 : state.new_orders_count;
            let accepted_cnt = isFoundAccepted ? state.accepted_orders_count - 1 : state.accepted_orders_count;
            let pickup_ready_cnt = isFoundPickupReady ? state.pickUpReady_orders_count - 1 : state.pickUpReady_orders_count;
            let delivery_ready_cnt = isFoundDeliveryReady ? state.deliveryReady_orders_count - 1 : state.deliveryReady_orders_count;
            let completed_cnt = isFoundCompleted ? state.completed_orders_count - 1 : state.completed_orders_count;
            let cancelled_cnt = isFoundCancelled ? state.cancelled_orders_count - 1 : state.cancelled_orders_count;

            // console.log("** Updated Order Status**", updatedOrder,isFoundNew, "QueryData=", queryData)
            if ( updatedOrder.status === ORDER_STATUS.cancelled || updatedOrder.status === ORDER_STATUS.rejected )
            {
                itemsCancelled = [updatedOrder, ...itemsCancelled]
                cancelled_cnt++;
            }
            else if ( updatedOrder.status === ORDER_STATUS.accepted )
            {
                itemsAccepted = [updatedOrder, ...itemsAccepted]
                accepted_cnt++;
            } 
            else if ( updatedOrder.status === ORDER_STATUS.orderCompleted || updatedOrder.status === ORDER_STATUS.orderExpired )
            {
                itemsCompleted = [updatedOrder, ...itemsCompleted]
                completed_cnt++;
            }
            else if(updatedOrder.status === ORDER_STATUS.orderReady)
            {
                if(updatedOrder.serviceType === ORDER_SERVICE_TYPE.pickup)
                {
                    itemsPickUpReady = [updatedOrder, ...itemsPickUpReady]
                    pickup_ready_cnt++;
                }
                else if(updatedOrder.serviceType === ORDER_SERVICE_TYPE.delivery)
                {
                    itemsDeliveryReady = [updatedOrder, ...itemsDeliveryReady]
                    delivery_ready_cnt++;
                }
            }

            return {
                ...state,
                msg: action.payload,
                accepted_orders: itemsAccepted,
                inDispatch_orders: itemsInDispatch,
                inProgress_orders: itemsProgress,
                new_orders:  itemsNew,
                packageOrder: itemsPackage,
                pickUpReady_orders: itemsPickUpReady,
                deliveryReady_orders: itemsDeliveryReady,
                pickUp_orders: itemsPickUp,
                completed_orders: itemsCompleted,
                cancelled_orders: itemsCancelled,

                accepted_orders_count: accepted_cnt,
                new_orders_count: new_cnt,
                pickUpReady_orders_count: pickup_ready_cnt,
                deliveryReady_orders_count: delivery_ready_cnt,
                completed_orders_count: completed_cnt,
                cancelled_orders_count: cancelled_cnt
            } 
        }
        case COMPLETE_ORDER_DONE:            
        {
            let updatedOrder = null
            let itemsCompleted = [...state.completed_orders];
            let itemsDeliveryReady = [...state.deliveryReady_orders];

            let delivery_ready_cnt = state.deliveryReady_orders_count;
            let completed_cnt = state.completed_orders_count;

            // Get Updated Order
            itemsDeliveryReady.map( _order => {
                if ( _order.orderId === queryData ){
                    updatedOrder = _order
                }
            })
            
            if ( updatedOrder ) 
            {
                updatedOrder.statusMsg = action.payload.data
                updatedOrder.status = ORDER_STATUS.orderDeliveryCompleted

                delivery_ready_cnt--;
                completed_cnt++;
            }

            itemsDeliveryReady =  itemsDeliveryReady.filter(_order => _order.orderId !== updatedOrder.orderId);
            itemsCompleted = [updatedOrder, ...itemsCompleted]

            return {
                ...state,
                msg: action.payload,
                completed_orders: itemsCompleted,
                deliveryReady_orders: itemsDeliveryReady,

                deliveryReady_orders_count: delivery_ready_cnt,
                completed_orders_count: completed_cnt
            }  
        }                              
        case GET_ORDERS_DONE:
        {
            let data = action.payload.data;
            //console.log( 'orders =', action, data ) // data => {count: 3, orders[{}, {}, {}]}
            let orderStatus = queryData.orderStatus;

            switch (orderStatus)
            {
                case QUERY_ORDER_STATUS.NEW_ORDER:
                    return {...state, 
                        new_orders: data.orders, 
                        new_orders_count: data.count
                    }
                    break;
                case QUERY_ORDER_STATUS.ACCEPTED_ORDER:
                    return {...state, 
                        accepted_orders: data.orders, 
                        accepted_orders_count: data.count
                    }
                    break;
                case QUERY_ORDER_STATUS.PICKUP_READY_ORDER:
                    return {...state, 
                        pickUpReady_orders: data.orders, 
                        pickUpReady_orders_count: data.count
                    }
                    break;
                case QUERY_ORDER_STATUS.DELIVERY_READY_ORDER:
                    return {...state, 
                        deliveryReady_orders: data.orders, 
                        deliveryReady_orders_count: data.count
                    }
                    break;
                case QUERY_ORDER_STATUS.COMPLETED_ORDER:
                    return {...state, 
                        completed_orders: data.orders, 
                        completed_orders_count: data.count
                    }
                    break;
                case QUERY_ORDER_STATUS.CANCELLED_ORDER:
                    return {...state, 
                        cancelled_orders: data.orders, 
                        cancelled_orders_count: data.count
                    }
                    break;
            }

            return;
            // old get orders
            let cnt = data.acceptedOrderCount

            if ( cnt < data.inDispatchOrderCount ){
                cnt = data.inDispatchOrderCount
            }
            if ( cnt < data.inProgressCount ){
                cnt = data.inProgressCount
            }
            if ( cnt < data.newOrderCount ){
                cnt = data.newOrderCount
            }
            if ( cnt < data.packageOrderCount ){
                cnt = data.packageOrderCount
            }
            if ( cnt < data.pickUpReadyOrderCount ){
                cnt = data.pickUpReadyOrderCount
            }
            if ( cnt < data.pickedUpOrderCount ){
                cnt = data.pickedUpOrderCount
            }

            if ( cnt < data.completed_orders_count ){
                cnt = data.completed_orders_count
            }

            let pickupCnt = 0
            let deliveryCnt = 0
            let itemsPReady = []
            let itemsDReady = []
            data.pickUpReadyOrder.map( order => {
                if ( order.orderTypeMsg === 'Pick-Up' ){
                    pickupCnt = pickupCnt + 1
                    itemsPReady.push(order)
                }else if ( order.orderTypeMsg === 'Delivery' ){
                    deliveryCnt = deliveryCnt + 1
                    itemsDReady.push(order)
                }else{
                    pickupCnt = pickupCnt + 1
                    itemsPReady.push(order)
                }
            })

            return {
                ...state,
                accepted_orders: data.acceptedOrder,
                inDispatch_orders: data.inDispatchOrder,
                inProgress_orders: data.inProgress,
                new_orders: data.newOrder,
                packageOrder: data.packageOrder,
                pickUpReady_orders: itemsPReady,
                deliveryReady_orders: data.inDispatchOrder,
                pickUp_orders: data.pickedUpOrder,
                completed_orders: data.completedOrder,

                accepted_orders_count:  data.acceptedOrderCount,
                inDispatch_orders_count: data.inDispatchOrderCount,
                inProgress_orders_count: data.inProgressCount,
                new_orders_count: data.newOrderCount,
                packageOrder_count: data.packageOrderCount,
                pickUpReady_orders_count: pickupCnt,
                deliveryReady_orders_count: data.inDispatchOrderCount,
                pickUp_orders_count: data.pickedUpOrderCount,
                completed_orders_count: data.completedOrderCount,

                orders_count: cnt
            } 
        }
        // Only Redux without Call api
        case UPDATE_EXPIRED_STATUS:
        {
            let updatedOrder = action.data
            //console.log("** Expired Order Status**", updatedOrder)

            let itemsAccepted = [...state.accepted_orders];
            let itemsInDispatch = [...state.inDispatch_orders];
            let itemsProgress = [...state.inProgress_orders];
            let itemsNew = [...state.new_orders];
            let itemsPackage = [...state.packageOrder];
            let itemsPickUpReady = [...state.pickUpReady_orders];
            let itemsDeliveryReady = [...state.deliveryReady_orders];
            let itemsPickUp = [...state.pickUp_orders];
            let itemsCompleted = [...state.completed_orders];
            let itemsCancelled = [...state.cancelled_orders]

            let isFoundNew = itemsNew.find((_order) => {return _order.orderId === updatedOrder.orderId});
            let isFoundAccepted = itemsAccepted.find((_order) => {return _order.orderId === updatedOrder.orderId});
            let isFoundPickupReady = itemsPickUpReady.find((_order) => {return _order.orderId === updatedOrder.orderId});
            let isFoundDeliveryReady = itemsDeliveryReady.find((_order) => {return _order.orderId === updatedOrder.orderId});
            let isFoundCompleted = itemsCompleted.find((_order) => {return _order.orderId === updatedOrder.orderId});
            let isFoundCancelled = itemsCancelled.find((_order) => {return _order.orderId === updatedOrder.orderId});

            let new_cnt = isFoundNew ? state.new_orders_count - 1 : state.new_orders_count;
            let accepted_cnt = isFoundAccepted ? state.accepted_orders_count - 1 : state.accepted_orders_count;
            let pickup_ready_cnt = isFoundPickupReady ? state.pickUpReady_orders_count - 1 : state.pickUpReady_orders_count;
            let delivery_ready_cnt = isFoundDeliveryReady ? state.deliveryReady_orders_count - 1 : state.deliveryReady_orders_count;
            let completed_cnt = isFoundCompleted ? state.completed_orders_count - 1 : state.completed_orders_count;
            let cancelled_cnt = isFoundCancelled ? state.cancelled_orders_count - 1 : state.cancelled_orders_count;

            if ( isFoundNew )
            {
                itemsNew.map( _order => {
                    if ( _order.orderId === updatedOrder.orderId )
                    {
                        updatedOrder = _order
                    }
                })
            }
            else if (isFoundAccepted) 
            {
                itemsAccepted.map( _order => {
                    if ( _order.orderId === updatedOrder.orderId )
                    {
                        updatedOrder = _order
                    }
                })
            }
            else if ( isFoundPickupReady )
            {
                itemsPickUpReady.map( _order => {
                    if ( _order.orderId === updatedOrder.orderId )
                    {
                        updatedOrder = _order
                    }
                })
            }
            else if ( isFoundDeliveryReady )
            {
                itemsDeliveryReady.map( _order => {
                    if ( _order.orderId === updatedOrder.orderId )
                    {
                        updatedOrder = _order
                    }
                })
            }
            
            if( updatedOrder ) {
                updatedOrder.statusMsg = "Your order has been expired."
                updatedOrder.statusText = "Order has expired"
                updatedOrder.status = ORDER_STATUS.orderExpired
            }
            
            // itemsPickUp.map( _order => {
            //     if ( _order.orderId === updatedOrder.orderId ){
            //         updatedOrder = _order
            //     }
            // })
            
            // itemsInDispatch.map( _order => {
            //     if ( _order.orderId === updatedOrder.orderId ){
            //         updatedOrder = _order
            //     }
            // })

            // itemsPackage.map( _order => {
            //     if ( _order.orderId === updatedOrder.orderId ){
            //         updatedOrder = _order
            //     }
            // })

            itemsAccepted =  itemsAccepted.filter(_order => _order.orderId !== updatedOrder.orderId);
            itemsInDispatch =  itemsInDispatch.filter(_order => _order.orderId !== updatedOrder.orderId);
            itemsProgress =  itemsProgress.filter(_order => _order.orderId !== updatedOrder.orderId);
            itemsNew =  itemsNew.filter(_order => _order.orderId !== updatedOrder.orderId);
            itemsPackage =  itemsPackage.filter(_order => _order.orderId !== updatedOrder.orderId);
            itemsPickUpReady =  itemsPickUpReady.filter(_order => _order.orderId !== updatedOrder.orderId);
            itemsDeliveryReady =  itemsDeliveryReady.filter(_order => _order.orderId !== updatedOrder.orderId);
            itemsPickUp =  itemsPickUp.filter(_order => _order.orderId !== updatedOrder.orderId);

            if ( updatedOrder.status === ORDER_STATUS.orderExpired )
            {
                itemsCompleted = [updatedOrder, ...itemsCompleted]
                completed_cnt++;
            }

            return {
                ...state,
                msg: action.payload,
                accepted_orders: itemsAccepted,
                inDispatch_orders: itemsInDispatch,
                inProgress_orders: itemsProgress,
                new_orders:  itemsNew,
                packageOrder: itemsPackage,
                pickUpReady_orders: itemsPickUpReady,
                deliveryReady_orders: itemsDeliveryReady,
                pickUp_orders: itemsPickUp,
                completed_orders: itemsCompleted,

                accepted_orders_count: accepted_cnt,
                new_orders_count: new_cnt,
                pickUpReady_orders_count: pickup_ready_cnt,
                deliveryReady_orders_count: delivery_ready_cnt,
                completed_orders_count: completed_cnt,
                cancelled_orders_count: cancelled_cnt
            } 
        }
        case OPEN_NEW_ORDER_DIALOG:
            // let status = action.payload.status;
            return {
                ...state,
                orderDialog: {
                    type : 'new',
                    props: {
                        open: true,
                        status: 0
                    },
                    data : action.data
                }
            };
            
        case OPEN_EDIT_ORDER_DIALOG:
            return {
                ...state,
                orderDialog: {
                    type : 'edit',
                    props: {
                        open: true
                    },
                    data : action.data
                }
            };
        case CLOSE_ORDER_DIALOG:
            return {
                ...state,
                orderDialog: {
                    type : 'new',
                    props: {
                        open: false
                    },
                    data : null
                }
            };
        default:
            return state;
    }
}

export default ordersReducer;