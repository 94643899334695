import { ADDON_STATUS } from '../../components/Common/constants';
import { GET_ADDONS_DONE, GOT_ERROR_ADDON, 
    CREATE_ADDON_DONE, UPDATE_ADDON_DONE, DELETE_ADDON_DONE, OPEN_NEW_ADDON_DIALOG, 
    OPEN_EDIT_ADDON_DIALOG, CLOSE_ADDON_DIALOG, OPEN_NEW_ADDONITEM_DIALOG, 
    OPEN_EDIT_ADDONITEM_DIALOG, CLOSE_ADDONITEM_DIALOG, GET_ADDONS_GROUP_DONE} from '../actions/addon.actions';

const initialState = {
    pending_addons:[],
    active_addons:[],
    inactive_addons:[],
    deleted_addons:[],

    all_active_addons: [],

    pending_addons_count: 0,
    active_addons_count: 0,
    inactive_addons_count: 0,
    deleted_addons_count: 0,

    err_addon:null,
    addonDialog     : {
        type : 'new',
        props: {
            open: false
        },
        data : null,
    },
    addonItemDialog     : {
        type : 'new',
        props: {
            open: false
        },
        data : null,
        index: null
    }
}

const addonReducer = (state = initialState, action) => {    
    switch (action.type) {
        case GET_ADDONS_DONE:

        //console.log( 'get addons =', action.payload.data.data )

            if (action.queryData.status === 0) {
                return {
                    ...state,
                    pending_addons: (action.payload.data.data) || [],
                    pending_addons_count: action.payload.data.count,                    
                }
            } else if (action.queryData.status === 1) {
                return {
                    ...state,
                    active_addons: (action.payload.data.data) || [],
                    active_addons_count: action.payload.data.count
                }
            } else if (action.queryData.status === 2) {
                return {
                    ...state,
                    inactive_addons: (action.payload.data.data) || [],
                    inactive_addons_count: action.payload.data.count
                }
            } else {
                return {
                    ...state,
                    deleted_addons: (action.payload.data.data) || [],
                    deleted_addons_count: action.payload.data.count
                }
            }   
        case GET_ADDONS_GROUP_DONE:
            {
                return {
                    ...state,
                    all_active_addons: (action.payload.data) || []
                }
            }         
        case GOT_ERROR_ADDON:            
            return {
                ...state,
                err_addon: action.payload
            }
        case CREATE_ADDON_DONE:
            let addon = action.payload.data;           

            if (addon.status === 0) {                
                return {
                    ...state,
                    pending_addons: [addon, ...state.pending_addons]
                }
            } else if (addon.status === 1) {                
                return {
                    ...state,
                    active_addons: [addon, ...state.approved_addons]
                }
            } else if (addon.status === 2) {                
                return {
                    ...state,
                    inactive_addons: [addon, ...state.approved_addons]
                }
            } else {                
                return {
                    ...state,
                    deleted_addons: [addon, ...state.deleted_addons]
                }
            }            
        case UPDATE_ADDON_DONE: {
            let upodateAddon = action.payload.data === undefined ? action.payload : action.payload.data
            let updateId = action.queryData
            
            let itemsPending = [...state.pending_addons];
            let itemsActive = [...state.active_addons];
            let itemsDeActive = [...state.inactive_addons];
            let itemsDelete = [...state.deleted_addons];

            //console.log("** ADDON UPDATED **", upodateAddon, updateId, itemsPending)
            
            if (upodateAddon.status === ADDON_STATUS.PENDING) {
               
                let isFound = itemsPending.find((_addon) => {return _addon._id === updateId});
                // If status is updated
                if (!isFound) {
                    itemsPending = [upodateAddon, ...itemsPending]
                    itemsActive =  itemsActive.filter(_addon => updateId !== _addon._id);
                    itemsDeActive =  itemsDeActive.filter(_addon => updateId !== _addon._id);
                    itemsDelete =  itemsDelete.filter(_addon => updateId !== _addon._id);
                }

                itemsPending = itemsPending.map((_addon) => {
                    if ( _addon._id === updateId )
                    {
                        return upodateAddon;
                    }
                    return _addon;
                });
                return {
                    ...state,
                    pending_addons: itemsPending,
                    active_addons:  itemsActive,
                    inactive_addons: itemsDeActive,
                    deleted_addons: itemsDelete
                }

            } else if ([ADDON_STATUS.OUT_OF_STOCK, ADDON_STATUS.APPROVED].includes(upodateAddon.status)) {
               
                let isFound = itemsActive.find((_addon) => {return _addon._id === updateId});
                // If status is updated
                if (!isFound) {
                    itemsActive = [upodateAddon, ...itemsActive]
                    itemsPending =  itemsPending.filter(_addon => updateId !== _addon._id);
                    itemsDeActive =  itemsDeActive.filter(_addon => updateId !== _addon._id);
                    itemsDelete =  itemsDelete.filter(_addon => updateId !== _addon._id);
                }

                itemsActive = itemsActive.map((_addon) => {
                    if ( _addon._id === updateId )
                    {
                        return upodateAddon;
                    }
                    return _addon;
                });
                return {
                    ...state,
                    pending_addons: itemsPending,
                    active_addons:  itemsActive,
                    inactive_addons: itemsDeActive,
                    deleted_addons: itemsDelete
                }

            } else if (upodateAddon.status === ADDON_STATUS.REJECTED) {
               
                let isFound = itemsDeActive.find((_addon) => {return _addon._id === updateId});
                // If status is updated
                if (!isFound) {
                    itemsDeActive = [upodateAddon, ...itemsDeActive]
                    itemsPending =  itemsPending.filter(_addon => updateId !== _addon._id);
                    itemsActive =  itemsActive.filter(_addon => updateId !== _addon._id);
                    itemsDelete =  itemsDelete.filter(_addon => updateId !== _addon._id);
                }

                itemsDeActive = itemsDeActive.map((_addon) => {
                    if ( _addon._id === updateId )
                    {
                        return upodateAddon;
                    }
                    return _addon;
                });
                return {
                    ...state,
                    pending_addons: itemsPending,
                    active_addons:  itemsActive,
                    inactive_addons: itemsDeActive,
                    deleted_addons: itemsDelete
                }

            } else if (upodateAddon.status === ADDON_STATUS.DELETED) {
               
                let isFound = itemsDelete.find((_addon) => {return _addon._id === updateId});
                // If status is updated
                if (!isFound) {
                    itemsDelete = [upodateAddon, ...itemsDelete]
                    itemsPending =  itemsPending.filter(_addon => updateId !== _addon._id);
                    itemsActive =  itemsActive.filter(_addon => updateId !== _addon._id);
                    itemsDeActive =  itemsDeActive.filter(_addon => updateId !== _addon._id);
                }

                itemsDelete = itemsDelete.map((_addon) => {
                    if ( _addon._id === updateId )
                    {
                        return upodateAddon;
                    }
                    return _addon;
                });
                return {
                    ...state,
                    pending_addons: itemsPending,
                    active_addons:  itemsActive,
                    inactive_addons: itemsDeActive,
                    deleted_addons: itemsDelete
                }

            }

            
            break; 
        }      
        case DELETE_ADDON_DONE:
            let id = action.payload._id;

            let itemsPending = [...state.pending_addons];
            let itemsActive = [...state.active_addons];
            let itemsDeActive = [...state.inactive_addons];
            let itemsDelete = [...state.deleted_addons];

            itemsPending    = itemsPending.filter(_addon => id !== _addon._id);
            itemsActive     = itemsActive.filter(_addon => id !== _addon._id);
            itemsDeActive   = itemsDeActive.filter(_addon => id !== _addon._id);
            itemsDelete     = itemsDelete.filter(_addon => id !== _addon._id);
            return {
                ...state,
                pending_addons:     itemsPending,
                active_addons:      itemsActive,
                inactive_addons:    itemsDeActive,
                deleted_addons:     itemsDelete
            }            
        case OPEN_NEW_ADDON_DIALOG:
            return {
                ...state,
                addonDialog: {
                    type : 'new',
                    props: {
                        open: true
                    },
                    data : null
                }
            };
            
        case OPEN_EDIT_ADDON_DIALOG:
            return {
                ...state,
                addonDialog: {
                    type : 'edit',
                    props: {
                        open: true
                    },
                    data : action.data
                }
            };
        case CLOSE_ADDON_DIALOG:
            return {
                ...state,
                addonDialog: {
                    type : 'new',
                    props: {
                        open: false
                    },
                    data : null
                }
            };
        case OPEN_NEW_ADDONITEM_DIALOG:
            return {
                ...state,
                addonItemDialog: {
                    type : 'new',
                    props: {
                        open: true
                    },
                    data : null
                }
            };
            
        case OPEN_EDIT_ADDONITEM_DIALOG:
            return {
                ...state,
                addonItemDialog: {
                    type : 'edit',
                    props: {
                        open: true
                    },
                    data : action.data,
                    index: action.index
                }
            };
        case CLOSE_ADDONITEM_DIALOG:
            return {
                ...state,
                addonItemDialog: {
                    type : 'new',
                    props: {
                        open: false
                    },
                    data : null
                }
            };
                
        default:
            return state;
    }
}

export default addonReducer;