import React, { Component } from "react";
import ReactDOMServer from "react-dom/server";
import _ from "lodash";
import { withRouter } from "react-router-dom";
//import {Trans, withNamespaces, translate } from 'react-i18next';
import PropTypes from "prop-types";
import {
  UncontrolledDropdown,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  ListGroup,
  ListGroupItem,
  Nav,
  Collapse,
  NavItem,
  // NavLink,
  NavbarToggler,
} from "reactstrap";
import { Link } from "react-router-dom";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "../../store/actions/actions";

// import ToggleFullscreen from '../Common/ToggleFullscreen';
import HeaderRun from "./Header.run";
// import i18n from '../../i18n'
import utilService from "../../common/utilService";
import {
  APP_LANG_KEY,
  COLORS,
  DISPATCHER_USER_TYPE,
  HTTP_ERRORS,
  MANAGER_STATUS,
  ORDER_STATUS,
  USER_DATA,
} from "../Common/constants";

import mqtt from "mqtt";
import MainStore from "../../store/notifyStore";
import EnvDecode from "../../common/EnvDecode";
import ChangePasswordDialog from "../../views/login/changePwdDialog";
import ConfirmDialog from "../Common/ConfirmDialog";
import LogoutWarningDialog from "../../views/login/logoutDialog";
// import { deleteToken, unsubscribeToTopic, subscribeToTopic, onMessageListener } from '../../firebase/init-fcm';
import axios from "axios";
import OrderDetail from "../../views/orders/orderDetail";

let mqttTopic = null;
class HeaderHorizontal extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    this.defaultLangs = ["English", "Dutch"];
    var defLang = localStorage.getItem("jq-appLang");
    if (defLang === "en") {
      defLang = "English";
    } else {
      defLang = "Dutch";
    }

    this.state = {
      isOpen: false,
      dropdownOpen: false,
      langs: this.defaultLangs,
      lang: defLang,
      templateString: "",

      // mqtt
      client: null,
      connectStatus: "Connect",
      isSubed: false,
      messages: [],
      openChangePwdDlg: false,
      openLogoutWarning: false,
      profile: props.profile.storeProfileData,
    };

    this.changeLanguage = this.changeLanguage.bind(this);
    this.onCloseChangePwdDlg = this.onCloseChangePwdDlg.bind(this);
    this.getStoreInfo = this.getStoreInfo.bind(this);
    this.onClickLogOut = this.onClickLogOut.bind(this);
    this.onCloseLogoutDlg = this.onCloseLogoutDlg.bind(this);
    this.signout = this.signout.bind(this);
    this.onLogOut = this.onLogOut.bind(this);
  }

  changeLanguage = (lng) => {
    var defLang = localStorage.getItem(APP_LANG_KEY);
    if (defLang === lng) {
      return;
    }

    if (lng === "en") {
      localStorage.setItem(APP_LANG_KEY, "en");
    } else {
      localStorage.setItem(APP_LANG_KEY, "nl");
    }

    this._isMounted &&
      this.setState({
        lang: lng === "en" ? "English" : "Dutch",
      });
    window.location.reload();
  };

  componentDidMount() {
    // window.addEventListener("resize", this.handleResize);
    this._isMounted = true;
    HeaderRun();

    // MQTT
    this.userData = JSON.parse(localStorage.getItem(USER_DATA));
    if (this.userData) {
      this.handleDisconnect();
      this.mqttProcess();

      // this.connectFCM();
    }

    this.props.actions.getProfile();
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    this.setState({ profile: newProps.profile.storeProfileData });
  }

  processAutoPrint = async (msg) => {
    // get print string
    const printData = ReactDOMServer.renderToStaticMarkup(
      <OrderDetail order={msg} onCloseDetail={() => {}} isTemplateMode={true} />
    );
    // sleep for 5 seconds
    await new Promise((resolve) => {
      setTimeout(() => {
        resolve();
      }, 5000);
    });

    // set new data for print and print after 1 second
    this.setState({ templateString: printData }, () => {
      setTimeout(() => {
        window.print();
      }, 1000);
    });
  };

  componentDidUpdate() {
    console.log("MQTT connectStatus: ", this.state.connectStatus);
    if (this.props.err === HTTP_ERRORS.InvalidToken) {
      this.logoutByInvalidToken();
    }
  }

  componentWillUnmount() {
    // window.removeEventListener("resize", this.handleResize);

    this.handleDisconnect();
    this._isMounted = false;
    // this.disconnectFCM();
  }

  callGetOrderByOrderId = async (orderId) => {
    const url =
      EnvDecode.getEnvPath(process.env.React_App_API_SERVER_URL) +
      "/order/" +
      orderId;
    let res = await axios.get(url, {
      headers: {
        "Content-Type": "application/json",
        "X-Frame-Options": "sameorigin",
        language: localStorage.getItem(APP_LANG_KEY) || "en",
      },
    });

    if (res) {
      return res;
    }
  };

  /* for FCM
    onReceiveFcm = (message) => {
        console.log("FCM Message =>", message)
        if ( message.title === 'New Order.' ) {
            let data = JSON.parse(message.data);
            console.log("FCM Message Parse =>", data)
            this.callGetOrderByOrderId(data.orderId).then((res) => {
                console.log("GET ORDERS =>", res);
                if ( res.status === HTTP_ERRORS.OK ) {
                    let order = res.data.data
                    if(order.status && order.status == ORDER_STATUS.created || order.status == ORDER_STATUS.orderExpired)
                    {
                        MainStore.push(order);
                    }
                }
            }).catch((err) => {
                console.log("GET ORDERS ERROR =>", err);
            })
        }
    }

    disconnectFCM = async () => {
        await unsubscribeToTopic(this.userData.fcmManagerTopic);
        deleteToken(this.state.fcmToken).then((res) => {
            console.log("FCM token deleted =>", res);
        });
    }

    connectFCM = async () => {
        unsubscribeToTopic(this.userData.fcmManagerTopic).then(() => {
            subscribeToTopic(this.userData.fcmManagerTopic, this.onReceiveFcm).then()
            // onMessageListener().then((res) => {
            //     console.log("FCM background=>", res);
            // });
        })
    }
    */
  getUserName = () => {
    if (this.userData && this.userData !== undefined) {
      return this.userData.name;
    }
    return "";
  };

  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  toggleDropDown = () => {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
    });
  };

  toggleOffsidebar = (e) => {
    e.preventDefault();
    //this.props.actions.toggleSetting('offsidebarOpen');
  };

  onCloseChangePwdDlg = () => {
    this.setState({
      openChangePwdDlg: false,
    });
  };

  onChangePassword = (e) => {
    this.userData = JSON.parse(localStorage.getItem(USER_DATA));
    // if(this.userData.dispatcherUserType != DISPATCHER_USER_TYPE.Packer)
    // {
    if (
      this.userData.userEmail === undefined ||
      this.userData.userEmail == null
    ) {
      e.preventDefault();
      this.props.actions.openConfirmDialog({
        title: utilService.getLangByCode("Information"),
        description: utilService.getLangByCode(
          "Login information from superadmin"
        ),
        action_type: "change_password",
        dialog_type: "info",
      });
    } else {
      e.preventDefault();
      this.setState({
        openChangePwdDlg: true,
      });
    }
    // }
  };

  onClickLogOut = (e) => {
    e.preventDefault();
    this.setState({ openLogoutWarning: true });
  };

  onCloseLogoutDlg = () => {
    this.setState({ openLogoutWarning: false });
  };

  onLogOut = () => {
    this.setState({ openLogoutWarning: false });
    let userData = localStorage.getItem(USER_DATA);
    if (userData !== undefined && userData) {
      // update manager status => logout
      userData = JSON.parse(userData);
      if (
        userData &&
        userData.userEmail !== undefined &&
        userData.userEmail !== null
      ) {
        let manager_data = {
          _id: userData.id || "",
          name: userData.name || "",
          email: userData.userEmail,
          storeId: userData.storeId || "",
          storeName: userData.storeName || "",
          accepts: userData.accepts ? userData.accepts : "",
          phone: userData.phone || "",
          cityId: userData.cityId || "",
          cityName: userData.cityName || "",
          countryCode: userData.countryCode || "",
          receivedOrderEmail: userData.receivedOrderEmail || "",
          dispatcherUserType: userData.dispatcherUserType || 1,
          dispatcherUserTypeMsg: userData.dispatcherUserTypeMsg
            ? userData.dispatcherUserTypeMsg
            : "",
          password: userData.password || "",
          seqId: userData.seqId || 0,
          userType: userData.userType || 2,
          addedBy: userData.addedBy || "",
          status: MANAGER_STATUS.LOGGED_OUT,
          passwordChanged: false,
        };
        this.props.actions.updateManager(manager_data);
        // then this.signout() is called from mqtt notification
        // return;
      }
    }
    this.signout();
  };
  signout = () => {
    this.props.actions.logout();
    this.props.history.push("/login");
  };

  logoutByInvalidToken = () => {
    this.props.actions.logoutByInvalidToken();

    this.props.history.entries = [];
    this.props.history.index = -1;
    this.props.history.push("/login", "historystate");
  };

  state = {
    // mqtt
    client: null,
    connectStatus: "Connect",
    isSubed: false,
    messages: [],
    loading: false,
  };

  // MQTT
  mqttProcess() {
    if (this.client && Object.keys(this.client).length > 0) {
      // this.setState({ loading: false });
      return;
    }

    let clientId = "mqttjs_" + `${Math.random().toString(16).substr(2, 8)}`;
    var options = {
      clientId: clientId,
      username: EnvDecode.getEnvPath(process.env.React_App_MQTT_USERNAME),
      password: EnvDecode.getEnvPath(process.env.React_App_MQTT_PASSWORD),
      keepalive: 0,
      clean: false,
      protocolId: "MQIsdp",
      protocolVersion: 3,
    };

    console.log(
      "MQTT Server URL = ",
      EnvDecode.getEnvPath(process.env.React_App_MQTT_WEBSOCKET_URL)
    );
    this.handleConnect(
      EnvDecode.getEnvPath(process.env.React_App_MQTT_WEBSOCKET_URL),
      options
    );

    let timeout = setInterval(() => {
      if (this.state.connectStatus === "Connected") {
        this.setState({ connectStatus: "SubScribed" });
        //timeout && clearTimeout(timeout); // not timeout for auto reconnecting

        // Notification Topic
        mqttTopic =
          "storeManager/" + this.userData.storeId + "/" + this.userData.id;
        let qos = { qos: 2 };

        //console.log('topic =', mqttTopic)
        this.handleSubscribe(mqttTopic, qos);

        // FcmUserTopic for logout
        let fcmUserTopic = this.userData.fcmUserTopic;
        //console.log('fcmUserTopic = ', fcmUserTopic);
        this.handleSubscribe(fcmUserTopic, qos);
      }
    }, 100);
  }

  acceptOrder = (order, expired_time = 30) => {
    let timestamp = Date.parse(new Date()) / 1000; // Local time
    timestamp += expired_time * 60;

    let user_data = JSON.parse(localStorage.getItem("userData"));

    let req = {
      orderId: order.orderIdString,
      status: ORDER_STATUS.accepted,
      reason: "day limited",
      timestamp: timestamp,
      userId: user_data.id,
      // "notifyIndex":index
    };

    this.props.actions.updateOrderStatus(req);
    MainStore.remove(order);
  };

  // MQTT Handler
  handleConnect = (host, mqttOptions) => {
    this.setState({ connectStatus: "Connecting" });
    this.client = mqtt.connect(host, mqttOptions);
    if (this.client) {
      this.client.on("connect", () => {
        console.log(`MQTT connected at ${new Date().toISOString()}`);
        this.setState({ connectStatus: "Connected" });
      });

      this.client.on("error", (err) => {
        console.error("MQTT connection error: ", err);
        this.client.end();
      });

      this.client.on("reconnect", () => {
        console.log(`MQTT reconnecting at ${new Date().toISOString()}`);
        this.setState({ connectStatus: "Reconnecting" });
      });

      // Order Notification
      this.client.on("message", (topic, message) => {
        // console.log("** AuthToken**", message)
        const payload = { topic, message: message.toString() };
        const { messages } = this.state;
        const { autoPrintOrder, autoAcceptOrder } = _.get(
          this.props.profile,
          "storeProfileData",
          {}
        );

        let fcmUserTopic = this.userData.fcmUserTopic;
        let authToken = this.userData.authToken;

        // console.log("** Receive message by topic**", topic, fcmUserTopic, JSON.parse(payload.message))
        let msg = JSON.parse(payload.message);

        if (payload.topic === mqttTopic) {
          // const changedMessages = messages.concat([payload]);

          // //console.log( 'mqtt msg received =', changedMessages )
          // this.setState({ messages: changedMessages });

          if (msg.status && msg.status == ORDER_STATUS.created) {
            if (autoAcceptOrder) {
              this.acceptOrder(msg);

              if (autoPrintOrder) {
                this.processAutoPrint(msg);
              }
            } else {
              MainStore.push(msg);
            }
          } else if (msg.status == ORDER_STATUS.orderExpired) {
            MainStore.remove(msg);
            setTimeout(() => MainStore.push(msg), 1000);
          } else {
            MainStore.remove(msg);
          }
        }
        // Logout
        else if (payload.topic === fcmUserTopic) {
          //console.log("Session Expired:authToken = ", msg.authToken)
          if (msg.authToken !== authToken) {
            //console.log("** Set session is NULL **", authToken);
            // localStorage.setItem("userData", null )
            // localStorage.setItem("mqttClient", null);
            // loginService.setSession(null);
            this.signout();
          }
        }
      });
    }
  };

  handleSubscribe = (topic, qos) => {
    //console.log( 'handle subscribe = ', this.client, topic, qos )
    if (this.client) {
      this.client.subscribe(topic);
    }

    this.setState({ loading: false });
  };

  handleUnsub = (topic) => {
    if (this.client) {
      this.client.unsubscribe(topic, (error) => {
        if (error) {
          console.log("Mqtt Unsubscribe error", error);
          return;
        }

        this.setState({ isSubed: false });
      });
    }
  };

  handlePublish = (pubRecord) => {
    if (this.client) {
      const { topic, qos, payload } = pubRecord;
      this.client.publish(topic, payload, { qos }, (error) => {
        if (error) {
          console.log("Mqtt Publish error: ", error);
        }
      });
    }
  };

  handleDisconnect = () => {
    if (this.client) {
      this.client.end(() => {
        console.log(`MQTT disconnected at ${new Date().toISOString()}`);
        this.setState({ connectStatus: "Connect" });
        this.setState({ client: null });
      });
    }
  };

  getStoreInfo = () => {
    let storeInfo = {
      name: "",
      addr: "",
    };
    let userData = this.state.profile;
    if (userData !== undefined && userData !== null) {
      if (userData) {
        let name = utilService.getLocale(
          userData.name,
          utilService.getLanguage()
        );
        let addr = this.state.profile.storeAddr;
        return { name: name, addr: addr };
      }
    }

    return storeInfo;
  };

  render() {
    return (
      <header className="topnavbar-wrapper" id="nav_header">
        {/* START Top Navbar */}
        <nav className="navbar topnavbar navbar-expand-lg navbar-light">
          {/* START navbar header */}
          <div className="navbar-header d-flex font-arial">
            <a className="navbar-brand m-auto" /*href="#/"*/>
              <div className="brand-logo">
                <img className="img-fluid" src="img/logo.svg" alt="App Logo" />
              </div>
              {/* <div className="brand-logo-collapsed">
                                <img className="img-fluid" src="img/logo-single.png" alt="App Logo" />
                            </div> */}
            </a>

            <div className="store-info m-auto">
              <b style={{ color: COLORS.Ultramarine, padding: "0px" }}>
                {this.getStoreInfo().name}
              </b>
              <br />
              <b style={{ color: COLORS.Ultramarine, padding: "0px" }}>
                {this.getStoreInfo().addr}
              </b>
            </div>

            <NavbarToggler onClick={this.toggle} />
          </div>
          {/* END navbar header */}
          {/* START Nav wrapper */}
          <Collapse isOpen={this.state.isOpen} navbar>
            <Nav navbar className="mr-auto flex-column flex-lg-row">
              <UncontrolledDropdown nav inNavbar style={{ display: "none" }}>
                <DropdownToggle nav>
                  {utilService.getLangByCode("Dashboard")}
                </DropdownToggle>
                <DropdownMenu className="animated fadeIn">
                  <Link className="dropdown-item" to="dashboard">
                    {utilService.getLangByCode("Dashboard") + "v1"}
                  </Link>
                  <Link className="dropdown-item" to="dashboardv2">
                    {utilService.getLangByCode("Dashboard") + "v2"}
                  </Link>
                  <Link className="dropdown-item" to="dashboardv3">
                    {utilService.getLangByCode("Dashboard") + "v3"}
                  </Link>
                </DropdownMenu>
              </UncontrolledDropdown>
              <NavItem className="nav-menu-item" style={{ display: "none" }}>
                <Link className="nav-link" to="widgets">
                  {utilService.getLangByCode("Widgets")}
                </Link>
              </NavItem>
              {/* END Left navbar */}
            </Nav>
            <Nav className="flex-row nav-button-group" navbar>
              {/* Search icon */}
              {/* <NavItem>
                                <NavLink href="" data-search-open="">
                                    <em className="icon-magnifier"></em>
                                </NavLink>
                            </NavItem> */}
              {/* Fullscreen (only desktops) */}
              {/* <NavItem className="d-none d-md-block">
                                <ToggleFullscreen className="nav-link"/>
                            </NavItem> */}
              {/* START Alert menu */}

              {/* START Language list */}
              <NavItem className="ml-auto font-arial">
                <Dropdown
                  isOpen={this.state.dropdownOpen}
                  toggle={this.toggleDropDown}
                >
                  <DropdownToggle
                    onClick={this.toggleDropDown}
                    className="mt-0 mb-0"
                    style={{
                      backgroundColor: "#e8e9eb",
                      color: COLORS.GENERAL,
                      borderWidth: 0,
                      borderRadius: "3px",
                      fontSize: "14px",
                      fontWeight: "lighter",
                      height: 40,
                      width: 90,
                    }}
                  >
                    {utilService.getLangByCode(this.state.lang)}
                  </DropdownToggle>
                  <DropdownMenu
                  //className="dropdown-menu-right-forced animated fadeInUpShort mt-4 mb-0"
                  >
                    <DropdownItem onClick={() => this.changeLanguage("en")}>
                      {"English"}
                    </DropdownItem>
                    <DropdownItem onClick={() => this.changeLanguage("nl")}>
                      {utilService.getLangByCode("Dutch")}
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </NavItem>
              {/* END Language list */}

              {/* comment icon */}
              {/* <li className="nav-item mr-8">
                                <a className="nav-link" href="" onClick={this.toggleOffsidebar} data-toggle="tool-tip" title={utilService.getLangByCode('Comment')}>
                                    <em className="fas fa-comment"></em>
                                </a>
                            </li> */}

              {/* person icon */}
              <li className="nav-item mr-8">
                <a
                  className="nav-link"
                  id="nav_user"
                  href="#"
                  onClick={this.onChangePassword}
                  data-toggle="tool-tip"
                  title={this.getUserName()}
                  style={{ height: 40, width: 40 }}
                >
                  {/* <em className="fas fa-user" style={{color:'#21478F', width:24, height:24}}></em> */}
                  <img src="img/user.svg" />
                </a>
              </li>
              {/* signout icon */}
              <li className="nav-item">
                <a
                  className="nav-link"
                  href=""
                  onClick={(e) => this.onClickLogOut(e)}
                  data-toggle="tool-tip"
                  title={utilService.getLangByCode("Logout")}
                  style={{ height: 40, width: 40 }}
                >
                  {/* <em className="fas fa-sign-out-alt" style={{color:'#21478F', width:28, height:28}}></em> */}
                  <img src="img/logout.svg" />
                </a>
              </li>

              <UncontrolledDropdown
                nav
                inNavbar
                className="dropdown-list"
                style={{ display: "none" }}
              >
                <DropdownToggle nav className="dropdown-toggle-nocaret">
                  <em className="icon-bell"></em>
                  <span className="badge badge-danger">11</span>
                </DropdownToggle>
                {/* START Dropdown menu */}
                <DropdownMenu
                  right
                  className="dropdown-menu-right animated flipInX"
                >
                  <DropdownItem>
                    {/* START list group */}
                    <ListGroup>
                      <ListGroupItem
                        action
                        tag="a"
                        href=""
                        onClick={(e) => e.preventDefault()}
                      >
                        <div className="media">
                          <div className="align-self-start mr-2">
                            <em className="fab fa-twitter fa-2x text-info"></em>
                          </div>
                          <div className="media-body">
                            <p className="m-0">New followers</p>
                            <p className="m-0 text-muted text-sm">
                              1 new follower
                            </p>
                          </div>
                        </div>
                      </ListGroupItem>
                      <ListGroupItem
                        action
                        tag="a"
                        href=""
                        onClick={(e) => e.preventDefault()}
                      >
                        <div className="media">
                          <div className="align-self-start mr-2">
                            <em className="fa fa-envelope fa-2x text-warning"></em>
                          </div>
                          <div className="media-body">
                            <p className="m-0">New e-mails</p>
                            <p className="m-0 text-muted text-sm">
                              You have 10 new emails
                            </p>
                          </div>
                        </div>
                      </ListGroupItem>
                      <ListGroupItem
                        action
                        tag="a"
                        href=""
                        onClick={(e) => e.preventDefault()}
                      >
                        <div className="media">
                          <div className="align-self-start mr-2">
                            <em className="fa fa-tasks fa-2x text-success"></em>
                          </div>
                          <div className="media-body">
                            <p className="m-0">Pending Tasks</p>
                            <p className="m-0 text-muted text-sm">
                              11 pending task
                            </p>
                          </div>
                        </div>
                      </ListGroupItem>
                      <ListGroupItem
                        action
                        tag="a"
                        href=""
                        onClick={(e) => e.preventDefault()}
                      >
                        <span className="d-flex align-items-center">
                          <span className="text-sm">
                            {utilService.getLangByCode("More notifications")}
                          </span>
                          <span className="badge badge-danger ml-auto">14</span>
                        </span>
                      </ListGroupItem>
                    </ListGroup>
                    {/* END list group */}
                  </DropdownItem>
                </DropdownMenu>
                {/* END Dropdown menu */}
              </UncontrolledDropdown>
            </Nav>
          </Collapse>
        </nav>

        <ConfirmDialog
          confirmCancel={(confirmInfo) => {
            console.log("Header Confirm Cancel=>");
          }}
          confirmDone={(confirmInfo) => {
            console.log("Header Confirm Done=>");
          }}
        />

        <ChangePasswordDialog
          isOpen={this.state.openChangePwdDlg}
          onClose={() => this.onCloseChangePwdDlg()}
        />

        <LogoutWarningDialog
          isOpen={this.state.openLogoutWarning}
          onClose={this.onCloseLogoutDlg}
          onConfirm={this.onLogOut}
        />

        {/* container for auto print */}
        {this.state.templateString ? (
          <div
            style={{
              position: "fixed",
              width: "100%",
              left: 0,
              top: 0,
              visibility: "hidden",
            }}
            dangerouslySetInnerHTML={{ __html: this.state.templateString }}
          />
        ) : null}

        {/* END Top Navbar */}
      </header>
    );
  }
}

HeaderHorizontal.propTypes = {
  actions: PropTypes.object,
  //settings: PropTypes.object,
  auth: PropTypes.object,
};

//const mapStateToProps = ({ auth, state }) => ({ err: auth.err, settings: state.settings })
const mapStateToProps = ({ auth }) => ({
  err: auth.err,
  profile: auth.profile,
});
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
  getProfile: actions.getProfile,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(HeaderHorizontal));
