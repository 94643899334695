import axios from 'axios';
import EnvDecode from '../common/EnvDecode';
import { APP_LANG_KEY, AXIOS_LANGUAGE } from '../components/Common/constants';

const BASE_URL = EnvDecode.getEnvPath(process.env.React_App_API_SERVER_URL);


var WalletService = {
    
   getWallets ( data ) {

    let userType = data.userType;
    let txnType = data.txnType === '' ? 0: data.txnType 
    let triggerType = data.triggerType === '' ? 0 : data.triggerType
    let fromDate = data.fromDate === '' ? 0 : data.fromDate
    let toDate = data.toDate === '' ? 0 : data.toDate
    let pageNum = data.pageNum
    let query = data.query === '' ? 0 : data.query 

    let url = `${BASE_URL}` + '/api/walletsWithQuery/' + userType + "/" + txnType + "/" + triggerType + "/" + 
    fromDate + "/" + toDate + "/" + pageNum + "/" + query

        return new Promise((resolve, reject) => {
            axios.defaults.headers.common[AXIOS_LANGUAGE] = localStorage.getItem(APP_LANG_KEY) || 'en';
            axios.get( url )
                .then(response => {
                    if ( response.status === 200 )
                    {   
                        resolve(response.data.data);                        
                    }
                    else
                    {   
                        reject(response.data.data);
                    }
                })
                .catch(err=>{
                    reject(err);
                })
        });
    },

    
    
}

export default WalletService;