export const CREATE_OFFER = 'CREATE_OFFER';
export const GET_OFFER = 'GET_OFFER';
export const UPDATE_OFFER = 'UPDATE_OFFER';
export const GET_OFFERS_BY_STATUS = 'GET_OFFERS_BY_STATUS';
export const GET_PRODUCTS_BY_STORE_ID = 'GET_PRODUCTS_BY_STORE_ID';
export const UPDATE_OFFER_BY_STATUS = 'UPDATE_OFFER_BY_STATUS';
export const GET_CATEGORY_LIST_BY_STORE_ID = 'GET_CATEGORY_LIST_BY_STORE_ID';
export const GET_SUB_CATEGORY_LIST_BY_STORE_ID = 'GET_SUB_CATEGORY_LIST_BY_STORE_ID';
export const GET_SUB_SUB_CATEGORY_LIST_BY_STORE_ID = 'GET_SUB_SUB_CATEGORY_LIST_BY_STORE_ID';
export const GET_UNIT_LIST_BY_PRODUCT_ID = "GET_UNIT_LIST_BY_PRODUCT_ID";
export const GET_CITIES = 'GET_CITIES';

export const CREATE_OFFER_DONE = 'CREATE_OFFER_DONE';
export const GET_OFFER_DONE = 'GET_OFFER_DONE';
export const UPDATE_OFFER_DONE = 'UPDATE_OFFER_DONE';
export const GET_OFFERS_BY_STATUS_DONE = 'GET_OFFERS_BY_STATUS_DONE';
export const GET_PRODUCTS_BY_STORE_ID_DONE = 'GET_PRODUCTS_BY_STORE_ID_DONE';
export const UPDATE_OFFER_BY_STATUS_DONE = 'UPDATE_OFFER_BY_STATUS_DONE';
export const GET_CATEGORY_LIST_BY_STORE_ID_DONE = 'GET_CATEGORY_LIST_BY_STORE_ID_DONE';
export const GET_SUB_CATEGORY_LIST_BY_STORE_ID_DONE = 'GET_SUB_CATEGORY_LIST_BY_STORE_ID_DONE';
export const GET_SUB_SUB_CATEGORY_LIST_BY_STORE_ID_DONE = 'GET_SUB_SUB_CATEGORY_LIST_BY_STORE_ID_DONE';
export const GET_UNIT_LIST_BY_PRODUCT_ID_DONE = 'GET_UNIT_LIST_BY_PRODUCT_ID_DONE';
export const GET_CITIES_DONE = 'GET_CITIES_DONE';

export const GET_OFFER_ERROR = 'GET_OFFER_ERROR';

export const OPEN_NEW_OFFER_DIALOG = 'OPEN_NEW_OFFER_DIALOG';
export const OPEN_EDIT_OFFER_DIALOG = 'OPEN_EDIT_OFFER_DIALOG';
export const CLOSE_OFFER_DIALOG = 'CLOSE_OFFER_DIALOG';


/**
 * Create Offer
 */
export function createOffer(offerData) {
    return { type: CREATE_OFFER, data:offerData };
}

/**
 * Get Offer Detail Info By Id
 */
export function getOfferById( id ) {
    return { type: GET_OFFER, data: id };
}

/**
 * Update offer
 */
export function updateOffer( offerData ) {
    return { type: UPDATE_OFFER, data:offerData };
}

/**
 * Get Offers By status
 */
export function getOffersByStatus( statusData) {
    return { type: GET_OFFERS_BY_STATUS, data:statusData };
}

/**
 * Get Products By OfferId
 */
export function getProductsByStoreId( id) {
    return { type: GET_PRODUCTS_BY_STORE_ID, data:id };
}

/**
 * Update Offer Status By Status
 */
export function updateOfferStatus( statusData ) {
    return { type: UPDATE_OFFER_BY_STATUS, data: statusData };
}

/**
 * Get Category List By Offer Id
 */
export function getCategoryListByStoreId( id ) {
    return { type: GET_CATEGORY_LIST_BY_STORE_ID, data: id };
}

/**
 * Get Sub Category List By Offer Id
 */
export function getSubCategoryListByStoreId ( id ) {
    return { type: GET_SUB_CATEGORY_LIST_BY_STORE_ID, data: id };
}

/**
 * Get Sub Sub Category List By Offer Id
 */
export function getSubSubCategoryListByStoreId ( id ) {
    return { type: GET_SUB_SUB_CATEGORY_LIST_BY_STORE_ID, data: id };
}

/**
 * Get Units List By Offer Id
 */
export function getUnitListByProductId ( id ) {
    return { type: GET_UNIT_LIST_BY_PRODUCT_ID, data: id };
}

/**
 * Get Cities
 */
export function getCities () {
    return { type: GET_CITIES };
}

export function openNewOfferDialog()
{
    return {
        type: OPEN_NEW_OFFER_DIALOG
    }
}

export function openEditOfferDialog(data)
{
    return {
        type: OPEN_EDIT_OFFER_DIALOG, data:data
    }
}

export function closeOfferDialog()
{
    return {
        type: CLOSE_OFFER_DIALOG
    }
}

